import { DOCS_POLICY, DOCS_TERMS } from '../constants/api';

type ConfigType = Record<string, { agreement: string; policy: string }>;

export const replacePolicyLabel = (text: string, lang: string, config: ConfigType) => {
  const { agreement, policy } = config?.[lang.toLowerCase()] || {
    agreement: 'User Agreement',
    policy: 'Personal Data Processing Policy',
  };
  const [firstPart, secondPart] = text.split(agreement);
  const [first] = secondPart.split(policy);

  const clickHandler = (type: string) => async () => {
    let blob: Blob;
    let fileName: string;
    if (type === 'terms') {
      const resp = await fetch(DOCS_TERMS);
      blob = await resp.blob();
      fileName = 'User_Agreement_Beezytips.pdf';
    } else {
      const resp = await fetch(DOCS_POLICY);
      blob = await resp.blob();
      fileName = 'Personal_Data_Processing_Policy_Beezytips.pdf';
    }
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', fileName);
    link.click();
  };

  return (
    <>
      {firstPart}
      <a href="#" onClick={clickHandler('terms')} className="underline">
        {agreement}
      </a>
      {first}
      <a href="#" onClick={clickHandler('policy')} className="underline">
        {policy}
      </a>
    </>
  );
};
