import { FormikConfig } from '../../types/base';

export const config: Record<string, FormikConfig> = {
  restore: {
    newPassword: {
      type: 'password',
      placeholder: 'profile.new',
      required: true,
      initial: '',
    },
    repeatPassword: {
      type: 'password',
      placeholder: 'profile.repeat',
      required: true,
      initial: '',
    },
  },
  profile: {
    currentPassword: {
      type: 'password',
      placeholder: 'profile.current',
      required: true,
      initial: '',
    },
    newPassword: {
      type: 'password',
      placeholder: 'profile.new',
      required: true,
      initial: '',
    },
    repeatPassword: {
      type: 'password',
      placeholder: 'profile.repeat',
      required: true,
      initial: '',
    },
  },
};
