import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import { useLoader } from '../../hooks/useLoader';
import useMediaQuery from '../../pages/Landing/components/share/useQuery';
import { useRegisterUserMutation } from '../../redux/api/auth';
import { useGetEmployeeDataQuery, useUpdateUserDataMutation } from '../../redux/api/user';
import { useAppSelector } from '../../redux/hooks';
import { IErrorResponse } from '../../types/errors';
import { creteInitialValues } from '../../utils/formik';
import { useAddEmployeeValidationSchema } from '../../utils/validations/employee';
import { Button } from '../../view/Button';
import { FormTextInput } from '../../view/FormTextInput';
import { Loader } from '../Loader';

import { config } from './config';

export const AddEmployeeBody = () => {
  const validationSchema = useAddEmployeeValidationSchema();
  const [register, { isError, error, isSuccess, isLoading }] = useRegisterUserMutation();
  const { id } = useAppSelector(state => state.user);
  const { t } = useTranslation();
  const { organization: userOrg } = useAppSelector(state => state.user);
  const isMobile = useMediaQuery('(max-width: 1280px) and (max-aspect-ratio: 1024/695)');
  const navigate = useNavigate();
  const { state } = useLocation();
  const [
    updateUser,
    { isSuccess: isSuccessUpdate, isLoading: isLoadUpdate, isError: isErrorUpdate },
  ] = useUpdateUserDataMutation();

  const { data: qrData, isLoading: isDataLoading } = useGetEmployeeDataQuery(state?.employee.id, {
    refetchOnMountOrArgChange: true,
    skip: !state || !state.employee,
  });
  const isLoader = useLoader(isLoading, isLoadUpdate, isDataLoading);

  const initialValues = useMemo(() => {
    return creteInitialValues(config, {
      organization: userOrg,
      ...qrData,
    });
  }, [qrData, state]);

  const [file, setFile] = useState<File | null>(null);
  const fileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0] as File);
  };

  const handleSubmit = (values: typeof initialValues) => {
    const { name, surname, organization, proffession, iban, thirdname, wish } = values;

    const formData = new FormData();

    formData.append('file', file as File);
    formData.append('firstName', name);
    formData.append('lastName', surname);
    formData.append('organization', organization);
    formData.append('iban', iban);
    formData.append('thirdName', thirdname ?? null);
    formData.append('proffession', proffession ?? null);
    formData.append('wish', wish ?? null);
    formData.append('role', 'employee');
    formData.append('password', 'password');

    if (state) {
      formData.append('userId', state.employee.id);
      updateUser(formData);
    } else {
      formData.append('userId', `${id}`);
      register(formData);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isLoading, isError, isSuccess, error]);

  useEffect(() => {
    if (isSuccessUpdate) {
      navigate(-1);
    }
  }, [isSuccessUpdate, isLoadUpdate, isErrorUpdate]);

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (error) {
      const { status, data } = error as IErrorResponse;
      if (status === 409) {
        formik.setErrors({ email: data.msg });
      }
    }
  }, [isError]);

  return (
    <div
      className={`p-[2.604vw] flex gap-[1.042vw] md:p-[3.125vw] xs:p-[5.333vw] md:gap-[2.083vw] xs:gap-[4.267vw] ${
        isMobile && 'md:flex-col xs:flex-col'
      } bg-white`}
    >
      {isLoader && <Loader />}
      <div className="flex flex-col justify-between gap-[0.521vw] md:gap-[2.604vw] xs:gap-[4.267vw]">
        <div
          className={`w-[19.011vw] self-start px-[3.672vw] py-[2.083vw] text-center
        shadow-pass border-[1px] rounded-[0.833vw] border-authBorder flex items-center justify-center
        md:w-[83.073vw] md:px-[3.125vw] md:py-[5.208vw] md:rounded-[2.083vw]
        xs:w-[89.333vw] xs:px-[6.4vw] xs:py-[10.667vw] xs:rounded-[4.267vw]`}
        >
          <input type="file" id="file" name="file" className="hidden" onChange={fileChange} />
          <label
            htmlFor="file"
            className={`flex items-center flex-col text-[0.729vw] leading-[1.042vw] text-textGray text-center justify-center cursor-pointer
          md:text-[1.823vw] md:leading-[2.604vw] xs:text-[3.733vw] xs:leading-[5.333vw] md:max-w-[34.896vw] xs:max-w-[71.467vw]`}
          >
            <img
              src="/images/upload.png"
              alt="upload img"
              className="w-[7.5vw] h-[7.5vw] mb-[1.25vw] md:w-[18.75vw] md:h-[18.75vw] md:mb-[3.125vw] xs:w-[38.4vw] xs:h-[38.4vw] xs:mb-[6.4vw]"
            />
            {t('employee.image')}
          </label>
        </div>
        {qrData && (
          <div
            className={`w-[19.011vw] self-start px-[3.672vw] py-[2.083vw] text-center
        shadow-pass border-[1px] rounded-[0.833vw] border-authBorder flex items-center justify-center
        md:w-[83.073vw] md:px-[3.125vw] md:py-[5.208vw] md:rounded-[2.083vw]
        xs:w-[89.333vw] xs:px-[6.4vw] xs:py-[10.667vw] xs:rounded-[4.267vw]`}
          >
            <img src={qrData?.qr} alt="qr code" className="w-full h-full" />
          </div>
        )}
      </div>
      <div
        className={`flex flex-col w-[59.115vw] p-[1.302vw] shadow-pass rounded-[0.833vw]
        md:w-[83.073vw] md:p-[3.255vw] md:rounded-[2.083vw]
        xs:w-[89.333vw] xs:p-[6.667vw] xs:rounded-[4.267vw]`}
      >
        {Object.entries(config).map(([key, value]) => (
          <FormTextInput
            key={key}
            value={formik.values[key]}
            onChange={formik.handleChange}
            id={key}
            name={key}
            placeholder={t(value.placeholder) || ''}
            required={value.required}
            inputStyle="font-normal"
            error={formik.errors[key]}
            gap="mb-[1.302vw] md:mb-[3.255vw] xs:mb-[6.667vw]"
            type={value.type}
            disabled={value.disabled}
          />
        ))}
        <Button onClick={formik.submitForm} className="self-end px-[1.823vw]">
          {state ? t('employee.update') : t('employee.btn')}
        </Button>
      </div>
    </div>
  );
};
