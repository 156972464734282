export const Balls = () => {
  return (
    <>
      <div className="absolute top-0 left-0 w-[18vw] h-[18vw] md:w-[39vw] md:h-[39vw] xs:w-[67vw] xs:h-[67vw] flex items-center justify-center animate-purpleCircle">
        <div className=" w-[1px] h-[1px] rounded-full shadow-purple"></div>
      </div>
      <div className="absolute top-0 left-0 w-[23vw] h-[22vw] md:w-[52vw] md:h-[52vw] xs:w-[80vw] xs:h-[80vw] flex items-center justify-center animate-yellowCircle">
        <div className="w-[1px] h-[1px] rounded-full shadow-yellow"></div>
      </div>
      <div className="absolute top-0 left-0 w-[17vw] h-[17vw] md:w-[36vw] md:h-[39vw] xs:w-[65vw] xs:h-[65vw] flex items-center justify-center animate-blueCircle">
        <div className="w-[1px] h-[1px] rounded-full shadow-blue"></div>
      </div>
    </>
  );
};
