import { FC } from 'react';

import { API_URL } from '../../../../../constants/api';
import { IMenuItem } from '../../../../../redux/api/types/menu';
import { truncate } from '../../../../../utils/strings';
import { config } from '../config';

interface IProps {
  item: IMenuItem;
  onClick: () => void;
  plateView: boolean;
  className?: string;
}

const MenuItemCard: FC<IProps> = ({ item, onClick, plateView, className }) => {
  const Icon = config[item!.type as keyof typeof config];

  return (
    <div
      className={`dark:border-[1px] rounded-[16px] border-[#494949] dark:bg-categoryCardBg w-[44vw]
      dark:shadow-categoryCardShadow shadow-categoryCardShadowLight overflow-hidden relative min-h-max
      ${!plateView ? 'flex w-full items-center' : 'flex flex-col justify-between'} ${className}`}
      onClick={onClick}
    >
      <div
        className={`h-[43.5vw] overflow-hidden flex items-center justify-center ${
          !plateView && '!h-full !w-1/2 items-start justify-start grow'
        }`}
      >
        <img
          src={
            item && item.image
              ? `${API_URL}/image/${item.image}`
              : '/images/menu/dish-placeholder.png'
          }
          className={`${!item.image ? 'w-[86px] h-[86px]' : 'h-full w-full object-cover'}`}
        />
      </div>
      {item && item.type && (
        <div
          className="rounded-full h-[32px] w-[32px] flex items-center justify-center absolute top-[8px] left-[8px]"
          style={{ backgroundColor: Icon.color }}
        >
          <Icon.icon width={16} height={16} />
        </div>
      )}
      <div className={`p-[12px] flex flex-col justify-between ${!plateView && 'w-1/2'}`}>
        <p className="dark:text-white text-blackBg text-[18px] leading-[20px] font-semibold mb-[4px] overflow-hidden text-ellipsis">
          {item && truncate(item.name)}
        </p>
        <p className="text-[#FFB800] font-bold text-[18px] leading-[30px]">{item && item.price}₾</p>
      </div>
    </div>
  );
};

export default MenuItemCard;
