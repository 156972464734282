import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import { TOAST_OPTIONS } from '../../config';
import { useLoader } from '../../hooks/useLoader';
import useMediaQuery from '../../pages/Landing/components/share/useQuery';
import { ReviewType } from '../../redux/api/types/reviews';
import { useGetUserDataQuery, useUpdateUserDataMutation } from '../../redux/api/user';
import { useAppSelector } from '../../redux/hooks';
import { pathnameSlicer } from '../../utils/base';
import { creteInitialValues, handleFormWithPhone } from '../../utils/formik';
import { useProfileValidationSchema } from '../../utils/validations/profile';
import { Button } from '../../view/Button';
import { Checkbox } from '../../view/Checkbox';
import { FormTextInput } from '../../view/FormTextInput';
import ImageInput from '../../view/ImageInput';
import { ChangePasswordForm } from '../ChangePasswordForm';
import { Loader } from '../Loader';

import CategoryDropdown from './components/CategoryDropdown';
import Navbar from './components/Navbar';
import { adminConfig, config } from './config';

export const ProfileBody = () => {
  const { id, role } = useAppSelector(state => state.user);
  const { pathname } = useLocation();
  const currentPage = pathnameSlicer(pathname);
  const isMobile = useMediaQuery('(max-width: 1280px) and (max-aspect-ratio: 1024/695)');
  const validationSchema = useProfileValidationSchema();
  const [updateUser, { isSuccess, isError, isLoading: isUpdating }] = useUpdateUserDataMutation();
  const { data, isLoading, refetch } = useGetUserDataQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });
  const initialValues = useMemo(
    () => creteInitialValues(role === 'admin' ? { ...config, ...adminConfig } : config, data),
    [data, isLoading]
  );
  const { t } = useTranslation();
  const isLoader = useLoader(isLoading, isUpdating);
  const [file, setFile] = useState<File | null>(null);
  const [isRatingEnabled, setIsRatingEnabled] = useState(true);
  const [isComissionEnabled, setIsComissionEnabled] = useState(true);
  const [activeItems, setActiveItems] = useState<ReviewType[]>([]);

  useEffect(() => {
    if (data) {
      setIsRatingEnabled(!!data.isRatingVisible);
      setActiveItems(data.marks);
      setIsComissionEnabled(!!data.isComissionEnabled);
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('back.user.update'), TOAST_OPTIONS);
    }
    if (isError) {
      toast.error(t('back.user.error'), TOAST_OPTIONS);
    }
  }, [isSuccess, isError]);

  const handleSubmit = (values: typeof initialValues) => {
    if (file) {
      const formData = new FormData();

      formData.append('file', file as File);
      formData.append('userId', `${id}`);
      formData.append('isRatingVisible', `${isRatingEnabled}`);
      formData.append('isComissionEnabled', `${isComissionEnabled}`);
      formData.append('marks', activeItems.join(','));
      Object.entries(values).forEach(([key, value]) => {
        if (value) {
          formData.append(key, value);
        }
      });

      updateUser(formData)
        .unwrap()
        .then(() => refetch());
    } else {
      updateUser({
        ...values,
        userId: id,
        isRatingVisible: `${isRatingEnabled}`,
        isComissionEnabled: `${isComissionEnabled}`,
        marks: activeItems.join(','),
      })
        .unwrap()
        .then(() => refetch());
    }
  };

  const toggleRatingEnabled = (e?: ChangeEvent<HTMLInputElement>) => {
    if (e) {
      setIsRatingEnabled(e.target.checked);
    }
  };

  const toggleComissionEnabled = (e?: ChangeEvent<HTMLInputElement>) => {
    if (e) {
      setIsComissionEnabled(e.target.checked);
    }
  };

  const fileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0] as File);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  return (
    <div
      className={`bg-white py-[1.875vw] px-[2.604vw] flex flex-col gap-[2.083vw]
      md:gap-[3.125vw] md:py-[2.083vw] md:px-[3.255vw] xs:gap-[5.333vw] xs:py-[5.333vw] xs:px-[5.333vw]`}
    >
      {isLoader && <Loader />}
      <Navbar />
      {currentPage === 'profile' ? (
        <div
          className={`p-[2.604vw] flex gap-[1.042vw] md:p-[3.125vw] xs:p-[0] md:gap-[2.083vw] xs:gap-[4.267vw] ${
            isMobile && 'md:flex-col xs:flex-col'
          } bg-white`}
        >
          <ImageInput fileChange={fileChange} size="xl" image={data?.image} />
          <div
            className={`flex flex-col w-[59.115vw] p-[1.302vw] shadow-pass rounded-[0.833vw]
        md:w-full md:p-[3.255vw] md:rounded-[2.083vw]
        xs:p-[6.667vw] xs:rounded-[4.267vw]`}
          >
            {Object.entries(role === 'admin' ? { ...config, ...adminConfig } : config).map(
              ([key, value]) => (
                <FormTextInput
                  key={key}
                  value={formik.values[key]}
                  onChange={handleFormWithPhone(key, formik)}
                  id={key}
                  name={key}
                  inputStyle="font-medium"
                  placeholder={t(value.placeholder) || ''}
                  error={formik.errors[key]}
                  gap="mb-[1.302vw] md:mb-[3.255vw] xs:mb-[6.667vw]"
                  type={value.type}
                />
              )
            )}
            <div className="flex gap-6 xs:flex-col xs:mb-[20px]">
              <Checkbox
                labelText={t('profile.rating') || ''}
                name="isRatingVisible"
                toggle={toggleRatingEnabled}
                checked={isRatingEnabled}
              />
              {role === 'admin' && (
                <Checkbox
                  labelText={t('profile.comission') || ''}
                  name="isComissionEnabled"
                  toggle={toggleComissionEnabled}
                  checked={isComissionEnabled}
                />
              )}
            </div>
            <CategoryDropdown activeItems={activeItems} setActiveItems={setActiveItems} />
            <Button
              onClick={formik.submitForm}
              className="self-end px-[1.823vw] md:px-[4.557vw] xs:px-[23.733vw]"
            >
              {t('saveBtn')}
            </Button>
          </div>
        </div>
      ) : (
        <ChangePasswordForm isRestoring={false} />
      )}
    </div>
  );
};
