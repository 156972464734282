import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '../../../constants/routes';
import useMediaQuery from '../../../pages/Landing/components/share/useQuery';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { replaceUser } from '../../../redux/slices/userSlice';
import { Roles } from '../../../types/base';

import { AdminCurrentUser } from './AdminCurrentUser';

interface IProps {
  role: Roles | '';
  organization: string;
  expanded: boolean;
}

export const SidebarTopTitle: FC<IProps> = ({ role, organization, expanded }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 1280px) and (max-aspect-ratio: 1024/695)');
  const { id, adminId } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLeaveCurrentUser = () => {
    dispatch(replaceUser({ id: adminId as number, organization: 'admin' }));
    navigate(`${ROUTES.DASHBOARD}/${adminId}${ROUTES.ORGANIZATION}`);
  };

  return (
    <>
      {isMobile && !expanded ? (
        <>
          {role === 'admin' && id !== adminId && (
            <button onClick={handleLeaveCurrentUser} className="w-full flex justify-center">
              <img
                src="/images/icons/home_round.svg"
                alt="exit icon"
                className="w-[1.719vw] h-[1.719vw] md:w-[4.297vw] md:h-[4.297vw] md:mb-[5.208vw]"
              />
            </button>
          )}
        </>
      ) : (
        <div>
          <div className="h-[4.323vw] md:h-[10.807vw]">
            <h2
              className={`w-full font-bold text-[1.042vw] leading-[1.563vw] text-center mb-[1.042vw]
              md:text-[2.604vw] md:leading-[3.906vw] md:mb-[2.604vw]`}
            >
              {role === 'admin' ? t('menu.admin') : organization}
            </h2>
            {role === 'admin' && id !== adminId && <AdminCurrentUser />}
          </div>
        </div>
      )}
    </>
  );
};
