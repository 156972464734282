import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useRedirect = (
  skip: boolean,
  isLoading: boolean,
  isSuccess: boolean,
  to: string,
  ...rest: (string | number | boolean)[]
) => {
  const navigate = useNavigate();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setReady(true);
    }
  }, [isLoading, isSuccess]);

  useEffect(() => {
    if (skip) return;
    if (rest.length && ready) {
      const joinRest = rest.join('/');
      navigate(`${to}/${joinRest}`);
    } else if (ready) {
      navigate(to);
    }
  }, [ready, ...rest]);
};
