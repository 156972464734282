import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';

import { TOAST_OPTIONS } from '../../../config';
import useMediaQuery from '../../../pages/Landing/components/share/useQuery';
import { useGetUserQrQuery, useUpdateUserQRMutation } from '../../../redux/api/qr';
import { useAppSelector } from '../../../redux/hooks';
import { ReactComponent as PrintIcon } from '../../../static/print.svg';
import { Button } from '../../../view/Button';

const QRBlock = () => {
  const { t } = useTranslation();

  const ref = useRef<HTMLImageElement | null>(null);
  const { id } = useAppSelector(state => state.user);
  const { data, refetch } = useGetUserQrQuery(id, { refetchOnMountOrArgChange: true });
  const isMobile = useMediaQuery('(max-width: 1280px) and (max-aspect-ratio: 1024/695)');

  const [updateQr, { isLoading, isSuccess }] = useUpdateUserQRMutation();

  useEffect(() => {
    if (!isLoading && isSuccess) {
      toast.success(t('back.user.update'), TOAST_OPTIONS);
    }
  }, [isLoading, isSuccess]);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = data?.qrCode as string;
    link.setAttribute('download', 'qr.png');
    link.click();
  };

  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  const handleUpdateQr = () => {
    updateQr(id);
    refetch();
  };

  return (
    <div className="flex flex-col items-center md:py-[10.417vw] xs:py-[8vw]">
      <p
        className={`font-medium text-[1.042vw] leading-[1.563vw] text-mainBlack mb-[1.354vw] md:text-center xs:text-center
    md:text-[2.604vw] md:leading-[3.906vw] md:mb-[5.208vw] xs:text-[4.533vw] xs:leading-[6.4vw] xs:mb-[8vw] md:max-w-[50vw] xs:max-w-[89.067vw]`}
      >
        {t('qr.text')}
      </p>
      <div className="px-[0.703vw] md:px-0 xs:px-0">
        <div
          className={`px-[1.25vw] py-[2.083vw] shadow-employee rounded-[0.833vw] border-[1px] border-authBorder mb-[2.24vw]
      md:rounded-[2.083vw] md:px-[1.693vw] md:py-[1.953vw] md:mb-[3.906vw]
      xs:rounded-[4.267vw] xs:px-[4.8vw] xs:py-[5.333vw] xs:mb-[6.933vw]`}
        >
          <img
            ref={ref}
            src={data?.qrCode}
            alt="qr code"
            className="w-[23.229vw] h-[21.927vw] md:w-[46.875vw] md:h-[46.875vw] xs:w-[80vw] xs:h-[80vw]"
          />
        </div>
        <div className="flex justify-between md:flex-col md:gap-[1.563vw] xs:gap-[3.2vw]">
          <Button onClick={handleDownload} className="w-[42%] md:w-full xs:w-full">
            {t('qr.download')}
          </Button>
          <Button onClick={handleUpdateQr} className="w-[42%] md:w-full xs:w-full">
            {t('qr.refresh')}
          </Button>
          <Button onClick={handlePrint} className="">
            {isMobile ? t('qr.print') : <PrintIcon />}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default QRBlock;
