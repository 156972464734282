import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as StarIcon } from '../../../../static/star_big.svg';
import { defaultRating } from '../config';

interface IProps {
  handleRatingClick: (n: number) => () => void;
  rating: number;
}

export const Rating: FC<IProps> = ({ handleRatingClick, rating }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-center rounded-[3.2vw] bg-white mx-[4vw]">
      <div className="pb-[3.733vw] pt-[2.133vw]">
        <p className="text-center mb-[1.067vw] text-black">{t('pay.rate')}</p>
        <div className="flex gap-[1.867vw]">
          {defaultRating.map(el => (
            <button onClick={handleRatingClick(el)} key={el}>
              <StarIcon
                fill={el <= rating ? '#F1B615' : '#DBDCDD'}
                className="w-[5.333vw] h-[5.333vw]"
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
