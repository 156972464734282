import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { TOAST_OPTIONS } from '../../config';
import { useLoader } from '../../hooks/useLoader';
import { IAdminDataResponse } from '../../redux/api/types/user';
import { useDeleteUserMutation, useGetAdminDataQuery } from '../../redux/api/user';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { closeModal } from '../../redux/slices/userSlice';
import SearchBar from '../../view/SearchBar';
import { Table } from '../AdminTable/components/Table';
import { ConfirmationModal } from '../ConfirmationModal';
import { Loader } from '../Loader';

export const OrganizationsBody = () => {
  const { data, refetch, isLoading } = useGetAdminDataQuery('confirmed', {
    refetchOnMountOrArgChange: true,
  });

  const [organizations, setOrganizations] = useState<IAdminDataResponse[]>([]);
  const [searchValue, setSearchValue] = useState('');

  const { modal } = useAppSelector(state => state.user);
  const [deleteId, setDeleteId] = useState(0);
  const [deleteUser, { isSuccess, isError }] = useDeleteUserMutation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isLoader = useLoader(isLoading);

  const confirmHandler = () => {
    deleteUser({ id: deleteId });
    dispatch(closeModal());
  };

  useEffect(() => {
    if (!isLoading && data) {
      setOrganizations(data);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (data) {
      setOrganizations(
        data.filter(e => e.organization.toLowerCase().includes(searchValue.toLowerCase()))
      );
    }
  }, [searchValue]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('back.user.delete'), TOAST_OPTIONS);
      refetch();
    }
    if (isError) toast.error(t('back.user.error'), TOAST_OPTIONS);
  }, [isSuccess, isError]);

  const declineHandler = () => {
    dispatch(closeModal());
    setDeleteId(0);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);

  return (
    <div className="p-[2.604vw] md:px-[3.255vw] md:py-[5.208vw] xs:px-0 xs:py-[8vw]">
      <div
        className={`p-[1.042vw] shadow-org rounded-[0.625vw] h-[44.479vw] flex flex-col gap-[1.042vw]
        md:p-[2.604vw] md:rounded-[1.563vw] md:h-[113.542vw] md:gap-[2.083vw]
        xs:p-[5.333vw] xs:rounded-[3.2vw] xs:h-[165.333vw] xs:gap-[3.2vw]`}
      >
        <p className="text-[1.25vw] leading-[1.771vw] text-mainBlack xs:text-[4.533vw] xs:leading-[6.4vw] md:text-[2.604vw] md:leading-[3.906vw]">
          {t('organizations.list')}
        </p>
        {!data || !data.length ? (
          <p
            className={`text-[1.25vw] leading-[1.771vw] text-lowGray mt-[2.083vw] text-center
            md:mt-[5.208vw] xs:mt-[10.667vw] md:text-[2.083vw] md:leading-[3.125vw] xs:text-[3.733vw] xs:leading-[5.333vw]`}
          >
            {t('organizations.noData')}
          </p>
        ) : (
          <>
            <SearchBar searchValue={searchValue} handleSearch={handleSearch} />
            <Table data={organizations} setDeleteId={setDeleteId} />
            <ConfirmationModal
              open={modal}
              title={t('organizations.deleteConfirm')}
              declineHandler={declineHandler}
              confirmHandler={confirmHandler}
            />
          </>
        )}
      </div>
      {isLoader && <Loader />}
    </div>
  );
};
