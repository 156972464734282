import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  card: {
    image: string;
    title: string;
    text: string;
  };
  index: number;
}

export const Slide: FC<IProps> = ({ card, index }) => {
  const { image, title, text } = card;
  const {
    t,
    i18n: { language: lang },
  } = useTranslation();
  const correctLang = lang.split('-').length > 1 ? lang.split('-')[0] : lang;
  return (
    <>
      <div
        className={`flex flex-col items-center text-white relative justify-center
          xs:w-full xs:h-[170.4vw] xs:gap-0 shrink-0
        `}
      >
        <div className="flex gap-[3.2vw]">
          <div
            className={`
            bg-white text-black font-bold items-center justify-center rounded-full flex
            xs:text-[5.333vw] xs:leading-[8vw] xs:w-[10.667vw] xs:h-[10.667vw]
          `}
          >
            {index}
          </div>
          <h3 className="font-bold xs:mb-[5.333vw] xs:text-[6.4vw] xs:leading-[9.067vw]">
            {t(title)}
          </h3>
        </div>
        <img
          src={`/images/landing/${image}${image !== 'recipe' ? '_' + correctLang : ''}.png`}
          alt="recipe"
          className="xs:mb-[5.333vw] xs:w-[66.667vw] xs:h-[114.667vw] md:-translate-x-[0.912vw]"
        />
        <p className="text-center xs:text-[4.267vw] xs:leading-[6.4vw] mb-auto max-w-[75.2vw]">
          {t(text)}
        </p>
      </div>
    </>
  );
};
