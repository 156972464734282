import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

import { legendView, placeholderView } from './classNames';

interface IProps {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type: string;
  id?: string;
  name?: string;
  inputStyle?: string;
  error?: string;
  required?: boolean;
  isTouched?: boolean;
  gap?: string;
  last?: boolean;
  disabled?: boolean;
  placeholderStyle?: string;
  wrapperStyle?: string;
}

export const FormTextInput: FC<IProps> = ({
  value,
  onChange,
  placeholder,
  id,
  name,
  inputStyle,
  required,
  error,
  gap,
  last,
  type,
  disabled,
  placeholderStyle,
  wrapperStyle,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isHide, setIsHide] = useState(type === 'password');
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isFocused) {
      ref.current?.focus();
    } else {
      ref.current?.blur();
    }
  }, [isFocused]);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const togglePass = () => setIsHide(prev => !prev);

  return (
    <fieldset
      className={`relative bg-white ${
        error
          ? last
            ? 'mb-[1.25vw] md:mb-[3.125vw] xs:mb-[6.4vw]'
            : 'mb-[0.313vw] md:mb-[0.781vw] xs:mb-[1.6vw]'
          : gap
          ? gap
          : 'mb-[1.25vw] md:mb-[3.125vw] xs:mb-[6.4vw]'
      } ${wrapperStyle}`}
    >
      <input
        value={value}
        ref={ref}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={handleFocus}
        id={id || ''}
        name={name || ''}
        disabled={disabled}
        type={isHide ? 'password' : type === 'password' ? 'text' : type}
        className={`${
          error
            ? 'mb-[0.313vw] border-mainRed focus:outline-mainRed md:mb-[0.781vw] xs:mb-[1.6vw]'
            : 'border-inputBorder'
        } w-full h-[2.5vw] rounder-[0.417vw] px-[0.729vw] py-[0.625vw]
         rounded-[0.417vw] border-[1px] text-authActive 
        text-[0.834vw] leading-[1.25vw]  ${inputStyle || ''}
        md:h-[6.25vw] md:rounded-[1.042vw] md:px-[1.823vw] md:py-[1.563vw] md:text-[2.083vw] md:leading-[3.125vw]
        xs:h-[12.8vw] xs:rounded-[2.133vw] xs:px-[3.733vw] xs:py-[3.2vw] xs:text-[4.267vw] xs:leading-[6.4vw]
        focus:outline-0 focus:border-[1px] focus:border-mainOrange`}
      />
      <span
        onClick={handleFocus}
        className={`absolute placeholder:font-normal transition-all duration-300 text-textGray
        ${
          isFocused || value ? legendView : `${placeholderView} ${value && 'hidden'}`
        } ${placeholderStyle}`}
      >
        {isFocused ? placeholder : required ? `${placeholder}*` : placeholder}
      </span>
      {type === 'password' && (
        <button className="" onClick={togglePass}>
          <img
            src={`/images/icons/eye_${isHide ? 'off' : 'on'}.svg`}
            alt="show password icon"
            className={`absolute top-[25%] ${
              error ? '-translate-y-[25%]' : '-translate-y-[0%]'
            } right-[8px] w-[1.25vw] opacity-25
            h-[1.25vw] md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]`}
          />
        </button>
      )}
      {error && (
        <p
          className={`text-[0.625vw] leading-[0.938vw] text-textGray flex items-center gap-[0.417vw]
          md:text-[1.563vw] md:leading-[2.344vw] xs:text-[3.2vw] xs:leading-[4.8vw] md:gap-[0.417vw] xs:gap-[2.133vw]`}
        >
          <img
            src="/images/icons/error.svg"
            alt="error icon"
            className="w-[0.833vw] h-[0.833vw] md:w-[2.083vw] md:h-[2.083vw] xs:w-[4.267vw] xs:h-[4.267vw]"
          />
          {error}
        </p>
      )}
    </fieldset>
  );
};
