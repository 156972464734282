import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../../../constants/routes';
import { dashboardLink } from '../../config';
import { hoverEffect } from '../share/classes';
import { Navbar } from '../share/Navbar';
import useMediaQuery from '../share/useQuery';

import { LanguageSelect } from './components/LanguageSelect';
import { SideMenu } from './components/SideMenu';

export const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isSideActive, setIsSideActive] = useState(false);
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 1280px) and (max-aspect-ratio: 1024/695)');

  const toggleBurger = () => setIsSideActive(prev => !prev);

  useEffect(() => {
    const handler = () => {
      if (isSticky && window.pageYOffset !== 0) return;
      if (window.pageYOffset > 5) setIsSticky(true);
      else setIsSticky(false);
    };

    document.addEventListener('scroll', handler);

    return () => {
      document.removeEventListener('scroll', handler);
    };
  }, [isSticky]);

  return (
    <>
      <header
        className={
          isSticky
            ? 'fixed top-0 z-[8] w-full flex justify-center'
            : 'top-0 absolute z-[8] w-full flex justify-center'
        }
      >
        <div
          className={`md:justify-between flex items-center justify-between transition-all duration-300 ease-in
      md:px-[5.208vw] xs:px-[5.6vw] xs:h-[20vw] h-[4.167vw] w-[71.198vw] md:h-[13.021vw] md:w-full
      ${
        isSticky &&
        'bg-[#181B23] rounded-[5.208vw] px-[1.302vw] md:h-[11.719vw] md:rounded-none md:top-0 mt-[0.938vw] md:mt-0'
      }`}
        >
          <div className="md:flex md:gap-[5.208vw] md:items-end xs:gap-[20.533vw]">
            <button onClick={toggleBurger} className="hidden md:block">
              <img
                src="/images/landing/menu.svg"
                className="md:w-[5.208vw] md:h-[5.208vw] xs:w-[10.667vw] xs:h-[10.667vw]"
                alt="burger menu"
              />
            </button>
            <Link to={ROUTES.LANDING}>
              <img
                src="/images/landing/logo.png"
                alt="main logo"
                className={`md:w-[12.76vw] md:h-[6.51vw] xs:w-[26.133vw] xs:h-[13.333vw] h-auto transition-[width] duration-300 ease-in ${
                  isSticky ? 'w-[4.375vw]' : 'w-[5.104vw]'
                }`}
              />
            </Link>
          </div>
          {/* <div className="flex items-center"> */}
          <Navbar mr={''} className="md:hidden" />
          <a
            href={dashboardLink}
            target="_blank"
            className={`px-[1.823vw] pt-[0.547vw] pb-[0.599vw] bg-phoneOrange whitespace-nowrap block text-mainBlack
          rounded-[5.208vw] font-bold text-[0.938vw] leading-[1.25vw] ${hoverEffect}
          md:text-[2.344vw] md:leading-[3.125vw] md:rounded-[13.021vw] md:px-[3.19vw] md:pt-[1.367vw]
          md:pb-[1.497vw] xs:hidden`}
            rel="noreferrer"
          >
            {t('landing.signIn')}
          </a>
          <LanguageSelect className="md:hidden" />
          {/* </div> */}
        </div>
      </header>
      {isMobile && <SideMenu toggleBurger={toggleBurger} isOpen={isSideActive} />}
    </>
  );
};
