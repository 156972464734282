import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useDarkTheme = (): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [isChecked, setIsChecked] = useState<boolean>(
    !document.documentElement.classList.contains('dark')
  );
  const [isFirstMount, setIsFirstMount] = useState(true);

  useEffect(() => {
    if (!isFirstMount) {
      if (!isChecked) {
        document.documentElement.classList.add('dark');
        document.documentElement.classList.remove('light');
      } else {
        document.documentElement.classList.remove('dark');
        document.documentElement.classList.add('light');
      }
    } else {
      if (document.documentElement.classList.contains('dark')) {
        setIsChecked(false);
      } else if (document.documentElement.classList.contains('light')) {
        setIsChecked(true);
      } else {
        setIsChecked(false);
      }
      setIsFirstMount(false);
    }
  }, [isChecked, isFirstMount]);

  return [isChecked, setIsChecked];
};
