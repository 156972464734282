import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ChangePasswordForm } from '../../components/ChangePasswordForm';
import { AuthTemplate } from '../../layouts/AuthTemplate';
import { useResetPasswordQuery } from '../../redux/api/auth';

export const PasswordRestore = () => {
  const { confirmId } = useParams();
  const expires = (confirmId as string).split('-').at(-1) as string;
  const { t } = useTranslation();
  const { data } = useResetPasswordQuery(confirmId as string);

  return (
    <AuthTemplate>
      {+expires < Date.now() ? (
        <p className="text-3xl leading-10">{t('confirmation.expired')}</p>
      ) : (
        <ChangePasswordForm isRestoring={true} data={data} />
      )}
    </AuthTemplate>
  );
};
