import { AddEmployeeBody } from '../../components/AddEmployeeBody';
import { DashboardTemplate } from '../../layouts/DashboradTemplate';

export const AddEmployee = () => {
  return (
    <DashboardTemplate>
      <AddEmployeeBody />
    </DashboardTemplate>
  );
};
