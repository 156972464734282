import MenuCategoryBody from '../../components/MenuCategoryBody';
import { DashboardTemplate } from '../../layouts/DashboradTemplate';

export const MenuCategory = () => {
  return (
    <DashboardTemplate>
      <MenuCategoryBody />
    </DashboardTemplate>
  );
};
