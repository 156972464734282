import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { yellowDot } from '../../../share/classes';

import { Balls } from './components/Balls';

export const SecondSection = () => {
  const [hover, setHover] = useState(-1);
  const {
    t,
    i18n: { language: lang },
  } = useTranslation();
  const correctLang = lang.split('-').length > 1 ? lang.split('-')[0] : lang;
  const handleFocus = (i: number) => () => setHover(i);
  const handleLeave = () => setHover(-1);

  return (
    <section
      className={`w-full flex flex-col items-center pt-[5.989vw] mb-[4.219vw]: md:mb-[10.417vw] pb-[5.208vw] md:pb-[2.214vw]
      xs:pb-[36.267vw] xs:mb-[21.333vw] mt-[-4.5vw] md:pt-[14.302vw] md:mt-[-14.302vw] xs:pt-[19vw] xs:mt-[-19vw]`}
      id="second"
    >
      <h2
        className={`font-bold text-white text-[2.604vw] md:text-[5.208vw] leading-[5.729vw]
        md:leading-[7.292vw] mb-[0.469vw] md:mb-[11.979vw] uppercase 
        xs:leading-[12.8vw] xs:text-[8vw] xs:mb-[33.067vw]`}
      >
        {t('landing.advantages.title')}
      </h2>
      <div className="bg-advBg w-full md:w-[87.89vw] xs:w-full rounded-[1.042vw] md:rounded-[2.604vw] xs:rounded-[2.639vw] flex flex-col items-center relative">
        <div
          className={`relative pl-[20.313vw] md:pl-[22.656vw] pr-[9.219vw] md:pr-0 pb-[6.302vw]
            md:pb-[4.427vw] pt-[2.708vw] md:pt-[5.078vw] flex gap-[1.406vw]
            md:gap-[2.734vw] w-full -translate-y-[0.052vw]
            xs:pt-[46.933vw] xs:pl-[5.6vw] xs:pr-[8.533vw] xs:pb-[8vw] xs:gap-[3.2vw] z-[1]`}
        >
          <p
            className={`font-viga text-[3.68vw] md:text-[4.552vw] leading-[4.948vw]
            md:leading-[6.12vw] text-[#F1B615] -translate-y-[0.6vw] md:-translate-y-[0.4vw]
            xs:text-[9.323vw] xs:leading-[12.533vw] xs:-translate-y-[0.8vw]`}
          >
            01
          </p>
          <div className="flex flex-col gap-[0.833vw] md:gap-[1.432vw] xs:gap-[3.733vw]">
            <p
              className={`text-[2.083vw] md:text-[2.604vw] leading-[2.604vw] md:leading-[3.906vw]
              text-white max-w-[36.523vw] md:max-w-[49.479vw]
              xs:text-[4.8vw] xs:leading-[8vw] xs:max-w-[76vw]`}
            >
              {t('landing.advantages.first')}
            </p>
            <ul
              className={`text-white text-[1.25vw] md:text-[2.083vw] leading-[1.771vw]
              md:leading-[3.125vw] gap-[0.521vw] md:gap-[0.781vw] flex flex-col
              xs:text-[4.267vw] xs:leading-[6.4vw] xs:gap-[3.2vw]`}
            >
              <li className={yellowDot}>{t('landing.advantages.text1')}</li>
              <li className={yellowDot}>{t('landing.advantages.text2')}</li>
            </ul>
          </div>
          <div
            className="absolute -left-[7.917vw] md:-left-[7.626vw] -top-[8.75vw] md:-top-[12.866vw] xs:left-0 xs:-top-[25.067vw]"
            onMouseEnter={handleFocus(0)}
            onMouseLeave={handleLeave}
          >
            <img
              src="/images/landing/imac.png"
              alt="imac"
              className="w-[23.438vw] md:w-[29.035vw] h-[26.042vw] md:h-[32.164vw] xs:w-[59.467vw] xs:h-[65.867vw]"
            />
            <img
              src={`/images/landing/imac_anim_${correctLang}.png`}
              alt="imac screen"
              className={`w-[19.87vw] md:w-[24.578vw] h-[16.888vw] md:h-[20.89vw] absolute transition-all duration-500 ease-out ${
                hover === 0
                  ? 'left-[24.333%] -top-[0.9%]'
                  : 'left-[8.778%] md:left-[8.762%] top-[5.1%] md:top-[5.109%]'
              } xs:w-[50.336vw] xs:h-[42.784vw]`}
            />
          </div>
        </div>
        <div className="w-[64.896vw] md:w-[80.339vw] xs:w-[89.333vw] h-[1px] opacity-30 bg-white" />
        <div
          className={`relative pl-[6.458vw] pr-[9.219vw] pb-[6.146vw] pt-[4.898vw] flex gap-[1.458vw]
          w-full md:pl-[7.682vw] md:pr-[27.604vw] md:pb-[5.078vw] md:pt-[4.427vw] md:gap-[2.344vw]
          xs:pt-[8vw] xs:pl-[5.6vw] xs:pr-[5.333vw] xs:pb-[20.533vw] xs:gap-[2.133vw] z-[1]`}
        >
          <p
            className={`font-viga text-[3.68vw] md:text-[4.552vw] leading-[4.948vw]
            md:leading-[6.12vw] text-[#F1B615] -translate-y-[0.973vw]
            xs:text-[9.323vw] xs:leading-[12.533vw] xs:-translate-y-[1.9vw]`}
          >
            02
          </p>
          <div className="flex flex-col gap-[1.354vw]">
            <p
              className={`text-[2.083vw] md:text-[2.604vw] leading-[2.604vw] md:leading-[3.906vw]
              text-white max-w-[36.523vw] md:max-w-[49.479vw]
              xs:text-[4.8vw] xs:leading-[8vw] xs:max-w-[76vw]`}
            >
              {t('landing.advantages.second')}
            </p>
            <ul
              className={`text-white text-[1.25vw] md:text-[2.083vw] leading-[1.771vw]
              md:leading-[3.125vw] gap-[0.521vw] md:gap-[0.781vw] flex flex-col
              xs:text-[4.267vw] xs:leading-[6.4vw] xs:gap-[3.2vw]`}
            >
              <li className={yellowDot}>{t('landing.advantages.text3')}</li>
              <li className={yellowDot}>{t('landing.advantages.text4')}</li>
            </ul>
          </div>
          <div
            className={`absolute left-[50.729vw] -top-[1.406vw] w-[25.486vw] h-[22.656vw]
            md:w-[33.203vw] md:h-[29.557vw] md:-top-[4.818vw] md:left-[62.37vw]
            xs:w-[68vw] xs:h-[60.413vw] xs:left-[44.533vw] xs:top-[35.767vw]`}
            onMouseEnter={handleFocus(1)}
            onMouseLeave={handleLeave}
          >
            <img
              src={`/images/landing/card_front_${correctLang}.png`}
              alt="front card"
              className={`origin-center absolute bottom-0 left-0 w-[20.596vw] h-[11.485vw] transition-transform duration-500 ease-out
              drop-shadow-[4.17913px_10.9702px_13.5822px_rgba(41,39,39,0.25)] z-[2] md:h-[15.115vw] md:w-[26.832vw] ${
                hover === 1 ? '-rotate-[12deg]' : '-rotate-[0]'
              } xs:w-[54.492vw] xs:h-[30.893vw]`}
            />
            <img
              src={`/images/landing/card_front_${correctLang}.png`}
              alt="back card"
              className={`origin-bottom-right absolute bottom-[7.33%] left-[0%] w-[20.596vw] h-[11.485vw] transition-transform duration-500 ease-out
              drop-shadow-[4.17913px_10.9702px_13.5822px_rgba(41,39,39,0.25)] z-[1] rouded-[0.521vw] md:rounded-[0.671vw] brightness-[80%] md:h-[15.115vw] md:w-[26.832vw] ${
                hover === 1 ? 'rotate-[32.38deg]' : 'rotate-[14.88deg]'
              } xs:w-[54.492vw] xs:h-[30.893vw]`}
            />
          </div>
        </div>
        <Balls />
      </div>
    </section>
  );
};
