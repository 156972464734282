import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';

/* eslint-disable */

const events = {
  on(el: any, type: any, callback: any) {
    if (el.addEventListener) {
      el.addEventListener(type, callback);
    } else {
      el.attachEvent(`on${type}`, () => {
        callback.call(el);
      });
    }
  },

  off(el: any, type: any, callback: any) {
    if (el.removeEventListener) {
      el.removeEventListener(type, callback);
    } else {
      el.detachEvent(`on${type}`, callback);
    }
  },
};

const isDescendant = (el: any, target: any): boolean => {
  if (target !== null) {
    return el === target || isDescendant(el, target.parentNode);
  }
  return false;
};

const clickAwayEvents = ['mouseup', 'touchend'];
const bind = (callback: any) =>
  clickAwayEvents.forEach(event => events.on(document, event, callback));
const unbind = (callback: any) =>
  clickAwayEvents.forEach(event => events.off(document, event, callback));

export default class ClickAwayListener extends React.Component<{
  children: ReactNode;
  onClickAway: any;
}> {
  componentDidMount() {
    if (this.props.onClickAway) {
      bind(this.handleClickAway);
    }
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.onClickAway !== this.props.onClickAway) {
      unbind(this.handleClickAway);
      if (this.props.onClickAway) {
        bind(this.handleClickAway);
      }
    }
  }

  componentWillUnmount() {
    unbind(this.handleClickAway);
  }

  handleClickAway = (event: any) => {
    if (event.defaultPrevented) {
      return;
    }

    const el = ReactDOM.findDOMNode(this);

    if (document.documentElement.contains(event.target) && !isDescendant(el, event.target)) {
      this.props.onClickAway(event);
    }
  };

  render() {
    return this.props.children;
  }
}
