import { RatingBody } from '../../components/RatingBody';
import { DashboardTemplate } from '../../layouts/DashboradTemplate';

export const Rating = () => {
  return (
    <DashboardTemplate>
      <RatingBody />
    </DashboardTemplate>
  );
};
