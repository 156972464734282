import { Allergens, DishTypes, FormikConfig } from '../../types/base';

export const config: FormikConfig = {
  name: {
    type: 'text',
    required: true,
    placeholder: 'foodMenu.item.name',
    initial: '',
  },
  price: {
    type: 'number',
    required: true,
    placeholder: 'foodMenu.item.price',
    initial: '0',
  },
};

export const additionalFields: FormikConfig = {
  compound: {
    type: 'text',
    required: false,
    placeholder: 'foodMenu.item.compound',
    initial: '',
  },
  type: {
    type: 'text',
    required: false,
    placeholder: 'foodMenu.item.type',
    initial: '',
  },
  calories: {
    type: 'number',
    required: false,
    placeholder: 'foodMenu.item.calories',
    initial: '',
  },
  protein: {
    type: 'number',
    required: false,
    placeholder: 'foodMenu.item.protein',
    initial: '',
  },
  fat: {
    type: 'number',
    required: false,
    placeholder: 'foodMenu.item.fat',
    initial: '',
  },
  carbohydrate: {
    type: 'number',
    required: false,
    placeholder: 'foodMenu.item.carbs',
    initial: '',
  },
  time: {
    type: 'number',
    required: false,
    placeholder: 'foodMenu.item.time',
    initial: '',
  },
  categoryId: {
    type: 'text',
    required: true,
    placeholder: 'foodMenu.item.category',
    initial: '',
  },
};

export const KBJU = ['calories', 'protein', 'fat', 'carbohydrate'];
export const KBJUInputs = [
  'md:max-w-[11.9vw] xs:max-w-full',
  'md:max-w-[12vw] xs:max-w-full',
  'md:max-w-[11vw] xs:max-w-full',
  '',
];

export const defaultDishTypes = [
  {
    title: 'foodMenu.item.types.vegetarian',
    value: DishTypes.VEGETARIAN,
  },
  {
    title: 'foodMenu.item.types.vegan',
    value: DishTypes.VEGAN,
  },
  {
    title: 'foodMenu.item.types.post',
    value: DishTypes.POST,
  },
  {
    title: 'foodMenu.item.types.diet',
    value: DishTypes.DIET,
  },
  {
    title: 'foodMenu.item.types.hot',
    value: DishTypes.HOT,
  },
  {
    title: 'foodMenu.item.types.kosher',
    value: DishTypes.KOSHER,
  },
  {
    title: 'foodMenu.item.types.halal',
    value: DishTypes.HALAL,
  },
];

export const convertAllergenToSelectItem = (allergen: Allergens) => {
  const translationKey = allergen.includes('-')
    ? allergen
        .split('-')
        .map((el, i) => {
          return i === 0 ? el : el.slice(0, 1).toUpperCase() + el.slice(1);
        })
        .join('')
    : `${allergen}`;
  return {
    title: `foodMenu.item.allergens.${translationKey}`,
    value: allergen,
  };
};

export const getDefaultAllergenTypes = () => {
  return Object.entries(Allergens).map(([, value]) => {
    return convertAllergenToSelectItem(value);
  });
};

const defaultTimesMinutes = ['10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
const defaultTimesHours = ['1'];

export const getDefaultTimeTypes = () => {
  const minuteTypes = defaultTimesMinutes.map(el => ({
    title: `${el} foodMenu.item.minute`,
    value: el,
  }));
  const hourTypes = defaultTimesHours.map(el => ({
    title: `${el} foodMenu.item.hour`,
    value: el,
  }));

  return [...minuteTypes, ...hourTypes];
};
