import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Advantage = () => {
  const [hoverId, setHoverId] = useState(-1);
  const { t } = useTranslation();

  const handleHover = (i: number) => () => setHoverId(i);
  const handleLeave = () => setHoverId(-1);

  return (
    <div className="flex justify-between select-none md:flex-col xs:flex-col md:pl-[5.078vw] md:gap-[5.208vw] xs:gap-[15.467vw]">
      <div
        className="w-[20.99vw] flex items-center justify-between relative md:gap-[2.604vw] md:w-[75.391vw] md:justify-start xs:w-[89.6vw] xs:gap-[5.333vw]"
        onMouseEnter={handleHover(1)}
        onMouseLeave={handleLeave}
      >
        <img
          src="/images/landing/shield.png"
          alt="shield"
          className={`w-[5.625vw] h-[5.625vw] transition-transform md:w-[10.417vw] md:h-[10.417vw] duration-[350ms] ${
            hoverId === 1 ? 'scale-[1.13]' : 'scale-100'
          } ease-linear z-[2] relative xs:w-[21.333vw] xs:h-[21.333vw]`}
        />
        <div
          className={`top-0 left-0 absolute w-[5.938vw] h-[5.677vw] transition-opacity duration-[350ms] md:hidden ${
            hoverId === 1 ? 'opacity-1' : 'opacity-0'
          } rounded-[40.559vw] ease-linear bg-yellowTr blur-xl z-[1]`}
        />
        <p
          className={`text-[#CFD5DF] text-[1.042vw] leading-[1.563vw] max-w-[14.323vw]
          md:max-w-[60.37vw] md:text-[2.604vw] md:leading-[3.906vw]
          xs:max-w-[62.933vw] xs:text-[5.333vw] xs:leading-[8vw]`}
        >
          {t('landing.first.shield')}
        </p>
      </div>
      <div
        className="w-[20.99vw] flex items-center justify-between relative md:gap-[2.604vw] md:w-[75.391vw] md:justify-start xs:w-[89.6vw] xs:gap-[5.333vw]"
        onMouseEnter={handleHover(2)}
        onMouseLeave={handleLeave}
      >
        <img
          src="/images/landing/screen.png"
          alt="screen"
          className={`w-[5.625vw] h-[5.625vw] transition-transform duration-[350ms] md:w-[10.417vw] md:h-[10.417vw] ${
            hoverId === 2 ? 'scale-[1.13]' : 'scale-100'
          } ease-linear relative z-[2] xs:w-[21.333vw] xs:h-[21.333vw]`}
        />

        <div
          className={`top-0 left-0 absolute w-[5.938vw] h-[5.677vw] transition-opacity duration-[350ms] md:hidden ${
            hoverId === 2 ? 'opacity-1' : 'opacity-0'
          } rounded-[40.559vw] ease-linear bg-yellowTr blur-xl z-[1]`}
        />
        <p
          className={`text-[#CFD5DF] text-[1.042vw] leading-[1.563vw] max-w-[14.323vw]
          md:max-w-[60.37vw] md:text-[2.604vw] md:leading-[3.906vw]
          xs:max-w-[62.933vw] xs:text-[5.333vw] xs:leading-[8vw]`}
        >
          {t('landing.first.screen')}
        </p>
      </div>
      <div
        className="w-[20.99vw] flex items-center justify-between relative md:gap-[2.604vw] md:w-[75.391vw] md:justify-start xs:w-[89.6vw] xs:gap-[5.333vw]"
        onMouseEnter={handleHover(3)}
        onPointerLeave={handleLeave}
      >
        <img
          src="/images/landing/thumb.png"
          alt="thumb up"
          className={`w-[5.625vw] h-[5.625vw] transition-transform duration-[350ms] md:w-[10.417vw] md:h-[10.417vw] ${
            hoverId === 3 ? 'scale-[1.13]' : 'scale-100'
          } ease-linear relative z-[2] xs:w-[21.333vw] xs:h-[21.333vw]`}
        />
        <div
          className={`top-0 left-0 absolute w-[5.938vw] h-[5.677vw] transition-opacity duration-[350ms] md:hidden ${
            hoverId === 3 ? 'opacity-1' : 'opacity-0'
          } rounded-[40.559vw] ease-linear bg-yellowTr blur-xl z-[1]`}
        />
        <p
          className={`text-[#CFD5DF] text-[1.042vw] leading-[1.563vw] max-w-[14.323vw]
          md:max-w-[60.37vw] md:text-[2.604vw] md:leading-[3.906vw]
          xs:max-w-[62.933vw] xs:text-[5.333vw] xs:leading-[8vw]`}
        >
          {t('landing.first.thumb')}
        </p>
      </div>
    </div>
  );
};
