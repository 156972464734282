import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLoader } from '../../hooks/useLoader';
import { IReviewItem } from '../../redux/api/types/reviews';
import { useGetAdminTransactionsQuery } from '../../redux/api/user';
import SearchBar from '../../view/SearchBar';
import { Select } from '../../view/Select';
import { Table } from '../AnalyticsBody/components/Table';
import { Loader } from '../Loader';
import { PDFButton } from '../PDFButton';

import { selectDate } from './config';

const TransactionsBody = () => {
  const [period, setPeriod] = useState(selectDate[0].value);
  const [filteredData, setFilteredData] = useState<IReviewItem[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation();
  const { data, isLoading, isFetching } = useGetAdminTransactionsQuery(period, {
    refetchOnMountOrArgChange: true,
  });

  const isLoader = useLoader(isLoading, isFetching);

  const handlePeriodChange = (value: string) => {
    setPeriod(value);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (data) {
        setFilteredData(
          data.reviews.filter(el =>
            el.user.organization.toLowerCase().includes(searchValue.toLowerCase())
          )
        );
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);

  useEffect(() => {
    if (data) {
      setSearchValue('');
      setFilteredData(data.reviews);
    }
  }, [data]);

  return (
    <div className="px-[2.604vw] py-[2.083vw] md:px-[3.255vw] md:py-[3.906vw] xs:px-[5.333vw] xs:py-[6.4vw] overflow-x-hidden bg-white">
      <div className="flex justify-between xs:flex-col xs:gap-[10px] mb-[10px]">
        <Select options={selectDate} onChange={handlePeriodChange} className="mb-[20px]" />
        <PDFButton period={period} isAdmin={true} />
      </div>
      <div className="xs:overflow-auto shadow-pass rounded-[0.625vw] md:rounded-[1.563vw] xs:rounded-[3.2vw]">
        <div
          className={`w-full p-[1.302vw] flex flex-col gap-[1.042vw] h-full min-h-screen
          md:p-[2.604vw] md:gap-[5.208vw] xs:p-[5.333vw] xs:gap-[4.267vw] xs:w-[170.133vw]`}
        >
          <div className="flex flex-wrap gap-[10px]">
            <p className="font-bold text-mainBlack text-[1.25vw] leading-[1.771vw] md:text-[2.604vw] md:leading-[3.906vw] xs:text-[5.333vw] xs:leading-[8vw]">
              {t('analytic.income')} - {data?.reviews.length}
            </p>
            <p className="font-bold text-mainBlack text-[1.25vw] leading-[1.771vw] md:text-[2.604vw] md:leading-[3.906vw] xs:text-[5.333vw] xs:leading-[8vw]">
              {t('analytic.tips')} - {data?.total ?? 0}
            </p>
            <p className="font-bold text-mainBlack text-[1.25vw] leading-[1.771vw] md:text-[2.604vw] md:leading-[3.906vw] xs:text-[5.333vw] xs:leading-[8vw]">
              {t('analytic.statusSuccess')} - {data?.positive}
            </p>
            <p className="font-bold text-mainBlack text-[1.25vw] leading-[1.771vw] md:text-[2.604vw] md:leading-[3.906vw] xs:text-[5.333vw] xs:leading-[8vw]">
              {t('analytic.statusReject')} - {data?.negative}
            </p>
          </div>
          <SearchBar searchValue={searchValue} handleSearch={handleSearch} />
          <Table data={filteredData} admin={true} />
        </div>
      </div>
      {isLoader && <Loader />}
    </div>
  );
};

export default TransactionsBody;
