import { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { cards } from '../../config';

import { Slide } from './Slide';

import 'swiper/css';

export const SwiperBlock = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  /* eslint-disable-next-line */
  const swiperInst = useRef<any>();

  const next = () => swiperInst.current?.slideNext();
  const prev = () => swiperInst.current?.slidePrev();

  return (
    <div className="w-full relative overflow-x-hidden scrollbar-hide">
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={swiper => setCurrentSlide(swiper.activeIndex)}
        onAfterInit={swiper => (swiperInst.current = swiper)}
      >
        {cards.map((card, i) => (
          <SwiperSlide key={card.image}>
            <Slide card={card} index={i + 1} />
          </SwiperSlide>
        ))}
      </Swiper>
      {currentSlide + 1 !== cards.length && (
        <button onClick={next}>
          <img
            src="/images/landing/arrow_right.svg"
            alt="right arrow"
            className="absolute xs:w-[10.667vw] xs:h-[10.667vw] xs:-translate-y-[230%] right-[2.667vw] z-[1] top-[50%]"
          />
        </button>
      )}
      {currentSlide !== 0 && (
        <button onClick={prev}>
          <img
            src="/images/landing/arrow_right.svg"
            alt="right arrow"
            className="absolute xs:w-[10.667vw] xs:h-[10.667vw] xs:-translate-y-[230%] left-[2.667vw] z-[1] top-[50%] rotate-180"
          />
        </button>
      )}
    </div>
  );
};
