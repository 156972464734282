import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { replacePolicyLabel } from '../../../../utils/replacePolicyText';
import { Checkbox } from '../../../../view/Checkbox';
import { policy } from '../config';

interface IProps {
  compensate: boolean;
  toggleCompensate: () => void;
  terms: boolean;
  toggleTerms: () => void;
  isDisabled: boolean;
}

export const Agreement: FC<IProps> = ({
  terms,
  toggleTerms,
  compensate,
  toggleCompensate,
  isDisabled,
}) => {
  const {
    t,
    i18n: { language: lang },
  } = useTranslation();

  const correctLang = lang ? (lang.split('-').length > 1 ? lang.split('-')[0] : lang) : 'en';

  const policyText = t('pay.terms');
  const nextText = replacePolicyLabel(policyText, correctLang, policy);

  return (
    <div className="px-[4vw] flex flex-col gap-[2.667vw]">
      <Checkbox
        labelText={t('pay.compensate') || ''}
        checked={compensate}
        toggle={toggleCompensate}
        classNames="items-start xs:ml-0"
        labelClassnames="text-white xs:text-[3.2vw] xs:leading-[4.8vw]"
        name="compensate"
      />
      <Checkbox
        labelText={nextText}
        checked={terms}
        toggle={toggleTerms}
        classNames="items-start xs:ml-0"
        labelClassnames="text-white xs:text-[3.2vw] xs:leading-[4.8vw]"
        name="terms"
        disabled={isDisabled}
        withShadow={true}
      />
    </div>
  );
};
