import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLoader } from '../../hooks/useLoader';
import { useGetUserReviewQuery } from '../../redux/api/reviews';
import { useAppSelector } from '../../redux/hooks';
import { ReviewKeyType } from '../../types/base';
import { Card } from '../../view/Card';
import { Select } from '../../view/Select';
import { Loader } from '../Loader';

import { Table } from './components/Table';
import { cards, selectDate, selectReceiver } from './config';

export const AnalyticsBody = () => {
  const { id, role } = useAppSelector(state => state.user);
  const [period, setPeriod] = useState(selectDate[0].value);
  const [receiver, setReceiver] = useState(selectReceiver[0].value);
  const { t } = useTranslation();
  const { data, isLoading, refetch, isFetching } = useGetUserReviewQuery(
    { id, period, receiver },
    {
      refetchOnMountOrArgChange: true,
      skip: role === 'employee' || !id,
    }
  );
  const isLoader = useLoader(isLoading, isFetching);

  const receiverSelectData = useMemo(() => {
    if (data) {
      return [
        ...selectReceiver,
        ...data.employyes.map(e => ({
          title: `${e.lastName} ${e.firstName}`,
          value: e.id.toString(),
        })),
      ];
    } else {
      return selectReceiver;
    }
  }, [data]);

  const sortedData = useMemo(() => {
    if (data) {
      return [...data?.negative, ...data?.positive].sort(
        (a, b) => new Date(b.reviewDate).getTime() - new Date(a.reviewDate).getTime()
      );
    }
    return [];
  }, [data]);

  const handlePeriodChange = (value: string) => {
    setPeriod(value);
  };
  const handleReceiverChange = (value: string) => {
    setReceiver(value);
  };

  useEffect(() => {
    refetch();
  }, [period, receiver]);

  return (
    <div className="px-[2.604vw] py-[2.083vw] md:px-[3.255vw] md:py-[3.906vw] xs:px-[5.333vw] xs:py-[6.4vw] overflow-x-hidden bg-white">
      <div className="flex items-center gap-[1.094vw] md:gap-[2.604vw] mb-[1.354vw] md:mb-[2.604vw] xs:gap-[3.2vw] xs:mb-[5.333vw] xs:flex-col">
        <Select options={selectDate} onChange={handlePeriodChange} />
        <Select options={receiverSelectData} onChange={handleReceiverChange} />
      </div>
      <div className="flex items-center gap-[1.042vw] mb-[2.083vw] md:gap-[2.04vw] md:mb-[3.125vw] xs:mb-[6.4vw] md:flex-wrap xs:flex-wrap xs:justify-center">
        {cards.map(el => (
          <Card
            tooltipText={t(el.tooltipText) || ''}
            title={t(el.title) || ''}
            key={el.title}
            className="flex flex-col justify-between"
          >
            <p className="font-bold text-[1.563vw] leading-[2.083vw] text-end md:text-[3.125vw] md:leading-[4.427vw] xs:text-[6.4vw] xs:leading-[9.067vw]">
              {data?.[el.key as ReviewKeyType] || (el.key === 'averageRating' ? '0.0' : 0)}
            </p>
          </Card>
        ))}
      </div>
      <div className="xs:overflow-auto shadow-pass rounded-[0.625vw] md:rounded-[1.563vw] xs:rounded-[3.2vw]">
        <div
          className={`w-full p-[1.302vw] flex flex-col gap-[1.042vw] h-[31.25vw]
        md:p-[2.604vw] md:gap-[5.208vw] md:h-[69.01vw]
        xs:p-[5.333vw] xs:gap-[4.267vw] xs:h-[48vw] xs:w-[170.133vw]`}
        >
          <p className="font-bold text-mainBlack text-[1.25vw] leading-[1.771vw] md:text-[2.604vw] md:leading-[3.906vw] xs:text-[5.333vw] xs:leading-[8vw]">
            {t('analytic.income')}
          </p>
          <Table data={sortedData} />
        </div>
      </div>
      {isLoader && <Loader />}
    </div>
  );
};
