import { ProfileBody } from '../../components/ProfileBody';
import { DashboardTemplate } from '../../layouts/DashboradTemplate';

export const Profile = () => {
  return (
    <DashboardTemplate>
      <ProfileBody />
    </DashboardTemplate>
  );
};
