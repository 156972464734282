import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import { TOAST_OPTIONS } from '../../../../config';
import { useGetAfterPayDataQuery, useSendPromoDataMutation } from '../../../../redux/api/payment';
import { useAppSelector } from '../../../../redux/hooks';
import { Button } from '../../../../view/Button';
import { FormTextInput } from '../../../../view/FormTextInput';
import { Loader } from '../../../Loader';

import FileBlock from './components/FileBlock';

const PromoBlock = () => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File>();
  const { id } = useAppSelector(state => state.user);
  const { data, isLoading } = useGetAfterPayDataQuery(`${id}`);
  const [sendData, { isLoading: isSendLoading, isSuccess }] = useSendPromoDataMutation();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputFile = e.target.files?.[0];
    setFile(inputFile);
  };

  useEffect(() => {
    if (!isSendLoading && isSuccess) {
      toast.success(t('back.user.update'), TOAST_OPTIONS);
    }
  }, [isSendLoading, isSuccess]);

  const initialValues = {
    title: data?.title || '',
    description: data?.description || '',
    buttonText: data?.buttonText || '',
    link: data?.link || '',
  };

  const handleSubmit = (values: typeof initialValues) => {
    const { title, description, buttonText, link } = values;

    const formData = new FormData();

    formData.append('file', file as File);
    formData.append('title', title ?? null);
    formData.append('description', description ?? null);
    formData.append('buttonText', buttonText ?? null);
    formData.append('link', link ?? null);
    formData.append('organizationId', `${id}` ?? null);

    sendData(formData);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const handleClearAll = () => {
    formik.resetForm();
    setFile(undefined);
  };

  return (
    <div className="flex flex-col gap-[1.042vw] w-6/12 md:w-full md:py-[10.417vw] md:gap-[2.604vw] xs:gap-[3.2vw] xs:py-[8vw]">
      {(isLoading || isSendLoading) && <Loader />}
      <h3 className="font-bold text-mainBlack text-[1.563vw] leading-[2.083vw] md:text-[3.906vw] md:leading-[5.208vw] xs:text-[5.333vw] xs:leading-[8vw]">
        {t('qr.promo.title')}
      </h3>
      <FormTextInput
        value={formik.values.title}
        onChange={formik.handleChange}
        type="text"
        id="title"
        name="title"
        placeholder={t('qr.promo.titleInput') || ''}
        gap="0"
      />
      <textarea
        className={`
              focus:outline-none w-full border-[1px] resize-none text-[0.833vw] leading-[1.25vw] px-[0.729vw] py-[0.625vw]
              placeholder:text-[0.833vw] placeholder:leading-[1.25vw] placeholder:text-midGray md:rounded-[1.042vw]
              rounded-[0.417vw] bg-white border-inputBorder md:px-[1.823vw] md:py-[1.563vw] xs:rounded-[2.133vw]
              md:text-[2.083vw] md:leading-[3.125vw] xs:text-[4.267vw] xs:leading-[6.4vw] xs:px-[3.733vw] xs:py-[3.2vw]
              placeholder:md:text-[2.083vw] placeholder:md:leading-[3.125vw] placeholder:xs:text-[4.267vw] placeholder:xs:leading-[6.4vw]
            `}
        placeholder={t('qr.promo.description') || ''}
        value={formik.values.description}
        onChange={formik.handleChange}
        id="description"
        name="description"
        rows={3}
        maxLength={120}
      />
      <FormTextInput
        value={formik.values.buttonText}
        onChange={formik.handleChange}
        type="text"
        id="buttonText"
        name="buttonText"
        placeholder={t('qr.promo.buttonText') || ''}
        gap="0"
      />
      <FormTextInput
        value={formik.values.link}
        onChange={formik.handleChange}
        type="text"
        id="link"
        name="link"
        placeholder={t('qr.promo.link') || ''}
        gap="0"
      />
      <FileBlock file={file} onChange={handleFileChange} imageFromDB={data?.image} />
      <div className="w-full flex justify-between">
        <Button className="w-[48%]" onClick={formik.handleSubmit}>
          {t('saveBtn')}
        </Button>
        <Button className="w-[48%]" onClick={handleClearAll}>
          {t('clearBtn')}
        </Button>
      </div>
    </div>
  );
};

export default PromoBlock;
