export const defaultTips = [5, 10, 20, 30];
export const defaultRating = [1, 2, 3, 4, 5];
export const markItems = [
  {
    icon: 'clean',
    name: 'pay.types.clean',
  },
  {
    icon: 'service',
    name: 'pay.types.service',
  },
  {
    icon: 'food',
    name: 'pay.types.food',
  },
  { icon: 'interior', name: 'pay.types.interior' },
  { icon: 'music', name: 'pay.types.music' },
  { icon: 'politeness', name: 'pay.types.politeness' },
];

export const policy = {
  en: {
    agreement: 'User Agreement',
    policy: 'Personal Data Processing Policy',
  },
  ru: {
    agreement: 'Пользовательского соглашения',
    policy: 'Политики обработки персональных данных',
  },
  ge: {
    agreement: 'შეთანხმების პირობებს',
    policy: 'პერსონალური მონაცემთა დამუშავების პოლიტიკას',
  },
};
