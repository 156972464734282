import { OrganizationsBody } from '../../components/OgranizationsBody';
import { DashboardTemplate } from '../../layouts/DashboradTemplate';

export const Organizations = () => {
  return (
    <DashboardTemplate>
      <OrganizationsBody />
    </DashboardTemplate>
  );
};
