import { FC, ReactNode } from 'react';

import { useRoute } from '../../hooks/useRoute';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { Main } from '../Main';

interface IProps {
  children: ReactNode;
}

export const AuthTemplate: FC<IProps> = ({ children }) => {
  useRoute();
  return (
    <div className="bg-white flex flex-col justify-between w-full h-full">
      <Header />
      <Main className="flex items-center justify-center py-[1.042vw] md:py-[2.604vw] xs:py-[5.333vw]">
        {children}
      </Main>
      <Footer />
    </div>
  );
};
