import { AnalyticsBody } from '../../components/AnalyticsBody';
import { PDFButton } from '../../components/PDFButton';
import { DashboardTemplate } from '../../layouts/DashboradTemplate';

export const Analytics = () => {
  return (
    <DashboardTemplate headerChildren={<PDFButton />}>
      <AnalyticsBody />
    </DashboardTemplate>
  );
};
