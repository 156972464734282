import { ChangeEvent } from 'react';
import { useFormik } from 'formik';

import { FormikConfig } from '../types/base';

export const creteInitialValues = <T>(config: FormikConfig, data?: Partial<T>) => {
  return Object.fromEntries(
    Object.entries(config).map(entry => {
      const [key, value] = entry;
      if (data) {
        return [key, data[key as keyof T]?.toString() || ''];
      }
      return [key, value.initial];
    })
  );
};

export const handleFormWithPhone =
  (type: string, formik: ReturnType<typeof useFormik>) => (e: ChangeEvent<HTMLInputElement>) => {
    switch (type) {
      case 'phone': {
        let value = e.target.value.replace(/\D+/g, '');
        value = value ? `+${value}` : '';
        formik.setFieldValue('phone', value);
        return;
      }
      default:
        formik.handleChange(e);
    }
  };
