import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReviewType } from '../../../../redux/api/types/reviews';

interface IProps {
  isShown: boolean;
  handleValueableClose: (empty: boolean) => () => void;
  handleValeableClick: (value: string) => () => void;
  mostValuable: string;
  rating: number;
  markItems: ReviewType[];
}

export const Drawer: FC<IProps> = ({
  isShown,
  mostValuable,
  handleValueableClose,
  handleValeableClick,
  rating,
  markItems,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`absolute bottom-0 left-0 w-full flex flex-col
        pt-[10.667vw] pb-[9.6vw] px-[4vw] bg-white gap-[8.533vw]
        border-t-[1px] border-midGray rounded-t-[3.467vw] transition-all duration-400
        ${isShown ? 'opacity-1 translate-y-0' : 'opacity-0 translate-y-full hidden'}
      `}
    >
      <div>
        <p className="mb-[5.333vw] text-[5.333vw] text-mainBlack leading-[8vw] text-center">
          {rating >= 3 ? t('pay.positive') : t('pay.negative')}
        </p>
        <div className="flex flex-wrap gap-y-[3.2vw] justify-between">
          {markItems.map(el => (
            <button
              onClick={handleValeableClick(el)}
              key={el}
              className={`px-[3.467vw] py-[2.133vw] w-[29.6vw] text-[3.2vw] leading-[4.8vw]
                  justify-center flex items-center flex-col rounded-[3.2vw] ${
                    el === mostValuable ? 'bg-mainYellow' : 'bg-white border-[1px] border-midGray'
                  }
                `}
            >
              <img
                src={`/images/icons/${el}.svg`}
                alt={`${el} icon`}
                className="w-[7.467vw] h-[7.467vw]"
              />
              {t(`pay.types.${el}`)}
            </button>
          ))}
        </div>
      </div>
      <button
        onClick={handleValueableClose(false)}
        className={`btn normal-case bg-phoneOrange hover:bg-phoneOrange
            w-full text-[5.333vw] font-bold text-mainBlack leading-[8vw] border-0
          `}
      >
        {t('pay.btn')}
      </button>
      <button onClick={handleValueableClose(true)} className="absolute top-[3.2vw] right-[3.2vw]">
        <img src="/images/icons/close_icon.svg" alt="close icon" className="w-[6.4vw] h-[6.4vw]" />
      </button>
    </div>
  );
};
