import TransactionsBody from '../../components/TransactionsBody';
import { DashboardTemplate } from '../../layouts/DashboradTemplate';

export const Transactions = () => {
  return (
    <DashboardTemplate>
      <TransactionsBody />
    </DashboardTemplate>
  );
};
