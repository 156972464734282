import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowDown } from '../../../static/arrow_down.svg';
import { Allergens } from '../../../types/base';
import ClickAwayListener from '../../../view/ClickAwayListener';
import { getDefaultAllergenTypes } from '../config';

import DropdownLabel from './DropdownLabel';

interface IProps {
  activeItems: {
    title: string;
    value: Allergens;
  }[];
  setActiveItems: Dispatch<
    SetStateAction<
      {
        title: string;
        value: Allergens;
      }[]
    >
  >;
  placeholder?: string;
}

const AllergensDropdown: FC<IProps> = ({ activeItems, setActiveItems, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const handleClick = () => {
    setIsOpen(prev => !prev);
  };
  const handleChange = (selected: { title: string; value: Allergens }) => () => {
    setActiveItems(prev => {
      const ind = prev.findIndex(e => e.value === selected.value);
      if (ind < 0) {
        return [...prev, selected];
      }
      const copy = [...prev];
      copy.splice(ind, 1);
      return copy;
    });
  };

  /* eslint-disable-next-line */
  const handleClose = (e: any) => {
    if (e.target.classList.contains('away')) return;
    setIsOpen(false);
  };

  const dropDownItems = useMemo(() => getDefaultAllergenTypes(), []);

  return (
    <div className="w-full">
      <div
        className={`w-full px-[1.042vw] py-[0.573vw] border-[1px] rounded-[0.417vw] relative
        md:px-[2.604vw] md:py-[1.432vw] md:rounded-[1.042vw] mb-[20px] overglow-y-scroll max-w-full
        xs:px-[5.333vw] xs:py-[2.933vw] xs:rounded-[2.133vw] border-inputBorder ${
          isOpen && 'border-mainOrange'
        }`}
      >
        <button
          className="flex items-center away w-full h-full justify-between"
          onClick={handleClick}
        >
          <div
            className={` text-mainBlack away flex gap-[8px] w-full max-w-full flex-wrap ${
              !activeItems.length ? 'text-textGray' : 'font-bold text-mainBlack'
            }`}
          >
            {!!activeItems.length ? (
              <>
                {activeItems.map(item => (
                  <DropdownLabel title={t(item.title)} key={item.title} />
                ))}
                <DropdownLabel className="ml-auto self-start" title={`${activeItems.length}`} />
              </>
            ) : (
              placeholder
            )}
          </div>
          <ArrowDown
            className={`w-[1.25vw] h-[1.25vw] transition-transform away ${
              isOpen ? 'rotate-[180deg]' : 'rotate-0'
            } md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]`}
            stroke="black"
            fill="black"
            style={{ '&>path': { fill: 'black' } }}
          />
        </button>
      </div>
      {isOpen && (
        <ClickAwayListener onClickAway={handleClose}>
          <div className="w-full bg-white relative">
            <ul
              className={`max-h-[25.229vw] md:max-h-[45.573vw] px-[0.833vw] py-[1vw] xs:max-h-[93.333vw] shadow-pass overflow-auto
              absolute w-full rounded-[0.417vw] z-10
              md:rounded-[1.042vw] xs:rounded-[2.133vw] bg-white left-0 -top-1`}
            >
              <li
                className={`cursor-pointer md:text-[2.083vw] text-mainBlack text-ellipsis overflow-hidden whitespace-nowrap
                  md:leading-[3.125vw] xs:text-[4.267vw] xs:leading-[6.4vw] py-[0.573vw] px-[0.5vw]
                  md:px-[2.604vw] md:py-[1.432vw] xs:px-[5.333vw] xs:py-[2.933vw] 
                  flex justify-between items-center sticky -top-5 bg-white
                `}
              >
                <div className="flex items-center gap-[0.417vw] md:gap-[1.042vw] xs:gap-[2.133vw] text-textGray">
                  {t('foodMenu.item.allergen')}
                </div>
                <button onClick={() => setActiveItems([])} className="text-textGray">
                  {t('foodMenu.item.clearAll')}
                </button>
              </li>
              {dropDownItems.map(item => (
                <li
                  key={item.title}
                  onClick={handleChange(item)}
                  className={`cursor-pointer md:text-[2.083vw] text-mainBlack text-ellipsis overflow-hidden whitespace-nowrap
                  md:leading-[3.125vw] xs:text-[4.267vw] xs:leading-[6.4vw] py-[0.573vw] px-[0.5vw]
                  md:px-[2.604vw] md:py-[1.432vw] xs:px-[5.333vw] xs:py-[2.933vw] 
                  hover:bg-yellowDropDownActiveHover flex justify-between items-center
                  ${activeItems.some(el => item.value === el.value) && 'bg-yellowDropDownActive'}
                `}
                >
                  <div
                    className={`flex items-center gap-[0.417vw] md:gap-[1.042vw] xs:gap-[2.133vw]
                    ${
                      activeItems.some(el => item.value === el.value)
                        ? 'text-mainBlack'
                        : 'text-textGray'
                    }`}
                  >
                    {t(item.title)}
                  </div>
                  <input
                    type="checkbox"
                    className="checkbox checkbox-warning checkbox-sm"
                    checked={activeItems.some(el => item.value === el.value)}
                  />
                </li>
              ))}
            </ul>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default AllergensDropdown;
