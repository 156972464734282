import { FC } from 'react';

import { ReactComponent as StarIcon } from '../../../../static/star_big.svg';

interface IProps {
  organization?: string;
  ratingAvg?: number;
  isRatingVisible?: boolean;
}

const LogoBlock: FC<IProps> = ({ organization, ratingAvg, isRatingVisible }) => {
  return (
    <div className="flex justify-between px-[4vw]">
      <p className="text-white font-bold text-[6.4vw] leading-[9.067vw] max-w-[62.4vw]">
        {organization}
      </p>
      {isRatingVisible && (
        <div className="flex items-center gap-[1.067vw] px-[2.133vw] py-[0.8vw] self-start bg-phoneOrange rounded-[5.333vw]">
          <StarIcon fill="white" className="w-[3.2vw] h-[3.2vw]" />
          <span className="text-white font-bold text-[4.267vw] leading-[6.4vw]">{ratingAvg}</span>
        </div>
      )}
    </div>
  );
};

export default LogoBlock;
