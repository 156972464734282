import { Link } from 'react-router-dom';

import { LANGUAGES } from '../../constants/base';
import { ROUTES } from '../../constants/routes';
import { LanguageSelect } from '../../view/LanguageSelect';

export const Header = () => {
  return (
    <header
      className={`w-full h-[4.167vw] py-[0.781vw] md:h-[10.417vw] bg-mainBlack
      flex px-[14.427vw] items-center justify-between md:px-[5.859vw]
      xs:px-[5.333vw] xs:h-[21.333vw] md:py-[1.953vw] xs:py-[4vw]`}
    >
      <Link to={ROUTES.LANDING}>
        <img
          src="/images/logo.png"
          alt="main logo"
          className="w-[5.104vw] h-[2.604vw] md:w-[12.76vw] md:h-[6.51vw] xs:w-[26.133vw] xs:h-[13.333vw]"
        />
      </Link>
      <LanguageSelect className="w-[4.948vw] h-[2.396vw] rounded-[5.208vw]" items={LANGUAGES} />
    </header>
  );
};
