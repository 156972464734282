import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { passwordSchema } from './base';

export const usePassValidationSchema = (isRestoring: boolean) => {
  const { t } = useTranslation();

  const msg = {
    space: t('errors.pass.space'),
    required: t('errors.pass.required'),
    chars: t('errors.pass.chars'),
    length: t('errors.pass.min'),
  };

  let schema = yup.object({
    newPassword: passwordSchema(msg),
    repeatPassword: passwordSchema(msg),
  });

  if (!isRestoring) {
    schema = schema.concat(yup.object({ currentPassword: passwordSchema(msg) }));
  }

  return schema;
};
