import { PAYMENT_CREATE } from '../../../constants/api';

const headers = {
  Accept: 'text/plain',
  'Accept-Encoding': 'gzip, deflate, br',
  'Content-type': 'application/json',
};

function validateMerchant(trId: string, preAuth: boolean) {
  return fetch('https://payze.io/v2/api/applepay/start-payment', {
    method: 'POST',
    body: JSON.stringify({
      transactionId: trId,
      preauthorize: preAuth,
    }),
    headers: headers,
  });
}

export const handler = (
  data: string,
  /* eslint-disable-next-line */
  callback: (status: any, event?: any, shouldRedirect?: boolean) => void
) => {
  const xhr = new XMLHttpRequest();
  /* eslint-disable-next-line */
  let payment: any = null;

  xhr.onload = () => {
    payment = JSON.parse(xhr.responseText);
  };
  xhr.open('POST', PAYMENT_CREATE, false);
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.send(data);

  console.log(payment);

  try {
    /* eslint-disable-next-line */
    if ((window as any).ApplePaySession) {
      const request = {
        countryCode: 'GE',
        currencyCode: 'GEL',
        supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
        merchantCapabilities: ['supports3DS'],
        total: { label: 'Payze', amount: payment.tips.total },
      };
      /* eslint-disable-next-line */
      const session = new (window as any).ApplePaySession(10, request);
      /* eslint-disable-next-line */
      let applePayToken: any = null;
      /* eslint-disable-next-line */
      session.onvalidatemerchant = async (event: any) => {
        const merchantSession = validateMerchant(payment.transactionId, false);
        merchantSession
          .then(response => {
            response
              .json()
              .then(respData => {
                applePayToken = respData.data.token;
                session.completeMerchantValidation(respData.data);
              })
              .catch(err => {
                console.log(err);
              });
          })
          .catch(err => {
            console.log(err);
          });
      };
      /* eslint-disable-next-line */
      session.onpaymentmethodselected = (event: any) => {
        const update = {
          newTotal: { label: 'Payze', amount: payment.tips.total },
        };

        session.completePaymentMethodSelection(update);
      };
      /* eslint-disable-next-line */
      session.onshippingmethodselected = (event: any) => {
        const update = {};

        session.completeShippingMethodSelection(update);
      };
      /* eslint-disable-next-line */
      session.onpaymentauthorized = (event: any) => {
        const token = event.payment.token;

        const acceptApplePay = fetch('https://payze.io/v2/api/applepay/accept', {
          method: 'POST',
          body: JSON.stringify({
            token: applePayToken,
            payzeTransactionId: payment.transactionId,
            acceptRequest: {
              version: token.paymentData.version,
              data: token.paymentData.data,
              signature: token.paymentData.signature,
              ephemeralPublicKey: token.paymentData.header.ephemeralPublicKey,
              publicKeyHash: token.paymentData.header.publicKeyHash,
              transactionId: token.paymentData.header.transactionId,
              displayName: token.paymentMethod.displayName,
              network: token.paymentMethod.network,
              type: token.paymentMethod.type,
              transactionIdentifier: token.transactionIdentifier,
            },
          }),
          headers: headers,
        });
        /* eslint-disable-next-line */
        let status = (window as any).ApplePaySession.STATUS_FAILURE;

        acceptApplePay.then(response => {
          response.json().then(payData => {
            if (payData.status.code === 'Success') {
              /* eslint-disable-next-line */
              status = (window as any).ApplePaySession.STATUS_SUCCESS;
            }

            const result = {
              status: status,
            };

            session.completePayment(result);

            if (callback) {
              callback(result, {}, true);
            }
          });
        });
      };
      /* eslint-disable-next-line */
      session.oncancel = (event: any) => {
        if (callback) {
          const result = {
            /* eslint-disable-next-line */
            status: (window as any).ApplePaySession.STATUS_FAILURE,
          };
          callback(result, event, false);
        }
      };

      session.begin();
    } else {
      console.log('no payment session');
    }
  } catch (e) {
    console.log('e: ', e);
  }
};
