export const Balls = () => {
  return (
    <>
      <div className="absolute top-[70%] -left-[10%] w-[18vw] h-[18vw] md:w-[39vw] md:h-[39vw] xs:w-[67vw] xs:h-[67vw] flex items-center justify-center md:top-[60%] md:-left-[20%] xs:top-[80%] xs:-left-[40%]">
        <div className=" w-[1px] h-[1px] rounded-full shadow-purpleLight"></div>
      </div>
      <div className="absolute -top-[20%] left-[82%] w-[23vw] h-[22vw] md:w-[52vw] md:h-[52vw] xs:w-[80vw] xs:h-[80vw] flex items-center justify-center md:-top-[30%] md:left-[60%] xs:top-[40%]">
        <div className="w-[1px] h-[1px] rounded-full shadow-yellowLight"></div>
      </div>
      <div className="absolute top-[70%] left-[80%] w-[17vw] h-[17vw] md:w-[36vw] md:h-[39vw] xs:w-[65vw] xs:h-[65vw] flex items-center justify-center md:top-[60%] md:left-[70%] xs:top-[40%]">
        <div className="w-[1px] h-[1px] rounded-full shadow-blueLight"></div>
      </div>
    </>
  );
};
