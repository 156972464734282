import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { numberSchema, stringSchema } from './base';

export const useAddCategoryValidationSchema = () => {
  const { t } = useTranslation();

  return yup.object({
    name: stringSchema(
      'name',
      4,
      {
        required: t('errors.menu.category.name.required'),
        length: t('errors.menu.category.name.length'),
      },
      true
    ),
  });
};

export const useAddMenuItemValidationSchema = () => {
  const { t } = useTranslation();

  return yup.object({
    name: stringSchema(
      'name',
      4,
      {
        length: t('errors.menu.item.name.length'),
        required: t('errors.menu.item.name.required'),
      },
      true
    ),
    compound: stringSchema(
      'compound',
      10,
      {
        length: t('errors.menu.item.compound.length'),
        required: t('errors.menu.item.compound.required'),
      },
      false
    ),
    price: numberSchema(1, {
      required: t('errors.menu.item.price.required'),
      min: t('errors.menu.item.price.min'),
    }),
  });
};
