import { FC, ReactNode, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { NotConfirmedUser } from '../../components/NotConfirmedUser';
import { MODAL_ANCHOR_ID } from '../../constants/base';
import { useConfirmed } from '../../hooks/useConfirmed';
import { useRoute } from '../../hooks/useRoute';
import useMediaQuery from '../../pages/Landing/components/share/useQuery';
import { useAppSelector } from '../../redux/hooks';

import { DashboardHeader } from './components/DashboardHeader';
import { DashboardSidebar } from './components/DashboardSidebar';
import { DashboardSidebarMobile } from './components/DashboardSidebarMobile';

interface IProps {
  children: ReactNode;
  headerChildren?: ReactNode;
}
export const DashboardTemplate: FC<IProps> = ({ children, headerChildren }) => {
  const ref = useRef<HTMLElement | null>(null);
  const { modal } = useAppSelector(state => state.user);
  const { t } = useTranslation();
  useRoute();
  const confirmed = useConfirmed();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isPortraitMobile = useMediaQuery('(max-width: 950px) and (min-aspect-ratio: 12/9)');

  useEffect(() => {
    if (modal) ref.current?.scrollTo(0, 0);
  }, [modal]);

  return (
    <>
      {isPortraitMobile ? (
        <div className="w-full h-full bg-mainBlack flex flex-col justify-center items-center">
          <img src="/images/icons/mobile.svg" className="w-[20vw] h-[20vw] animate-phoneRotate" />
          <p className="text-white">{t('turnPhone')}</p>
        </div>
      ) : (
        <div className="bg-white w-full min-h-screen h-min flex xs:flex-col md:min-h-screen md:relative xs:relative xs:h-auto">
          {isMobile ? <DashboardSidebarMobile /> : <DashboardSidebar />}
          <section
            ref={ref}
            id={MODAL_ANCHOR_ID}
            className={`w-[84.375vw] md:w-[89.583vw] h-min xs:w-full relative ${
              modal ? 'overflow-hidden' : ''
            }`}
          >
            {confirmed ? (
              <>
                <DashboardHeader>{headerChildren}</DashboardHeader>
                {children}
              </>
            ) : (
              <NotConfirmedUser />
            )}
          </section>
        </div>
      )}
    </>
  );
};
