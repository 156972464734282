import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import { TOAST_OPTIONS } from '../../../config';
import { useCreateCategoryMutation, useUpdateCategoryMutation } from '../../../redux/api/menu';
import { IMenuCategoryItem } from '../../../redux/api/types/menu';
import { useAppSelector } from '../../../redux/hooks';
import { creteInitialValues } from '../../../utils/formik';
import { useAddCategoryValidationSchema } from '../../../utils/validations/menu';
import { config } from '../config';

export const useLocalFormik = (
  onClose: () => void,
  refetch: () => void,
  file?: File,
  currentData?: IMenuCategoryItem,
  maxOrder?: number
) => {
  const { t } = useTranslation();

  const initialValues = creteInitialValues(config.inputs, currentData);
  const validationSchema = useAddCategoryValidationSchema();

  const [createCategory, { isSuccess: isSuccessCreate }] = useCreateCategoryMutation();
  const [updateCategory, { isSuccess }] = useUpdateCategoryMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('foodMenu.toast.categoryUpdated'), TOAST_OPTIONS);
    }
    if (isSuccessCreate) {
      toast.success(t('foodMenu.toast.categoryCreated'), TOAST_OPTIONS);
    }
  }, [isSuccess, isSuccessCreate]);

  const { menuId, menuLang } = useAppSelector(state => state.user);

  const handleSubmit = (values: typeof initialValues) => {
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value.toLocaleString());
      }
    });
    formData.append('menuId', `${menuId}`);
    formData.append('lang', menuLang);

    if (file) {
      formData.append('file', file as File);
    }

    if (currentData) {
      formData.append('id', `${currentData.id}`);
      updateCategory(formData).then(refetch).then(onClose);
    } else {
      formData.append('order', `${(maxOrder as number) + 1}`);
      createCategory(formData).then(refetch).then(onClose);
    }
  };

  return useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
  });
};
