import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { ROUTES } from '../../constants/routes';
import { AuthTemplate } from '../../layouts/AuthTemplate';
import { useConfirmRegistrationQuery } from '../../redux/api/auth';
import { useAppDispatch } from '../../redux/hooks';
import { loginUser } from '../../redux/slices/userSlice';
import { Button } from '../../view/Button';

export const ConfirmRegistration = () => {
  const { confirmId } = useParams();

  const { isError, isSuccess, data } = useConfirmRegistrationQuery(confirmId as string);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (isError) {
      navigate(ROUTES.LOGIN);
    }
    if (isSuccess) {
      localStorage.setItem('tipsToken', data.token);
      dispatch(loginUser(data));
    }
  }, [isError, isSuccess]);

  return (
    <AuthTemplate>
      <div
        className={`
          w-[30.104vw] border-authBorder shadow-auth border-[1px] flex flex-col relative p-[2.083vw] rounded-[0.625vw] gap-[1.563vw]
          md:w-[72.756vw] md:gap-[3.906vw]
          xs:w-[89.067vw] md:p-[10.667vw] md:rounded-[3.2vw] xs:gap-[8vw]
        `}
      >
        <p
          className={`
            font-bold text-mainBlack text-[1.24vw] leading-[1.771vw] text-center
            md:text-[3.125vw] md:leading-[4.427vw] xs:text-[6.4vw] xs:leading-[9.067vw]
          `}
        >
          {t('confirmation.emailConfirm')}
        </p>
        <Button onClick={() => {}}>
          <Link
            className={`w-full font-bold text-[1.042vw] leading-[1.563vw]
          md:text-[2.604vw] md:leading-[3.906vw] xs:text-[5.333vw] xs:leading-[8vw]`}
            to={ROUTES.HOME}
          >
            {t('confirmation.home')}
          </Link>
        </Button>
      </div>
    </AuthTemplate>
  );
};
