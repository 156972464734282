import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';

import { ROUTES } from '../../constants/routes';
import { useRedirect } from '../../hooks/useRedirect';
import { useSendRestorePassEmailMutation } from '../../redux/api/auth';
import { useResetValidationSchame } from '../../utils/validations/reset';
import { Button } from '../../view/Button';
import { FormTextInput } from '../../view/FormTextInput';

export const ForgotPasswordForm = () => {
  const [sendEmail, { isLoading, isSuccess }] = useSendRestorePassEmailMutation();
  useRedirect(false, isLoading, isSuccess, ROUTES.LOGIN);
  const { t } = useTranslation();

  const handleSubmit = (values: Record<string, string>) => {
    if (values.email) {
      sendEmail({ email: values.email });
    }
  };
  const validationSchema = useResetValidationSchame();

  const formik = useFormik({
    initialValues: { email: '' },
    onSubmit: handleSubmit,
    validationSchema,
  });

  return (
    <div
      className={`w-[31.094vw] p-[2.083vw] md:p-[5.208vw] xs:px-[5.333vw] xs:py-[10.667vw]
      border-[1px] rounded-[0.834vw] border-authBorder flex flex-col shadow-auth bg-white
      md:w-[87.76vw] xs:w-[89.333vw] md:rounded-[2.083vw] xs:rounded-[4.267vw]`}
    >
      <div
        className={`flex flex-col gap-[0.834vw] mb-[1.25vw]
        md:gap-[2.083vw] xs:gap-[4.267vw] md:mb-[3.125vw] xs:mb-[5.333vw]`}
      >
        <h2
          className={`leading-[1.771vw] text-[1.25vw] font-bold text-mainBlack
          md:leading-[9.067vw] md:text-[3.125vw] xs:leading-[6.4vw] xs:text-[4.8vw]`}
        >
          {t('auth.passReclaim')}
        </h2>
        <p
          className={`font-[0.834vw] leading-[1.25vw] font-normal text-textGray
          md:text-[2.083vw] md:leading-[3.125vw] xs:text-[3.733vw] xs:leading-[5.333vw]`}
        >
          {t('auth.passReclaimText')}
        </p>
      </div>
      <FormTextInput
        value={formik.values.email}
        onChange={formik.handleChange}
        placeholder={'Email'}
        required={true}
        name={'email'}
        id={'email'}
        error={formik.errors.email}
        type="text"
      />
      <Button onClick={formik.submitForm} className="mb-[1.25vw] md:mb-[3.125vw] xs:mb-[6.4vw]">
        {t('auth.passReclaimBtn')}
      </Button>
      <Button className="bg-white hover:bg-white" onClick={() => {}}>
        <Link
          className={`w-full font-bold text-[1.042vw] leading-[1.563vw] text-mainOrange
          md:text-[2.604vw] md:leading-[3.906vw] xs:text-[5.333vw] xs:leading-[8vw]`}
          to={ROUTES.LOGIN}
        >
          {t('backBtn')}
        </Link>
      </Button>
    </div>
  );
};
