import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../../redux/hooks';
import { pathnameSlicer } from '../../../utils/base';
import { config } from '../config';

interface IProps {
  children?: ReactNode;
}

export const DashboardHeader: FC<IProps> = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { extraPath } = useAppSelector(state => state.user);
  const title = config[pathnameSlicer(pathname) as keyof typeof config];
  const { t } = useTranslation();

  const clickHandler = () => navigate(-1);

  return (
    <div
      className={`w-full h-[7.083vw] border-b-[1px] flex items-center justify-between px-[2.604vw]
      md:h-[11.719vw] md:px-[3.255vw] xs:h-[16vw] xs:px-[5.6vw]`}
    >
      <button
        onClick={clickHandler}
        className={`flex items-center gap-[1.042vw] font-bold text-mainBlack text-[1.563vw] leading-[2.083vw]
        md:gap-[2.604vw] md:text-[3.125vw] md:leading-[4.427vw] xs:gap-[5.333vw] xs:text-[5.333vw] xs:leading-[8vw]`}
      >
        <img
          src="/images/icons/arrow_left_filled.svg"
          alt="arrow button"
          className="w-[1.458vw] h-[1.458vw] self-center md:w-[3.646vw] md:h-[3.646vw] xs:w-[7.467vw] xs:h-[7.467vw]"
        />
        {t(title)} {extraPath && `- ${extraPath}`}
      </button>
      {children}
    </div>
  );
};
