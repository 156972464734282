import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  card: {
    image: string;
    title: string;
    text: string;
  };
  index: number;
  arrow: boolean;
}

export const Card: FC<IProps> = ({ card, index, arrow }) => {
  const { image, title, text } = card;

  const {
    t,
    i18n: { language },
  } = useTranslation();
  const correctLang = language.split('-').length > 1 ? language.split('-')[0] : language;
  return (
    <div className="flex items-center h-max">
      <div
        className={`flex flex-col items-center text-white w-[19.896vw]
        md:w-[38.151vw] md:h-[85.417vw]
        xs:w-[78.133vw] xs:h-[170.4vw] xs:gap-0
        ${arrow && 'mr-[2.448vw] xs:mr-[3.733vw] md:mr-[1.823vw]'}
      `}
      >
        <div className="flex gap-[0.625vw] md:gap-[1.563vw]">
          <div
            className={`
            bg-white text-black font-bold items-center justify-center rounded-full flex w-[2.083vw] h-[2.083vw]
            md:w-[5.208vw] md:h-[5.208vw] text-[1.042vw] md:text-[2.604vw]
          `}
          >
            {index}
          </div>
          <h3 className="font-bold text-[1.563vw] mb-[1.042vw] leading-[2.083vw] md:text-[3.125vw] md:leading-[4.427vw] md:mb-[2.604vw] xs:mb-[5.333vw] xs:text-[6.4vw] xs:leading-[9.067vw]">
            {t(title)}
          </h3>
        </div>
        <img
          src={`/images/landing/${image}${image !== 'recipe' ? '_' + correctLang : ''}.png`}
          alt="recipe"
          className="w-[13.021vw] h-[22.396vw] mb-[1.042vw] md:w-[32.552vw] md:h-[55.99vw] md:mb-[2.604vw] xs:mb-[5.333vw] xs:w-[66.667vw] xs:h-[114.667vw] md:-translate-x-[0.912vw]"
        />
        <p className="text-[1.042vw] leading-[1.563vw] md:mb-auto text-center md:text-[2.083vw] md:leading-[3.125vw] xs:text-[4.267vw] xs:leading-[6.4vw]">
          {t(text)}
        </p>
      </div>
      {arrow && (
        <img
          src="/images/landing/arrow_right.svg"
          alt="right arrow"
          className={`w-[2.083vw] h-[2.083vw] md:w-[5.208vw] md:h-[5.208vw] -translate-y-full
          md:-translate-y-[250%] mr-[1.823vw] md:mr-[1.302vw] xs:w-[10.667vw] xs:h-[10.667vw]
          xs:-translate-y-[230%]`}
        />
      )}
    </div>
  );
};
