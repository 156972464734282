import { FormikConfig } from '../../types/base';

export const config: {
  inputs: FormikConfig;
} = {
  inputs: {
    name: {
      placeholder: 'foodMenu.category.name',
      required: true,
      type: 'text',
      initial: '',
    },
  },
};
