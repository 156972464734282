import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode';

import { ROUTES } from '../constants/routes';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { loginUser, updateMenuLang } from '../redux/slices/userSlice';
import { JWTUser } from '../types/base';

export const useRoute = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const { isLogged, menuLang } = useAppSelector(state => state.user);

  useEffect(() => {
    const token = localStorage.getItem('tipsToken');

    if (!token) {
      if (
        !pathname.includes(ROUTES.LOGIN) &&
        !pathname.includes(ROUTES.REGISTER) &&
        !pathname.includes(ROUTES.PASSWORD) &&
        !pathname.includes(ROUTES.USER_CONFIRM) &&
        !pathname.includes(ROUTES.REVIEW)
      ) {
        navigate(ROUTES.LOGIN);
      }
    } else {
      if (isLogged) {
        if (!menuLang) {
          dispatch(updateMenuLang(i18n.language));
        }
        return;
      }
      const decoded = jwt(token) satisfies JWTUser;

      if (Date.now() < new Date(decoded.exp * 1000).getTime()) {
        dispatch(
          loginUser({
            id: decoded.id,
            token,
            role: decoded.role,
            organization: decoded.organization,
            status: decoded.status,
            remember: true,
            lang: i18n.language,
          })
        );
      }
    }
  }, []);
};
