import { FormikConfig } from '../../types/base';

export const config: FormikConfig = {
  email: {
    type: 'email',
    placeholder: 'auth.email',
    required: true,
    initial: '',
  },
  password: {
    type: 'password',
    placeholder: 'auth.password',
    required: true,
    initial: '',
  },
};
