import { FC } from 'react';

import { ReactComponent as StarIcon } from '../../static/star_big.svg';

interface IProps {
  rating: number;
  className: string;
  size: string;
  full: boolean;
}

export const Star: FC<IProps> = ({ rating, className, size, full }) => {
  return (
    <div className={`flex items-center ${className}`}>
      {!full ? (
        <div className="w-full flex items-center gap-[0.208vw] md:gap-[1.172vw] xs:gap-[2.4vw] md:ml-[0.85vw]">
          <StarIcon className={size} fill={'#F1B615'} />
          <p>{rating}</p>
        </div>
      ) : (
        new Array(5)
          .fill(0)
          .map((_, i) => (
            <StarIcon className={size} key={i} fill={i <= rating - 1 ? '#F1B615' : '#DBDCDD'} />
          ))
      )}
    </div>
  );
};
