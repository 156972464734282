import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { dashboardLink } from '../../../../config';
import { hoverEffect } from '../../../share/classes';

import { Advantage } from './components/Advantage';
import { Balls } from './components/Balls';
import { BeeAnim } from './components/BeeAnim';

export const FirstSection = () => {
  const ref = useRef<HTMLImageElement | null>(null);
  const [anim, setAnim] = useState('animate-phone1 -translate-y-[1.198vw]');
  const {
    t,
    i18n: { language: lang },
  } = useTranslation();

  const correctLang = lang.split('-').length > 1 ? lang.split('-')[0] : lang;

  useEffect(() => {
    const animCb = (e: AnimationEvent) => {
      const type = e.animationName;
      const timer = setTimeout(() => {
        if (type === 'phone1') {
          setAnim('animate-phone2 -translate-y-[2.24vw]');
        } else {
          setAnim('animate-phone1 -translate-y-[1.198vw]');
        }
        clearTimeout(timer);
      }, 2339);
    };
    ref.current?.addEventListener('animationend', animCb);
    return () => {
      ref.current?.removeEventListener('animationend', animCb);
    };
  }, [anim]);

  return (
    <section className="w-full flex flex-col justify-between pb-[4.219vw] md:mb-[9.115vw] md:pb-0 mb-[3.646vw] relative xs:mb-[9.6vw] xs:pb-[21.867vw] mt-[6.094vw] md:mt-[11.719vw]">
      <Balls />
      <BeeAnim />
      <div className="flex items-start relative z-[2] md:flex-col md:mb-[9.896vw] xs:mb-[9.6vw]">
        <div className="pt-[6.615vw] md:pt-[0] md:order-1 md:flex md:flex-col md:items-center md:w-full md:relative md:-translate-y-[2.734vw]">
          <img
            src="/images/landing/bee.svg"
            alt="bee"
            className={`hidden md:block md:absolute left-[0vw] -top-[6vw] xs:w-[20vw] xs:h-[20vw]
            xs:left-[67.66vw] xs:-top-[10vw]
            md:rotate-[10deg] md:w-[14.453vw] md:h-[14.453vw] ${
              correctLang !== 'ru' && 'md:left-[9vw] xs:left-[67vw]'
            } ${correctLang === 'ge' && 'md:left-[13vw] xs:left-[81vw]'}`}
          />
          <h1
            className={`text-white font-bold text-[2.813vw] leading-[3.333vw] 
            mb-[2.396vw] md:mb-[2.604vw] md:text-[5.208vw]
            md:leading-[7.292vw] md:text-center ${
              correctLang !== 'ru'
                ? 'max-w-[43.302vw] md:max-w-[80.76vw]'
                : 'max-w-[46.302vw] md:max-w-[87.76vw] xs:max-w-[79.333vw]'
            }
            xs:text-[7.467vw] xs:leading-[9.067vw] xs:mb-[3.2vw]`}
          >
            {t('landing.first.title')}
          </h1>
          <h3
            className={`text-[#CFD5DF] font-normal text-[1.25vw] md:text-[2.604vw] leading-[1.771vw] md:leading-[3.906vw]
            max-w-[31.406vw]: md:max-w-[59.899vw] md:text-center mb-[6.094vw] md:mb-[7.813vw]
            xs:text-[4.267vw] xs:leading-[6.4vw] xs:mb-[4.8vw] xs:max-w-[89.333vw]`}
          >
            {t('landing.first.text')}
          </h3>
          <a
            href={dashboardLink}
            target="_blank"
            className={`px-[2.083vw] pt-[0.781vw] pb-[0.833vw] bg-phoneOrange text-center hover:px-[2.344vw] transition-all duration-300
            rounded-[5.208vw] font-bold text-[0.938vw] leading-[1.25vw] ${hoverEffect} text-mainBlack
            md:rounded-[13.021vw] md:text-[2.344vw] md:leading-[3.125vw] md:px-[5.208vw] md:pt-[1.953vw] md:pb-[2.083vw]
            xs:rounded-[26.667vw] xs:text-[4.8vw] xs:leading-[6.4vw] xs:px-[0] xs:pt-[0] xs:pb-[0] xs:w-[89.333vw] xs:h-[12.8vw] xs:flex xs:items-center xs:justify-center`}
            rel="noreferrer"
          >
            {t('landing.signIn')}
          </a>
        </div>
        <div
          className={`w-[22.917vw] h-[35.156vw] md:order-0 md:h-[73.828vw] xs:w-[68.8vw] xs:h-[105.6vw]
          md:flex xs:flex md:justify-center xs:w-full shrink-0
          xs:justify-center md:w-full md:-translate-y-[2.734vw] xs:translate-y-[2.2vw]`}
        >
          <img
            src={`/images/landing/main1_anim_${correctLang}.png`}
            alt="phone"
            className={`w-[22.917vw] h-[35.156vw] md:w-[48.047vw] md:h-[73.828vw] xs:w-[68.8vw] xs:h-[105.6vw] ${anim}`}
            ref={ref}
          />
        </div>
      </div>
      <Advantage />
    </section>
  );
};
