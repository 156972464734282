import { FC } from 'react';
import { NavLink } from 'react-router-dom';

import useMediaQuery from '../../../pages/Landing/components/share/useQuery';
import { useAppSelector } from '../../../redux/hooks';

interface IProps {
  to: string;
  text: string;
  icon: string;
  active?: boolean;
  badge?: string | number;
  expanded: boolean;
}

export const Link: FC<IProps> = ({ to, text, icon, active, expanded, badge }) => {
  const { status } = useAppSelector(state => state.user);
  const isMobile = useMediaQuery('(max-width: 1280px) and (max-aspect-ratio: 1024/695)');

  return (
    <NavLink to={to} className={`${status > 1 || active ? 'cursor-pointer' : 'cursor-default'}`}>
      {({ isActive }) => (
        <>
          <button
            className={`font-bold text-[1.042vw] h-[2.604vw] leading-[1.563vw] text-center text-white
            md:text-[2.604vw] md:leading-[3.906vw] md:h-[6.51vw]
            xs:h-[13.333vw] xs:text-[5.333vw] xs:leading-[8vw] xs:font-normal
            ${
              isActive
                ? status > 1 || active
                  ? 'border-l-4 border-mainOrange bg-tab'
                  : 'text-textGray'
                : status > 1
                ? 'pl-[0.208vw] md:pl-[0.521vw] xs:px-[1.067vw]'
                : 'text-textGray'
            } ${status > 1 || active ? 'cursor-pointer' : 'cursor-default'}
            ${isMobile && !expanded && 'w-full flex items-center justify-center'}`}
          >
            <p
              className={`flex items-center pl-[2.084vw] xs:pl-[5.333vw] h-full relative ${
                status > 1 || active ? 'text-white' : 'text-textGray'
              } ${isMobile && !expanded && 'md:pl-0'}`}
            >
              <img
                src={
                  active
                    ? `/images/icons/${icon}.svg`
                    : `/images/icons/${status > 1 ? icon : icon + '_gray'}.svg`
                }
                alt={`${icon} icon`}
                className={`mr-[0.417vw] ${
                  expanded && 'md:mr-[1.237vw]'
                } w-[1.25vw] h-[1.25vw] md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]`}
              />
              {(expanded || !isMobile) && text}{' '}
              {(!!badge && expanded) || (!!badge && !isMobile) ? `(${badge})` : ''}
              {!!badge && !expanded && isMobile && (
                <div
                  className={`absolute right-0 top-0 rounded-[50%] bg-mainRed w-[2.604vw] h-[2.604vw]
                  text-[1.563vw] leading-[2.344vw] flex items-center justify-center translate-x-[35%] translate-y-[15%]`}
                >
                  {+badge >= 10 ? '9+' : badge}
                </div>
              )}
            </p>
          </button>
        </>
      )}
    </NavLink>
  );
};
