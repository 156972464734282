import { createPortal } from 'react-dom';

const Overlay = () => {
  return createPortal(
    <div className="fixed top-0 left-0 w-full h-full z-[9] bg-burderBg backdrop-blur-[2px]"></div>,
    document.body
  );
};

export default Overlay;
