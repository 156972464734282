import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowIcon } from '../../../../static/arrow_down.svg';
import { validateCard } from '../../../../utils/validations/card';
import { Button } from '../../../../view/Button';
import { FormTextInput } from '../../../../view/FormTextInput';

interface IProps {
  handleBack: () => void;
  sendReview: () => void;
}

export const CardView: FC<IProps> = ({ handleBack, sendReview }) => {
  const [cardNumber, setCardNumber] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [cvv, setCvv] = useState('');

  const [cardError, setCardError] = useState('');
  const [dateError, setDateError] = useState('');
  const [cvvError, setCvvError] = useState('');

  const { t } = useTranslation();

  const validate = (card: string, monthC: string, yearC: string, cvvC: string) => {
    return validateCard({
      cardNumber: {
        value: card,
        setError: setCardError,
      },
      year: {
        value: yearC,
        setError: setDateError,
      },
      month: {
        value: monthC,
        setError: setDateError,
      },
      cvv: {
        value: cvvC,
        setError: setCvvError,
      },
    });
  };

  const handleCardNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    if ((e.nativeEvent as InputEvent).inputType === 'deleteContentBackward') {
      validate(cardNumber, month, year, cvv);
      setCardNumber(prev => prev.slice(0, -1));
    } else {
      if (e.target.value.length > 19) return;
      let value = e.target.value.replace(/\D/g, '').trim();
      if (value.length >= 4) {
        const splited = value.split('').filter(el => el);
        const step = 4;
        let nextValue = '';
        for (let i = 0; i < splited.length; i += step) {
          const part = splited.slice(i, i + step).join('');
          if (part.length === 4) {
            nextValue += part + ' ';
          } else {
            nextValue += part;
          }
        }
        value = nextValue;
      }
      validate(value, month, year, cvv);
      setCardNumber(value);
    }
  };

  const handleMonth = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    validate(cardNumber, value, year, cvv);
    if (value.length > 2) return;
    setMonth(value.replace(/\D/g, ''));
  };

  const handleYear = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    validate(cardNumber, month, value, cvv);
    if (value.length > 2) return;
    setYear(value.replace(/\D/g, ''));
  };

  const handleCvv = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    validate(cardNumber, month, year, value);
    if (value.length > 3) return;
    setCvv(value.replace(/\D/g, ''));
  };

  const handleSumbit = () => {
    const isValid = validate(cardNumber, month, year, cvv);
    if (!isValid) {
      sendReview();
    }
  };

  return (
    <div className="flex flex-col px-[4vw]">
      <button className="mb-[4.267vw]" onClick={handleBack}>
        <ArrowIcon fill="black" stroke="black" className="rotate-[90deg]" />
      </button>
      <div className="relative w-[92vw] h-[53.333vw] mb-[5.333vw]">
        <img
          src="/images/card.png"
          alt="card"
          className="w-[92vw] h-[53.333vw] absolute top-0 left-0 z-0"
        />
        <p className="absolute z-[1] text-white text-[4.267vw] leading-[6.4vw] left-[6.4vw] bottom-[8.267vw]">
          {cardNumber}
        </p>
      </div>
      <div className="mb-[8vw]">
        <div className={cardError ? 'mb-[2.133vw]' : 'mb-[4.267vw]'}>
          <p className="font-bold text-mainBlack text-[3.733vw] leading-[6.4vw] mb-[2.133vw]">
            {t('pay.card.card')}
          </p>
          <FormTextInput
            value={cardNumber}
            onChange={handleCardNumberChange}
            type="tel"
            placeholder={t('pay.card.cardPlaceholder') || ''}
            error={t(cardError) || ''}
            gap=" "
          />
        </div>
        <div className={cardError ? 'mb-[2.133vw]' : 'mb-[4.267vw]'}>
          <p className="font-bold text-mainBlack text-[3.733vw] leading-[6.4vw] mb-[2.133vw]">
            {t('pay.card.date')}
          </p>
          <div className="flex gap-[2.667vw]">
            <FormTextInput
              value={month}
              onChange={handleMonth}
              type="tel"
              inputStyle={`!w-[20.8vw] ${dateError && 'border-mainRed focus:outline-mainRed'}`}
              placeholder={t('pay.card.monthPlaceholder') || ''}
              gap=" "
            />
            <FormTextInput
              value={year}
              onChange={handleYear}
              type="tel"
              inputStyle={`!w-[20.8vw] ${dateError && 'border-mainRed focus:outline-mainRed'}`}
              placeholder={t('pay.card.yearPlaceholder') || ''}
              gap=" "
            />
          </div>
          {dateError && (
            <p
              className={`text-textGray flex items-center my-[2.133vw]
              text-[3.2vw] leading-[4.8vw] gap-[2.133vw]`}
            >
              <img
                src="/images/icons/error.svg"
                alt="error icon"
                className="xs:w-[4.267vw] xs:h-[4.267vw]"
              />
              {t(dateError)}
            </p>
          )}
        </div>
        <div>
          <p className="font-bold text-mainBlack text-[3.733vw] leading-[6.4vw] mb-[2.133vw]">
            CVV/CVC
          </p>
          <FormTextInput
            value={cvv}
            onChange={handleCvv}
            type="tel"
            inputStyle="!w-[20.8vw]"
            placeholder="CVV/CVC"
            error={t(cvvError) || ''}
            gap=" "
          />
        </div>
      </div>
      <Button className="!text-mainBlack" onClick={handleSumbit}>
        {t('pay.card.pay')}
      </Button>
    </div>
  );
};
