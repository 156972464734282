import { Dispatch, FC, memo, SetStateAction, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import { IMenuCategoryItem, IMenuItem } from '../../../../../redux/api/types/menu';
import MenuItemCard from '../Card';

export type ItemType = Omit<IMenuCategoryItem, 'image' | 'createdAt'>;
interface IProps {
  open: () => void;
  items: IMenuItem[];
  categories: { id: number }[];
  currentCategory: number;
  selfCategory: number;
  plateView: boolean;
  setCurrentDish: Dispatch<SetStateAction<IMenuItem | undefined>>;
  setCurrentCategory: Dispatch<SetStateAction<number>>;
  isDownScroll: boolean;
  afterClick: boolean;
  scrollBlock: HTMLDivElement | null;
}

const ItemsBlock: FC<IProps> = ({
  open,
  items,
  categories,
  currentCategory,
  selfCategory,
  plateView,
  setCurrentDish,
  setCurrentCategory,
  isDownScroll,
  afterClick,
  scrollBlock,
}) => {
  const blockRef = useRef<HTMLDivElement>(null);
  const handleItemClick = (dish: IMenuItem) => () => {
    setCurrentDish(dish);
    open();
  };

  const { ref: observervableRefUp } = useInView({
    onChange: inView => {
      if (afterClick) return;
      if (inView && isDownScroll) {
        setCurrentCategory(selfCategory);
      }
    },
    rootMargin: '50% 0% 0%  0%',
    threshold: 1,
  });

  const { ref: observervableRefDown } = useInView({
    onChange: inView => {
      if (afterClick) return;
      if (inView && !isDownScroll) {
        const last = categories.findIndex(el => el.id === selfCategory);
        if (last >= 0 && categories[last - 1]) {
          setCurrentCategory(selfCategory);
        }
      }
    },
    rootMargin: '0% 0% 50% 0%',
    threshold: 1,
  });

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (currentCategory === selfCategory && afterClick) {
      timer = setTimeout(() => {
        scrollBlock?.scrollTo({ top: blockRef.current!.offsetTop - 195, behavior: 'smooth' });
      }, 300);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [currentCategory, afterClick]);

  return (
    <div className="w-full" ref={blockRef}>
      <div className="flex gap-[10px] flex-wrap w-full justify-between">
        {!!items.length ? (
          items.map((el, i) => {
            if (i === 0) {
              return (
                <div
                  key={el.id}
                  ref={observervableRefUp}
                  className={`${!plateView ? 'w-full' : 'w-[44vw] flex flex-col'}`}
                >
                  <MenuItemCard
                    item={el}
                    onClick={handleItemClick(el)}
                    plateView={plateView}
                    className={`${plateView && 'h-full'}`}
                  />
                </div>
              );
            }
            if (i === items.length - 1) {
              return (
                <div
                  key={el.id}
                  ref={observervableRefDown}
                  className={`${!plateView ? 'w-full' : 'w-[44vw] flex flex-col'}`}
                >
                  <MenuItemCard
                    item={el}
                    onClick={handleItemClick(el)}
                    plateView={plateView}
                    className={`${plateView && 'h-full'}`}
                  />
                </div>
              );
            }
            return (
              <MenuItemCard
                item={el}
                key={el.id}
                onClick={handleItemClick(el)}
                plateView={plateView}
              />
            );
          })
        ) : (
          <p>No data</p>
        )}
      </div>
    </div>
  );
};

export default memo(ItemsBlock);
