import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Questions = () => {
  const [isHover, setIsHover] = useState(false);
  const handleHover = () => setIsHover(true);
  const handleLeave = () => setIsHover(false);
  const { t } = useTranslation();

  return (
    <section
      id="questions"
      className={`w-full py-[10.469vw] relative flex justify-between text-white md:py-0 md:mb-[10.417vw] md:pl-[6.12vw] md:flex-col md:gap-[6.51vw]
      xs:pl-0 xs:gap-[12vw] xs:mb-[21.333vw] pt-[3.208vw] mt-[-3.208vw] md:pt-[13vw] md:mt-[-13vw] xs:pt-[19vw] xs:mt-[-19vw]`}
    >
      <div className="flex flex-col gap-[2.083vw] md:gap-[1.563vw] xs:pl-[5.333vw]">
        <h2 className="font-bold text-[2.813vw] leading-[3.333vw] md:text-[5.208vw] md:leading-[7.292vw] xs:text-[8vw] xs:leading-[12.8vw] uppercase xs:normal-case">
          {t('landing.contacts.title')}
        </h2>
        <p className="text-[1.563vw] leading-[2.083vw] md:text-[3.125vw] md:leading-[4.427vw] xs:text-[5.333vw] xs:leading-[8vw]">
          {t('landing.contacts.text')}
        </p>
      </div>
      <div
        className={`flex flex-col items-center justify-center w-[35.052vw] h-[17.188vw] bg-[#151822] rounded-[1.042vw] relative overflow-hidden
        md:w-[57.772vw] md:h-[24.74vw] xs:w-full xs:h-[48vw] md:rounded-[2.604vw] xs:rounded-[5.333vw] border-[1px] border-cardBg3`}
      >
        <div className="flex flex-col gap-[1.042vw] xs:gap-[4.267vw]">
          <p
            className={`text-[1.563vw] leading-[2.083vw] relative z-[2] flex items-center gap-[0.833vw]
            md:gap-[2.083vw] md:text-[3.125vw] md:leading-[4.427vw]
            xs:gap-[4.267vw] xs:text-[5.333vw] xs:leading-[8vw]`}
          >
            <img
              src="/images/landing/mail.svg"
              alt="mail"
              className="w-[1.25vw] h-[1.25vw] md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]"
            />
            info@beezytips.ge
          </p>
          <p
            className={`text-[1.563vw] leading-[2.083vw] relative z-[2] flex items-center gap-[0.833vw]
            md:gap-[2.083vw] md:text-[3.125vw] md:leading-[4.427vw]
            xs:gap-[4.267vw] xs:text-[5.333vw] xs:leading-[8vw]`}
          >
            <img
              src="/images/landing/Telegram.svg"
              alt="Telegram"
              className="w-[1.25vw] h-[1.25vw] md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]"
            />
            @beezytipz
          </p>
          <p
            className={`text-[1.563vw] leading-[2.083vw] relative z-[2] flex items-center gap-[0.833vw]
            md:gap-[2.083vw] md:text-[3.125vw] md:leading-[4.427vw]
            xs:gap-[4.267vw] xs:text-[5.333vw] xs:leading-[8vw]`}
          >
            <img
              src="/images/landing/phone.svg"
              alt="Telegram"
              className="w-[1.25vw] h-[1.25vw] md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]"
            />
            +995 55 1100428
          </p>
        </div>
        <div
          className={`absolute top-[21.152%] left-[39.208%] w-[28.621vw] h-[27.289vw] bg-yellowContacts
            blur-[77.5px] rotate-[22.28deg] z-[1] rounded-[50%]  md:w-[71.553vw] md:h-[68.223vw]
            md:left-[49.713%] md:top-[30.214%] xs:w-[146.541vw] xs:h-[139.72vw] xs:left-[59%] xs:top-[32%]`}
        />
      </div>
      <div
        className={`absolute transition-transform ease-in-out duration-[1800ms] top-[10.469vw] left-[20.469vw] md:z-[2] ${
          isHover && 'translate-y-[10%] translate-x-[10%]'
        } md:left-[49.823vw] md:top-[8.646vw] xs:left-[52.533vw] xs:top-[25.518vw]`}
        onMouseEnter={handleHover}
        onMouseLeave={handleLeave}
      >
        <img
          src="/images/landing/questions.png"
          alt="questions"
          className={`origin-bottom-left ease-in-out transition-transform duration-[1800ms] w-[19.375vw] h-[19.375vw] relative z-[3] ${
            isHover ? 'scale-[1.19]' : 'scale-100'
          } md:w-[48.438vw] md:h-[48.438vw] xs:w-[42.133vw] xs:h-[42.133vw]`}
        />
        <div
          className={`w-[7.865vw] h-[7.552vw] ease-in-out absolute z-[2] transition-all duration-[1800ms] bg-[#FFD84B] ${
            isHover
              ? 'scale-[1.36] left-[38.55%] top-[21.889%]'
              : 'left-[16.129%] top-[34.409%] scale-100'
          } rounded-[50%] blur-[25px] opacity-50 md:w-[19.661vw] md:h-[18.88vw] xs:w-[17.194vw] xs:h-[16.512vw]`}
        />
      </div>
    </section>
  );
};
