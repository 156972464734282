export const cards = [
  {
    image: 'recipe',
    title: 'landing.how.first.title',
    text: 'landing.how.first.text',
  },
  {
    image: 'pay_phone',
    title: 'landing.how.second.title',
    text: 'landing.how.second.text',
  },
  {
    image: 'dashboard_phone',
    title: 'landing.how.third.title',
    text: 'landing.how.third.text',
  },
];
