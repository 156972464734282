import { FC, ReactNode } from 'react';

import { Tooltip } from './components/Tooltip';

interface IProps {
  className?: string;
  tooltipText?: string;
  title: string;
  children: ReactNode;
}

export const Card: FC<IProps> = ({ className, tooltipText, title, children }) => {
  return (
    <div
      className={`w-[19.01vw] h-[7.5vw] shadow-pass rounded-[0.625vw] p-[1.042vw] relative  md:rounded-[1.563vw] md:p-[2.083vw] md:w-[40.232vw] md:h-[14.974vw]
      xs:rounded-[3.2vw] xs:p-[4.267vw] xs:w-[89.333vw] xs:h-[30.667vw] ${className || ''}`}
    >
      <div className="flex items-center justify-between">
        <h3 className="font-bold text-[1.042vw] leading-[1.563vw] md:text-[2.344vw] md:leading-[3.906vw] xs:text-[4.8vw] xs:leading-[8vw]">
          {title}
        </h3>
        {tooltipText && <Tooltip text={tooltipText} />}
      </div>
      {children}
    </div>
  );
};
