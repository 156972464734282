import MenuBody from '../../components/MenuBody';
import { DashboardTemplate } from '../../layouts/DashboradTemplate';

export const Menu = () => {
  return (
    <DashboardTemplate>
      <MenuBody />
    </DashboardTemplate>
  );
};
