import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useLoader } from '../../hooks/useLoader';
import {
  useDeleteUserMutation,
  useGetAdminDataQuery,
  useUpdateUserDataMutation,
} from '../../redux/api/user';
import { Table } from '../AdminTable';
import { Loader } from '../Loader';

export const NewRequestBody = () => {
  const { data, refetch, isLoading } = useGetAdminDataQuery('not-confirmed', {
    refetchOnMountOrArgChange: true,
  });
  const [deleteUser, { isSuccess: isDeleteSuccess, isLoading: isDeleteLoading }] =
    useDeleteUserMutation();
  const [updateUser, { isLoading: isUpdateLoading, isSuccess }] = useUpdateUserDataMutation();
  const { t } = useTranslation();
  const isLoader = useLoader(isLoading);

  const deleteHandler = (id: number) => () => {
    deleteUser({ id });
    refetch();
  };

  const acceptHandler = (id: number) => () => {
    updateUser({ status: 2, userId: id });
    refetch();
  };

  useEffect(() => {
    if ((isSuccess && !isUpdateLoading) || (isDeleteSuccess && !isDeleteLoading)) {
      refetch();
    }
  }, [isSuccess, isUpdateLoading, isDeleteSuccess, isDeleteLoading]);

  return (
    <div className="bg-white p-[2.604vw] md:px-[3.255vw] md:py-[5.208vw] xs:px-0 xs:py-[8vw]">
      {isUpdateLoading && <Loader />}
      <div
        className={`p-[1.042vw] shadow-org rounded-[0.625vw] h-[44.479vw] flex flex-col gap-[1.042vw]
        md:p-[2.604vw] md:rounded-[1.563vw] md:h-[113.542vw] md:gap-[2.083vw]
        xs:p-[5.333vw] xs:rounded-[3.2vw] xs:h-[165.333vw] xs:gap-[3.2vw]`}
      >
        <p className="text-[1.25vw] leading-[1.771vw] text-mainBlack xs:text-[4.533vw] xs:leading-[6.4vw] md:text-[2.604vw] md:leading-[3.906vw]">
          {t('request.list')}
        </p>
        {!data || !data.length ? (
          <p
            className={`text-[1.25vw] leading-[1.771vw] text-lowGray mt-[2.083vw] text-center
            md:mt-[5.208vw] xs:mt-[10.667vw] md:text-[2.083vw] md:leading-[3.125vw] xs:text-[3.733vw] xs:leading-[5.333vw]`}
          >
            {t('request.noData')}
          </p>
        ) : (
          <Table
            isNew={true}
            data={data}
            deleteHandler={deleteHandler}
            acceptHandler={acceptHandler}
          />
        )}
      </div>
      {isLoader && <Loader />}
    </div>
  );
};
