import { createPortal } from 'react-dom';

import { MODAL_ANCHOR_ID } from '../../constants/base';

export const Loader = () => {
  return createPortal(
    <div className="absolute h-full w-full flex items-center justify-center top-0 left-0 bg-backdrop z-[100]">
      <div className="border-t-mainBlack border-solid animate-spin rounded-full border-mainOrange border-[1.563vw] h-[16.563vw] w-[16.563vw]"></div>
    </div>,
    document.getElementById(MODAL_ANCHOR_ID) ?? (document.body as HTMLElement)
  );
};
