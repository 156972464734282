import { useFormik } from 'formik';

import { useCreateMenuItemMutation, useUpdateMenuItemMutation } from '../../../redux/api/menu';
import { IMenuItem } from '../../../redux/api/types/menu';
import { useAppSelector } from '../../../redux/hooks';
import { Allergens } from '../../../types/base';
import { creteInitialValues } from '../../../utils/formik';
import { useAddMenuItemValidationSchema } from '../../../utils/validations/menu';
import { additionalFields, config } from '../config';

export const useLocalFormik = (
  onClose: () => void,
  refetch: () => void,
  file?: File,
  currentData?: IMenuItem,
  maxOrder?: number,
  allergens?: {
    title: string;
    value: Allergens;
  }[],
  categoryId?: string
) => {
  const initialValues = {
    ...creteInitialValues({ ...config, ...additionalFields }, { ...currentData, categoryId }),
  };
  const validationSchema = useAddMenuItemValidationSchema();
  const { menuLang } = useAppSelector(state => state.user);

  const [createItem] = useCreateMenuItemMutation();
  const [updateMenu] = useUpdateMenuItemMutation();

  const handleSubmit = (values: typeof initialValues) => {
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value.toString());
      }
    });
    formData.append('lang', menuLang);
    formData.append('menuCategoryId', values.categoryId);
    formData.append('allergens', JSON.stringify(allergens?.map(el => el.value)));

    if (file) {
      formData.append('file', file as File);
    }

    if (currentData) {
      formData.append('id', `${currentData.id}`);
      updateMenu(formData).then(refetch).then(onClose);
    } else {
      formData.append('order', `${(maxOrder as number) + 1}`);
      createItem(formData).then(refetch).then(onClose);
    }
  };

  return useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
  });
};
