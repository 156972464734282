import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IReviewItem } from '../../../redux/api/types/reviews';

import { RowItem } from './RowItem';

interface IProps {
  data: IReviewItem[];
  admin?: boolean;
}

export const Table: FC<IProps> = ({ data, admin }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-[0.521vw] overflow-auto max-h-full md:gap-[1.563vw] xs:gap-[3.2vw] bg-white">
      {!!data.filter(e => !!e.payment?.amount).length ? (
        <>
          <div className="flex gap-[2.604vw] md:gap-[1.204vw] xs:gap-[2.467vw]">
            <p
              className={`text-textGray text-[1.042vw] leading-[1.563vw] py-[0.521vw] w-[4.635vw]
              md:text-[2.083vw] md:leading-[3.125vw] md:py-[0.391vw] md:w-[9.115vw]
              xs:text-[3.733vw] xs:leading-[5.333vw] xs:py-0 xs:w-[18.667vw]`}
            >
              {t('analytic.date')}
            </p>
            <p
              className={`text-textGray text-[1.042vw] leading-[1.563vw] py-[0.521vw] w-[5.677vw]
              md:text-[2.083vw] md:leading-[3.125vw] md:py-[0.391vw] md:w-[11.719vw]
              xs:text-[3.733vw] xs:leading-[5.333vw] xs:py-0 xs:w-[24vw]`}
            >
              {t('analytic.time')}
            </p>
            <p
              className={`text-textGray text-[1.042vw] leading-[1.563vw] py-[0.521vw] w-[5.677vw]
              md:text-[2.083vw] md:leading-[3.125vw] md:py-[0.391vw] md:w-[11.849vw]
              xs:text-[3.733vw] xs:leading-[5.333vw] xs:py-0 xs:w-[24.267vw]`}
            >
              {t('analytic.tipsAmount')}
            </p>
            <p
              className={`text-textGray text-[1.042vw] leading-[1.563vw] py-[0.521vw] w-[16.875vw]
              md:text-[2.083vw] md:leading-[3.125vw] md:py-[0.391vw] md:w-[28.906vw] 
              xs:text-[3.733vw] xs:leading-[5.333vw] xs:py-0 xs:w-[59.2vw]`}
            >
              {t('analytic.receiver')}
            </p>
            <p
              className={`text-textGray text-[1.042vw] leading-[1.563vw] py-[0.521vw] w-[11.302vw]
              md:text-[2.083vw] md:leading-[3.125vw] md:py-[0.391vw] md:w-[9.115vw]
              xs:text-[3.733vw] xs:leading-[5.333vw] xs:py-0 xs:w-[18.667vw]`}
            >
              {t('analytic.rating')}
            </p>
            {admin && (
              <p
                className={`text-textGray text-[1.042vw] leading-[1.563vw] py-[0.521vw]
                w-[11.302vw]
              md:text-[2.083vw] md:leading-[3.125vw] md:py-[0.391vw] md:w-[9.115vw]
              xs:text-[3.733vw] xs:leading-[5.333vw] xs:py-0 xs:w-[18.667vw]`}
              >
                {t('analytic.status')}
              </p>
            )}
          </div>
          {data.map(el => (
            <RowItem
              key={el.id}
              receiver={
                admin
                  ? el.user.organization
                  : el.user.role !== 'manager'
                  ? `${el.user.lastName} ${el.user.firstName} ${el.user.thirdName || ''}`
                  : t('analytic.team')
              }
              date={new Date(el.reviewDate)}
              rating={el.rating}
              amount={el.payment?.amount ?? 0}
              type={el.status}
              admin={admin}
            />
          ))}
        </>
      ) : (
        <p className="text-textGray text-[1.563vw] leading-[2.083vw] w-full text-center md:text-[2.604vw] md:leading-[3.906vw] xs:text-[4.267vw] xs:leading-[6.4vw]">
          {t('analytic.noData')}
        </p>
      )}
    </div>
  );
};
