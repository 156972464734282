import { IReviewItem } from '../../redux/api/types/reviews';

export const getServiceTotalAmount = (data: IReviewItem[] | undefined, key: string) => {
  if (!data) return 0;
  let count = 0;
  data.forEach(el => {
    if (el.mostValuable === key && !!el.type) count++;
  });
  return count;
};
