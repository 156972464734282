import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '../../../constants/routes';
import useMediaQuery from '../../../pages/Landing/components/share/useQuery';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { logOut } from '../../../redux/slices/userSlice';

import { Link } from './Link';

interface IProps {
  expanded: boolean;
}

export const QuitBlock: FC<IProps> = ({ expanded }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 1280px) and (max-aspect-ratio: 1024/695)');
  const { id } = useAppSelector(state => state.user);

  const handleLogout = () => {
    dispatch(logOut());
    navigate(ROUTES.LOGIN);
  };

  return (
    <div className={`flex flex-col justify-self-end ${isMobile && 'md:gap-[1.302vw]'}`}>
      <Link
        to={`${ROUTES.DASHBOARD}/${id}${ROUTES.PROFILE}${ROUTES.PROFILE}`}
        text={t('menu.profile')}
        icon="settings"
        active={true}
        expanded={expanded}
      />
      <button
        onClick={handleLogout}
        className={`flex items-center font-bold text-[1.042vw] leading-[1.563vw] text-center pl-[0.208vw]
              md:text-[2.604vw] md:leading-[3.906vw] md:pl-[0.521vw]
              xs:text-white xs:text-[5.333vw] xs:leading-[8vw] xs:font-normal
              ${isMobile && !expanded && 'w-full justify-center'}`}
      >
        {isMobile && !expanded ? (
          <img
            src="/images/icons/exit.svg"
            alt="exit icon"
            className={`mr-[0.417vw] ${
              expanded && 'md:mr-[1.237vw]'
            } w-[1.25vw] h-[1.25vw] md:w-[3.125vw] md:h-[3.125vw]`}
          />
        ) : (
          <p className="flex items-center pl-[2.084vw] xs:pl-[6.4vw]">
            <img
              src="/images/icons/exit.svg"
              alt="exit icon"
              className={`mr-[0.417vw] ${
                expanded && 'md:mr-[1.237vw]'
              } w-[1.25vw] h-[1.25vw] md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]`}
            />
            {t('menu.quit')}
          </p>
        )}
      </button>
    </div>
  );
};
