import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '../../../pages/Landing/components/share/useQuery';
import { Star } from '../../../view/Stars';
import { getTime } from '../helpers';

interface IProps {
  date: Date;
  amount: number;
  receiver: string;
  rating: number;
  type?: string;
  admin?: boolean;
}

export const RowItem: FC<IProps> = ({ date, amount, receiver, rating, type, admin }) => {
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const { t } = useTranslation();

  return (
    <div className="w-full flex items-center gap-[2.604vw] bg-white">
      <p
        className={`text-mainBlack text-[0.938vw] leading-[1.458vw] py-[0.521vw] w-[4.635vw] md:text-[2.083vw] md:leading-[3.125vw] md:py-[0.391vw] md:w-[9.115vw]
        xs:text-[3.733vw] xs:leading-[5.333vw] xs:py-0 xs:w-[18.667vw]`}
      >
        {date.toLocaleDateString('ru-RU', {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
        })}
      </p>
      <p
        className={`text-mainBlack text-[1.042vw] leading-[1.563vw] py-[0.521vw] w-[5.677vw] md:text-[2.083vw] md:leading-[3.125vw] md:py-[0.391vw] md:w-[11.719vw]
        xs:text-[3.733vw] xs:leading-[5.333vw] xs:py-0 xs:w-[24vw]`}
      >
        {getTime(date)}
      </p>
      <p
        className={`font-bold text-mainBlack text-[1.042vw] leading-[1.563vw] py-[0.521vw] w-[5.677vw] md:text-[2.083vw] md:leading-[3.125vw] md:py-[0.391vw] md:w-[11.849vw]
        xs:text-[3.733vw] xs:leading-[5.333vw] xs:py-0 xs:w-[24.267vw]`}
      >
        {amount} ₾
      </p>
      <p
        className={`text-mainBlack text-[1.042vw] leading-[1.563vw] py-[0.521vw] w-[16.875vw] md:text-[2.083vw] md:leading-[3.125vw] md:py-[0.391vw] md:w-[28.906vw]
        xs:text-[3.733vw] xs:leading-[5.333vw] xs:py-0 xs:w-[59.2vw]`}
      >
        {receiver}
      </p>
      <div
        className={`text-mainBlack text-[1.042vw] leading-[1.563vw] py-[0.521vw] w-[11.302vw] md:text-[2.083vw] md:leading-[3.125vw] md:py-[0.391vw] md:w-[9.115vw]
        xs:text-[3.733vw] xs:leading-[5.333vw] xs:py-0 xs:w-[18.667vw]`}
      >
        <Star
          rating={rating}
          className="gap-[0.417vw]"
          size="w-[1.25vw] h-[1.25vw] md:w-[2.344vw] md:h-[2.344vw] xs:w-[4.8vw] xs:h-[4.8vw]"
          full={!isMobile}
        />
      </div>
      {type && admin && (
        <p
          className={`text-mainBlack text-[1.042vw] leading-[1.563vw] py-[0.521vw] w-[16.875vw] md:text-[2.083vw] md:leading-[3.125vw] md:py-[0.391vw] md:w-[28.906vw]
        xs:text-[3.733vw] xs:leading-[5.333vw] xs:py-0 xs:w-[18.667vw]`}
        >
          {+type !== 0 ? (
            <span className="text-green-500">{t('analytic.statusSuccess')}</span>
          ) : (
            <span className="text-red-500">{t('analytic.statusReject')}</span>
          )}
        </p>
      )}
    </div>
  );
};
