import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '../../../pages/Landing/components/share/useQuery';
import { IAdminDataResponse } from '../../../redux/api/types/user';

import { TableRow } from './TableRow';

interface IProps {
  data: IAdminDataResponse[];
  setDeleteId?: Dispatch<SetStateAction<number>>;
  isNew?: boolean;
  deleteHandler?: (id: number) => () => void;
  acceptHandler?: (id: number) => () => void;
}

export const Table: FC<IProps> = ({
  data,
  setDeleteId,
  acceptHandler,
  deleteHandler,
  isNew = false,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 1280px) and (max-aspect-ratio: 1024/695)');

  return (
    <div className="overflow-auto">
      <div
        className={`flex items-center justify-between text-[1.042vw]
          leading-[1.563vw] text-textGray bg-white
          border-b-[1px] border-midGray py-[0.521vw] md:py-[1.302vw] xs:pt-[2.667vw] xs:pb-[3.733vw]
          md:text-[2.083vw] md:leading-[3.125vw] xs:text-[3.733vw] xs:leading-[5.333vw]
        `}
      >
        <p className="w-[16.719vw] md:w-[31.901vw] xs:w-[42.133vw]">{t('request.name')}</p>
        {!isMobile && (
          <>
            <p className="w-[16.875vw]">IBAN</p>
            <p className="w-[15.417vw]">E-mail</p>
          </>
        )}
        {isNew ? (
          <p className="w-[12.031vw] text-right md:w-[20.313vw] xs:w-[18.4vw]">
            {t('organizations.status')}
          </p>
        ) : (
          <>
            <p className="w-[12.031vw] text-right md:w-[20.313vw] xs:w-[18.4vw]">
              {t('organizations.todayTransactions')}
            </p>
            <p className="w-[10.573vw] text-right md:w-[20.313vw] xs:w-[18.4vw]">
              {t('organizations.delete')}
            </p>
          </>
        )}
      </div>
      {data.map(el => (
        <TableRow
          item={el}
          key={el.id}
          setDeleteId={setDeleteId}
          isNew={isNew}
          acceptHandler={acceptHandler}
          deleteHandler={deleteHandler}
        />
      ))}
    </div>
  );
};
