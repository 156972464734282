import { FormikConfig } from '../../types/base';

export const config: FormikConfig = {
  firstName: {
    type: 'text',
    placeholder: 'auth.name',
    required: true,
    initial: '',
  },
  lastName: {
    type: 'text',
    placeholder: 'auth.surname',
    required: true,
    initial: '',
  },
  email: {
    type: 'email',
    placeholder: 'auth.email',
    required: true,
    initial: '',
  },
  organization: {
    type: 'text',
    placeholder: 'auth.organization',
    required: true,
    initial: '',
  },
  socialLink: {
    type: 'text',
    placeholder: 'auth.socialLink',
    required: true,
    initial: '',
  },
  phone: {
    type: 'text',
    placeholder: 'auth.phone',
    required: true,
    initial: '',
  },
  password: {
    type: 'password',
    placeholder: 'auth.password',
    required: true,
    initial: '',
  },
  confirmPassword: {
    type: 'password',
    placeholder: 'auth.repeatPassword',
    required: true,
    initial: '',
  },
};
