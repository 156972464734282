import { FC, useState } from 'react';

import './style.css';

interface IProps {
  text: string;
}

export const Tooltip: FC<IProps> = ({ text }) => {
  const [isShown, setIsShown] = useState(false);

  const handleShow = () => setIsShown(true);
  const handleHide = () => setIsShown(false);

  return (
    <>
      <img
        src="/images/icons/tooltip.svg"
        alt="tooltip icon"
        className="cursor-pointer w-[1.25vw] h-[1.25vw] md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]"
        onMouseEnter={handleShow}
        onMouseLeave={handleHide}
        onPointerEnter={handleShow}
        onPointerLeave={handleHide}
      />
      <div
        className={`
          absolute right-[0.521vw] top-[2.813vw] w-[16.875vw] rounded-[0.417vw]
          px-[0.833vw] py-[0.625vw] bg-white border-slate-300 text-[0.625vw]
          leading-[0.938vw] shadow-pass transition-opacity
          md:right-[1.042vw] md:top-[5.599vw] md:w-[34.635vw] md:rounded-[1.042vw] md:px-[2.083vw] md:py-[1.563vw] md:text-[1.563vw] md:leading-[2.344vw]
          xs:right-[2.133vw] xs:top-[12.267vw] xs:w-[70.933vw] xs:rounded-[2.133vw] xs:px-[4.267vw] xs:py-[3.2vw] xs:text-[3.2vw] xs:leading-[4.8vw]
          ${isShown ? 'opacity-1' : 'opacity-0'}
        `}
      >
        {/* <div className="tooltip-triangle right-[4%] top-0"></div> */}
        {text}
      </div>
    </>
  );
};
