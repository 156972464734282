import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as Tips } from '../../../../static/payment.svg';
import { ReactComponent as Menu } from '../../../../static/restaraunt.svg';

import './style.css';

interface IProps {
  tipsLink?: string;
  isOpen?: boolean;
}

const icons = {
  restaraunt: Menu,
  payment: Tips,
};

const Touchbar: FC<IProps> = ({ tipsLink, isOpen }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hash } = useParams();

  const handleClick = () => navigate(`/menu/${hash}/categories`);

  const Icon1 = icons.restaraunt;
  const Icon2 = icons.payment;

  return (
    <div className="fixed bottom-0 h-[19.2vw] rounded-t-[14px] bg-white w-full flex shadow-2xl shadow-backdrop overflow-hidden">
      {isOpen ? (
        <div className={`${isOpen && 'dark:bg-categoryCardBg bg-white'} w-full h-full`}></div>
      ) : (
        <>
          <a
            className={`text-white bg-menuBtnGradient rounded-tl-[14px] w-1/2 py-[5.333vw] px-[9.333vw] rounded-none
        flex items-center gap-[7px] cursor-pointer justify-center text-[24px] leading-[28px] font-bold whitespace-nowrap
        `}
            type="button"
            onClick={handleClick}
          >
            <Icon1
              className="[&>path]:dark:fill-white [&>path]:fill-white shrink-0"
              width={24}
              height={24}
            />
            {t('foodMenu.client.menuBtn')}
          </a>
          <a
            className={`dark:bg-bgNeutral60 bg-white text-black dark:text-white
        py-[5.333vw] px-[9.333vw] flex items-center gap-[7px] justify-center rounded-none
        text-[24px] leading-[28px] font-bold whitespace-nowrap w-1/2 capitalize touch-button`}
            type="button"
            href={tipsLink}
          >
            <Icon2
              className="[&>path]:dark:fill-white [&>path]:fill-black shrink-0"
              width={24}
              height={24}
            />
            {t('foodMenu.client.tipsBtn').split(' ')[1]}
          </a>
        </>
      )}
    </div>
  );
};

export default Touchbar;
