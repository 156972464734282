import { FC, MouseEvent } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { MODAL_ANCHOR_ID } from '../../constants/base';
import { ReactComponent as CloseIcon } from '../../static/close.svg';
import { ReactComponent as TrashIcon } from '../../static/trash.svg';
import { Button } from '../../view/Button';
import ClickAwayListener from '../../view/ClickAwayListener';

interface IProps {
  open: boolean;
  declineHandler: (e: MouseEvent<HTMLButtonElement>) => void;
  confirmHandler: () => void;
  title: string;
}

export const ConfirmationModal: FC<IProps> = ({ open, title, declineHandler, confirmHandler }) => {
  const { t } = useTranslation();

  // useEffect(() => {
  //   if (open) {
  //     document.getElementById(MODAL_ANCHOR_ID)?.scrollTo(0, 0);
  //   }
  // }, [open]);

  return (
    <>
      {open
        ? createPortal(
            <div className="absolute top-0 left-0 bottom-0 right-0 bg-backdrop z-20 flex items-center justify-center bg-white">
              <ClickAwayListener onClickAway={declineHandler}>
                <div
                  className={`p-[2.083vw] w-[30.104vw] bg-white relative shadow-pass rounded-[0.625vw] away
                  md:p-[5.208vw] md:w-[75.26vw] md:rounded-[1.563vw] xs:p-[10.667vw] xs:w-[89.6vw] xs:rounded-[3.2vw]`}
                >
                  <p
                    className={`mb-[1.563vw] font-bold text-[1.25vw] leading-[1.771vw] text-mainBlack text-center away
                    md:mb-[3.906vw] md:text-[3.125vw] md:leading-[4.427vw] xs:mb-[8vw] xs:text-[5.333vw] xs:leading-[8vw]`}
                  >
                    {title}
                  </p>
                  <div className="w-full flex gap-[1.042vw] xs:flex-col md:gap-[2.604vw] xs:gap-[5.333vw]">
                    <button
                      className={`btn w-[48%] xs:w-full bg-white hover:bg-white border-mainOrange
                        hover:border-mainOrange border-solid hover:border-solid md:h-[6.25vw] xs:h-[12.8vw]
                        text-mainBlack normal-case font-bold text-[1.042vw] leading-[1.563vw]
                        md:text-[2.604vw] md:leading-[3.906vw] xs:text-[5.333vw] xs:leading-[8vw]
                      `}
                      style={{ borderWidth: '1px' }}
                      onClick={declineHandler}
                    >
                      {t('cancelBtn')}
                    </button>
                    <Button
                      className="w-[48%] xs:w-full flex items-center gap-[0.521vw] md:gap-[1.302vw] xs:gap-[2.667vw] away"
                      onClick={confirmHandler}
                    >
                      {t('deleteBtn')}
                      <TrashIcon className="w-[1.25vw] h-[1.25vw] md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]" />
                    </Button>
                  </div>
                  <button
                    onClick={declineHandler}
                    className="absolute right-[1.042vw] top-[1.042vw] md:right-[2.604vw] md:top-[2.604vw] xs:right-[4.267vw] xs:top-[4.267vw]"
                  >
                    <CloseIcon
                      className="w-[1.25vw] h-[1.25vw] md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]"
                      stroke="black"
                    />
                  </button>
                </div>
              </ClickAwayListener>
            </div>,
            document.getElementById(MODAL_ANCHOR_ID) as HTMLElement
          )
        : null}
    </>
  );
};
