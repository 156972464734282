import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { API_URL } from '../../../../../constants/api';
import { IMenuItem } from '../../../../../redux/api/types/menu';
import { ReactComponent as Arrow } from '../../../../../static/arrow_down.svg';
import { ReactComponent as CaloriesIcon } from '../../../../../static/calories.svg';
import { ReactComponent as CarboIcon } from '../../../../../static/carbohydrates.svg';
import { ReactComponent as FatIcon } from '../../../../../static/fat.svg';
import { ReactComponent as InfoIcon } from '../../../../../static/info.svg';
import { ReactComponent as ProteinIcon } from '../../../../../static/protein.svg';
import { ReactComponent as WatchIcon } from '../../../../../static/time.svg';
import { convertTime } from '../../../../../utils/strings';
import { config } from '../config';

import '../Swiper/styles.css';
import styles from './styles.module.scss';

interface IProps {
  isOpen: boolean;
  item: IMenuItem;
  close: () => void;
}

const CALORIES_MAP = [
  {
    icon: <CaloriesIcon />,
    title: 'foodMenu.item.calories',
  },
  {
    icon: <ProteinIcon />,
    title: 'foodMenu.item.protein',
  },
  {
    icon: <FatIcon />,
    title: 'foodMenu.item.fat',
  },
  {
    icon: <CarboIcon />,
    title: 'foodMenu.item.carbs',
  },
];

export const Modal: FC<IProps> = ({ isOpen, item, close }) => {
  const { t } = useTranslation();
  const Icon = config[item?.type as keyof typeof config];

  return (
    <div
      className={`w-full fixed top-0 h-screen z-20 dark:bg-categoryCardBg bg-white ${
        isOpen ? 'left-0' : '-left-[160%]'
      } transition-all duration-300`}
    >
      <div className="h-[60%] w-full absolute top-0 left-0 flex items-center justify-center dark:bg-categoryCardBg bg-white pointer-events-none">
        <img
          src={
            item && item.image
              ? `${API_URL}/image/${item.image}`
              : '/images/menu/dish-placeholder.png'
          }
          className={`${item?.image && 'w-full h-full object-cover'}`}
        />
      </div>
      <div className="w-screen h-full min-h-screen absolute top-0 overflow-auto">
        {isOpen && (
          <button
            onClick={() => {
              if (isOpen) close?.();
            }}
            className="w-[40px] h-[40px] dark:bg-none dark:bg-darkBg bg-white rounded-full fixed top-[4vw] left-[4vw]"
          >
            <Arrow className="rotate-90 [&>path]:dark:fill-white [&>path]:fill-black w-[40px] h-[40px]" />
          </button>
        )}
        <div className="h-[50%] pointer-events-none" />
        <div className="w-full px-[15px] mb-[130px] min-h-[50%] scrollbar-hide py-[30px] dark:bg-categoryCardBg bg-white rounded-t-[10.667vw] border-0 outline-0 flex flex-col">
          <p className="text-[18px] leading-[24px] dark:text-[#808080] text-black mb-[4px]">
            {item?.categoryName}
          </p>
          <h2 className="dark:text-white text-black font-bold text-[40px] leading-[46px] mb-[8px]">
            {item?.name}
          </h2>
          <div className="flex flex-wrap gap-[8px] mb-[16px]">
            {item?.time && (
              <p
                className={`px-[10px] py-[6px] rounded-[10.667vw] dark:bg-languageBg bg-[#F7F7F7] font-medium
            max-w-min flex items-center gap-[10px] whitespace-nowrap dark:text-white text-[16px] leading-[24px] text-black`}
              >
                <WatchIcon className="[&>path]:fill-[#FFB800]" />
                {convertTime(item.time, t)}
              </p>
            )}
            {item?.type && (
              <p
                className={`px-[10px] py-[6px] rounded-[10.667vw] dark:bg-languageBg bg-[#F7F7F7] font-medium
            max-w-min flex items-center gap-[10px] whitespace-nowrap dark:text-white text-black text-[16px] leading-[24px]`}
              >
                <Icon.icon width={20} height={20} className={styles[item.type]} />
                {t(Icon.title)}
              </p>
            )}
          </div>
          {item.compound && (
            <div className="mt-[24px] mb-[14px]">
              <p className="dark:text-[#CCCACA] text-black text-[18px] leading-[24px] font-semibold mb-[6px]">
                {t('foodMenu.item.compound')}
              </p>
              <p className="dark:text-[#CCCACA] text-black text-[18px] leading-[24px]">
                {item?.compound}
              </p>
            </div>
          )}
          {item.allergens && !!item.allergens.length && (
            <div className="mb-[14px]">
              <p className="dark:text-[#CCCACA] text-black text-[18px] leading-[24px] font-semibold mb-[6px]">
                {t('foodMenu.item.allergenSingle')}
              </p>
              <div className="flex gap-[8px] flex-wrap">
                {item.allergens.map(el => {
                  const splited = el.split('-');
                  return (
                    <p
                      key={el}
                      className="dark:text-white text-black px-[14px] py-[6px] dark:bg-languageBg bg-none bg-[#F7F7F7] text-[16px] leading-[24px] rounded-[22px]"
                    >
                      {t(
                        `foodMenu.item.allergens.${
                          splited.length <= 1
                            ? el
                            : splited[0] +
                              splited[1].slice(0, 1).toUpperCase() +
                              splited[1].slice(1)
                        }`
                      )}
                    </p>
                  );
                })}
              </div>
            </div>
          )}
          {(item.calories || item.carbohydrate || item.protein || item.fat) && (
            <div className="mb-[14px]">
              <p className="dark:text-[#CCCACA] text-black text-[18px] leading-[24px] font-semibold mb-[6px]">
                {t('foodMenu.item.KBJUAmount')}
              </p>
              <div className="dark:bg-languageBg bg-white rounded-[12px] py-[6px] flex w-full">
                {[item.calories, item.protein, item.fat, item.carbohydrate].map((el, i) => (
                  <div className="p-[8px] flex flex-col gap-[10px] w-1/4" key={i}>
                    <div className="flex px-[8px] py-[3px] rounded-[12px] bg-menuBtnGradient items-center justify-center gap-[2px]">
                      {CALORIES_MAP[i].icon}
                      <p className="text-white text-[16px] leading-[24px]">{el}</p>
                    </div>
                    <p className="dark:text-[#E6E6E6] text-[12px] leading-[12px] text-center capitalize text-black">
                      {t(CALORIES_MAP[i].title)}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="flex items-start gap-[4px] mb-[24px]">
            <div className="w-[24px] h-[24px]">
              <InfoIcon />
            </div>
            <p className="dark:text-[#CCCACA] text-black text-[14px] leading-[20px] pb-[30px]">
              {t('foodMenu.item.allergensInfo')}
            </p>
          </div>
        </div>
        {isOpen && (
          <button className="w-[92vw] rounded-[12px] py-[4.267vw] px-[4.8vw] fixed left-[4vw] bottom-[4vw] flex items-center gap-[7px] justify-between whitespace-nowrap text-white bg-menuBtnGradient mt-auto">
            <p className="text-[18px] leading-[20px] font-semibold">{t('foodMenu.client.price')}</p>
            <p className="text-[24px] leading-[30px] font-semibold">{item?.price}₾</p>
          </button>
        )}
      </div>
    </div>
  );
};
