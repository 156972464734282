import { FC, MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query';
import { QueryActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';

import { TOAST_OPTIONS } from '../../../config';
import { API_URL } from '../../../constants/api';
import { IEmploeyyCardItem } from '../../../redux/api/types/user';
import { useDeleteUserMutation } from '../../../redux/api/user';
import { useAppDispatch } from '../../../redux/hooks';
import { closeModal } from '../../../redux/slices/userSlice';
import { getShortName, trim } from '../../../utils/base';
import { Star } from '../../../view/Stars';
import { ConfirmationModal } from '../../ConfirmationModal';

import { EmployeePortal } from './EmployeePortal';

interface IProps {
  data: IEmploeyyCardItem;
  refetch: () => QueryActionCreatorResult<
    QueryDefinition<
      number,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>,
      never,
      IEmploeyyCardItem[],
      'api'
    >
  >;
}

export const EmployeeCard: FC<IProps> = ({ data, refetch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { firstName, lastName, totalRating, reviewsAmount, image } = data;
  const [coords, setCoords] = useState({ top: 0, left: 0 });
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [deleteUser, { isSuccess, isError, isLoading }] = useDeleteUserMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      dispatch(closeModal());
      toast.success(t('back.user.delete'), TOAST_OPTIONS);
      refetch();
    }
    if (isError) {
      dispatch(closeModal());
      toast.success(t('back.user.error'), TOAST_OPTIONS);
    }
  }, [isSuccess, isError, isLoading]);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    setCoords({ left: e.pageX, top: e.pageY + 10 });
    setIsOpen(true);
  };

  const handleClose = () => setIsOpen(false);

  const confirmHandler = () => {
    deleteUser({ id: data.id });
    setIsModalOpen(false);
  };
  const declineHandler = (e: MouseEvent<HTMLElement>) => {
    if ((e.target as HTMLElement).classList.contains('away')) return;
    handleClose();
    dispatch(closeModal());
    setIsModalOpen(false);
  };

  return (
    <div
      className={`w-[7.813vw] rounded-[0.625vw] shadow-employee py-[0.833vw]
        px-[0.625vw] flex flex-col gap-[0.729vw] border-[1px]
        border-midGray relative
        md:w-[19.531vw] md:rounded-[1.563vw] md:py-[2.083vw] md:px-[1.563vw] md:gap-[1.823vw]
        xs:w-[40vw] xs:rounded-[3.2vw] xs:py-[4.267vw] xs:px-[3.2vw] xs:gap-[3.733vw]
        ${data.status === 0 && 'bg-sidebar grayscale'}`}
    >
      <div className="flex flex-col items-center">
        {image ? (
          <img
            src={`${API_URL}/image/${image}`}
            alt="employee image"
            className="w-[3.646vw] h-[3.646vw] md:w-[9.115vw] md:h-[9.115vw] xs:w-[18.667vw] xs:h-[18.667vw] rounded-full mb-[0.313vw] md:mb-[0.781vw] xs:mb-[1.6vw]"
          />
        ) : (
          <div
            className={`w-[3.646vw] h-[3.646vw] bg-indigo-600 rounded-[50%] mb-[0.313vw] text-white flex items-center justify-center
          md:w-[7.813vw] md:h-[7.813vw] md:mb-[2.083vw] xs:w-[16vw] xs:h-[16vw] xs:mb-[4.267vw]`}
          >
            {getShortName(`${lastName} ${firstName}`)}
          </div>
        )}
        <span
          className={`mb-[0.104vw] leading-[1.25vw] text-[0.729vw] font-bold md:mb-[0.26vw] xs:mb-[0.533vw]
          md:text-[1.823vw] md:leading-[3.125vw] xs:text-[3.733vw] xs:leading-[6.4vw]`}
        >
          {trim(`${lastName} ${firstName}`, 9)}
        </span>
        <div className="flex items-center gap-[0.521vw] md:gap-[1.302vw] xs:ga-[2.667vw] h-[1.042vw] md:h-[2.604vw] xs:h-[5.333vw]">
          <Star
            rating={totalRating}
            className="gap-[0.208vw]"
            size="w-[0.625vw] h-[0.625vw] md:w-[1.563vw] md:h-[1.563vw] xs:w-[3.2vw] xs:h-[3.2vw]"
            full={true}
          />
          <p
            className={`text-[0.729vw] leading-[1.042vw]
            md:text-[1.823vw] md:leading-[2.604vw] xs:text-[3.733vw] xs:leading-[5.333vw]`}
          >
            {totalRating?.toFixed(1)}
          </p>
        </div>
      </div>
      <div className="flex items-center justify-between text-[0.729vw] leading-[1.042vw] md:text-[1.823vw] md:leading-[2.604vw] xs:text-[3.733vw] xs:leading-[5.333vw]">
        <span>{t('rating.employeeFeedback')}</span>
        <span>{reviewsAmount}</span>
      </div>
      <button onClick={handleClick} className="cursor-pointer absolute right-[5%]">
        <img
          src="/images/icons/employe_menu.svg"
          alt="menu icon"
          className="w-[1.042vw] h-[1.042vw] md:w-[2.604vw] md:h-[2.604vw] xs:w-[5.333vw] xs:h-[5.333vw]"
        />
      </button>
      {isOpen && (
        <EmployeePortal
          handleClose={handleClose}
          coords={coords}
          employee={data}
          onDelete={() => setIsModalOpen(true)}
        />
      )}
      <ConfirmationModal
        open={isModalOpen}
        declineHandler={declineHandler}
        confirmHandler={confirmHandler}
        title={t('rating.deleteEmployeeTitle') || ''}
      />
    </div>
  );
};
