import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';

interface IProps {
  mr: string;
  className: string;
  handler?: () => void;
}

export const Navbar: FC<IProps> = ({ mr, className, handler }) => {
  const { t } = useTranslation();

  return (
    <nav className={`${mr} ${className}`}>
      <ul
        className={`flex items-center md:flex-col gap-[2.083vw] text-[0.938vw]
        leading-[1.458vw] text-white whitespace-nowrap
        md:text-[2.344vw] md:leading-[3.906vw] md:gap-[3.906vw]
        xs:text-[4.533vw] xs:leading-[6.4vw] xs:gap-[8vw]
        `}
      >
        <li>
          <HashLink
            smooth={true}
            className="w-full block hover:text-phoneOrange transition-all duration-300"
            to="#second"
            onClick={handler}
          >
            {t('landing.nav.advantage')}
          </HashLink>
        </li>
        <li>
          <HashLink
            smooth={true}
            className="w-full block hover:text-phoneOrange transition-all duration-300"
            to="#third"
            onClick={handler}
          >
            {t('landing.nav.for')}
          </HashLink>
        </li>
        <li>
          <HashLink
            smooth={true}
            className="w-full block hover:text-phoneOrange transition-all duration-300"
            to="#howworks"
            onClick={handler}
          >
            {t('landing.nav.how')}
          </HashLink>
        </li>
        <li>
          <HashLink
            smooth={true}
            className="w-full block hover:text-phoneOrange transition-all duration-300"
            to="#about"
            onClick={handler}
          >
            {t('landing.nav.about')}
          </HashLink>
        </li>
        <li>
          <HashLink
            smooth={true}
            className="w-full block hover:text-phoneOrange transition-all duration-300"
            to="#questions"
            onClick={handler}
          >
            {t('landing.nav.contacts')}
          </HashLink>
        </li>
      </ul>
    </nav>
  );
};
