import { useState } from 'react';

import useMediaQuery from '../../pages/Landing/components/share/useQuery';

import PromoBlock from './components/Promo';
import QRBlock from './components/QRBlock';
import Tabs from './components/Tabs';

export const QRBody = () => {
  const isTablet = useMediaQuery('(max-width: 1200px)');
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <div className="bg-white md:h-min px-[9.271vw] md:px-[3.255vw]">
      {isTablet ? (
        <>
          <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
          <div className="">{currentTab ? <QRBlock /> : <PromoBlock />}</div>
        </>
      ) : (
        <div className="flex justify-between py-[4.92vw]">
          <PromoBlock />
          <QRBlock />
        </div>
      )}
    </div>
  );
};
