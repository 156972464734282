import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { ibanSchema, stringSchema } from './base';

export const useAddEmployeeValidationSchema = () => {
  const { t } = useTranslation();
  return yup.object({
    name: stringSchema(
      'Name',
      3,
      {
        length: t('errors.name.length'),
        required: t('errors.name.required'),
      },
      true
    ),
    surname: stringSchema(
      'Surname',
      3,
      {
        length: t('errors.surname.length'),
        required: t('errors.surname.required'),
      },
      false
    ),
    thirdname: stringSchema(
      'Thirname',
      3,
      {
        length: t('errors.thirdname.length'),
      },
      false
    ),
    proffession: stringSchema(
      'Proffesseion',
      4,
      {
        length: t('errors.proffession.length'),
      },
      false
    ),
    organization: stringSchema(
      'Organization',
      3,
      {
        length: t('errors.organization.length'),
        required: t('errors.organization.required'),
      },
      true
    ),
    // email: emailSchema(
    //   {
    //     length: t('errors.email.length'),
    //     invalid: t('errors.email.invalid'),
    //   },
    //   false
    // ),
    iban: ibanSchema({
      required: t('errors.iban.required'),
      chars: t('errors.iban.chars'),
      length: t('errors.iban.length'),
    }),
  });
};
