import { Dispatch, FC, PropsWithChildren, SetStateAction, useEffect, useRef } from 'react';
import { HashLink } from 'react-router-hash-link';

import { ItemType } from '../ItemsBlock';

import './styles.css';

interface IProps {
  items: ItemType[];
  activeIndex: number;
  setAfterClick: Dispatch<SetStateAction<boolean>>;
}

const Paragraph: FC<
  PropsWithChildren<{ isActive: boolean; scrollElem: HTMLDivElement | null }>
> = ({ children, isActive, scrollElem }) => {
  const ref = useRef<HTMLParagraphElement>(null);

  const clickHandler = () => {
    scrollElem?.scrollTo({ left: ref.current!.offsetLeft - 20, behavior: 'smooth' });
  };

  useEffect(() => {
    if (isActive) {
      clickHandler();
    }
  }, [isActive]);

  return (
    <p
      className={`whitespace-nowrap h-[28px] ${
        isActive
          ? 'text-[#FFB800] dark:text-[#FFB800] underline underline-offset-4'
          : 'dark:text-[#808080] text-blackBg'
      } font-[600] leading-[23px] text-[20px]`}
      ref={ref}
      onClick={clickHandler}
    >
      {children}
    </p>
  );
};

const SwiperBlock: FC<IProps> = ({ items, activeIndex, setAfterClick }) => {
  const activeRef = useRef<HTMLDivElement>(null);

  const clickHandler = () => {
    setAfterClick(true);
  };

  return (
    <div className="flex overflow-auto gap-[6.4vw] scrollbar-hide w-full" ref={activeRef}>
      {items.map(el => (
        <Paragraph key={el.id} isActive={activeIndex === el.id} scrollElem={activeRef.current}>
          <HashLink smooth={true} to={`#${el.id}`} onClick={clickHandler}>
            {el.name}
          </HashLink>
        </Paragraph>
      ))}
    </div>
  );
};

export default SwiperBlock;
