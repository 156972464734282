import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { ROUTES } from '../../../constants/routes';
import { useAppSelector } from '../../../redux/hooks';

const Navbar = () => {
  const { id } = useAppSelector(state => state.user);
  const { t } = useTranslation();

  return (
    <div className="flex items-center gap-[2.083vw] md:gap-[5.208vw] xs:gap-[10.667vw]">
      <NavLink
        to={`${ROUTES.DASHBOARD}/${id}${ROUTES.PROFILE}${ROUTES.PROFILE}`}
        className={({ isActive }) =>
          `flex items-center gap-[0.417vw] px-[0.208vw] pt-[0.677vw] leading-[1.25vw] font-bold text-[0.729vw] ${
            isActive
              ? 'border-b-[0.104vw] border-mainOrange pb-[0.573vw] md:pb-[1.432vw] xs:pb-[2.933vw] text-mainBlack'
              : 'pb-[0.677vw] text-textGray md:pb-[1.693vw] xs:pb-[3.467vw]'
          } md:gap-[1.042vw] md:px-0 md:pt-[1.693vw] md:text-[1.823vw] md:leading-[3.125vw]
      xs:gap-[2.133vw] xs:px-0 xs:pt-[3.467vw] xs:text-[3.733vw] xs:leading-[6.4vw]`
        }
      >
        <img
          src="/images/icons/profile.svg"
          alt="profile icon"
          className="w-[1.042vw] h-[1.042vw] md:w-[2.604vw] md:h-[2.604vw] xs:w-[5.333vw] xs:h-[5.333vw]"
        />
        {t('profile.title')}
      </NavLink>
      <NavLink
        to={`${ROUTES.DASHBOARD}/${id}${ROUTES.PROFILE}${ROUTES.SECURITY}`}
        className={({ isActive }) =>
          `flex items-center gap-[0.417vw] px-[0.208vw] pt-[0.677vw] leading-[1.25vw] font-bold text-[0.729vw] ${
            isActive
              ? 'border-b-[0.104vw] border-mainOrange pb-[0.573vw] md:pb-[1.432vw] xs:pb-[2.933vw] text-mainBlack'
              : 'pb-[0.677vw] text-textGray md:pb-[1.693vw] xs:pb-[3.467vw]'
          }  md:gap-[1.042vw] md:px-0 md:pt-[1.693vw] md:text-[1.823vw] md:leading-[3.125vw]
      xs:gap-[2.133vw] xs:px-0 xs:pt-[3.467vw] xs:text-[3.733vw] xs:leading-[6.4vw]`
        }
      >
        <img
          src="/images/icons/security.svg"
          alt="security icon"
          className="w-[1.042vw] h-[1.042vw] md:w-[2.604vw] md:h-[2.604vw] xs:w-[5.333vw] xs:h-[5.333vw]"
        />
        {t('profile.security')}
      </NavLink>
    </div>
  );
};

export default Navbar;
