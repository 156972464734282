import { CSSProperties, FC, ReactNode } from 'react';

interface IProps {
  children: ReactNode;
  className?: string;
  onClick: () => void;
  style?: CSSProperties;
  disabled?: boolean;
  ghost?: boolean;
  ghostStyles?: string;
}
export const Button: FC<IProps> = ({
  children,
  className,
  onClick,
  style,
  disabled,
  ghost,
  ghostStyles,
}) => {
  return (
    <button
      className={`btn h-[2.5vw]  normal-case
      font-bold ${className || ''}
      ${disabled ? 'bg-black hover:bg-black' : 'bg-mainOrange hover:bg-mainOrange'} 
      md:h-[6.25vw] md:text-[2.604vw] md:leading-[3.906vw]
      xs:h-[12.8vw] xs:text-[5.333vw] xs:leading-[8vw]
      ${
        ghost
          ? 'bg-white text-mainBlack border-[1px] border-mainOrange text-[0.833vw] leading-[1.25vw] hover:bg-white' +
            ` ${ghostStyles}`
          : 'text-white border-none text-[1.042vw] leading-[1.563vw] '
      }`}
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
