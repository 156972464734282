import { useLocation } from 'react-router-dom';

import { ROUTES } from '../constants/routes';
import { useAppSelector } from '../redux/hooks';

export const useConfirmed = () => {
  const { status } = useAppSelector(state => state.user);
  const { pathname } = useLocation();

  if (status <= 1) {
    if (
      pathname.includes(ROUTES.ANALYTICS) ||
      pathname.includes(ROUTES.RATING) ||
      pathname.includes(ROUTES.QR_CODE)
    ) {
      return false;
    }
    return true;
  }

  if (status !== 0) return true;
};
