import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '../../../pages/Landing/components/share/useQuery';
import { useGetAdminDataQuery } from '../../../redux/api/user';
import { useAppSelector } from '../../../redux/hooks';
import { links } from '../config';

import { Link } from './Link';
import { QuitBlock } from './QuitBlock';
import { SidebarTopTitle } from './SidebarTopTitle';

export const DashboardSidebar = () => {
  const { id, role, adminId, organization } = useAppSelector(store => store.user);
  const { data } = useGetAdminDataQuery('not-confirmed', {
    refetchOnMountOrArgChange: true,
    skip: role !== 'admin',
  });

  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const isMobile = useMediaQuery('(max-width: 1280px) and (max-aspect-ratio: 1024/695)');

  const toggleExpand = () => setExpanded(prev => !prev);

  return (
    <>
      {expanded && <div className="md:w-[10.417vw] h-full" />}
      <section
        className={`w-[15.625vw] bg-sidebar bg-[#373A3E]
        text-white flex flex-col pt-[2.917vw] pb-[10.313vw]
        md:pt-[9.245vw] md:pb-0 ${isMobile && id === adminId && 'justify-end'}
        ${expanded ? 'md:w-[33vw] absolute top-0 left-0 z-10' : 'md:w-[10.417vw]'}
      `}
      >
        {id !== adminId && (
          <div className="md:h-[44%]">
            <SidebarTopTitle role={role} organization={organization} expanded={expanded} />
            <div
              className={`flex flex-col w-full mt-[6.563vw] md:mt-0 ${
                (!expanded || role === 'admin') && 'md:mt-[12.239vw]'
              }`}
            >
              {links.base.map(el => (
                <Link
                  key={el.icon}
                  to={el.to(id)}
                  expanded={expanded}
                  icon={el.icon}
                  text={t(el.text)}
                />
              ))}
            </div>
          </div>
        )}
        {id !== adminId && role === 'admin' && (
          <div className="flex justify-center mb-[1.979vw] mt-[2.396vw]">
            <div className="h-[1px] w-[12.135vw] bg-midGray md:w-[60%]" />
          </div>
        )}
        <div
          className={`flex flex-col justify-between ${role !== 'admin' ? 'mt-auto' : 'h-full'} ${
            isMobile ? (id !== adminId ? 'pb-[6.25vw] md:h-[55%]' : 'pb-[6.25vw] md:h-[90%]') : ''
          }`}
        >
          {role === 'admin' && (
            <div className={`flex flex-col justify-self-end ${id === adminId && 'mt-[5.677vw]'}`}>
              {links.admin.map(el => (
                <Link
                  key={el.icon}
                  to={el.to(id)}
                  text={t(el.text)}
                  icon={el.icon}
                  expanded={expanded}
                  active={true}
                  badge={el.badge ? data?.length : undefined}
                />
              ))}
            </div>
          )}
          <QuitBlock expanded={expanded} />
          {isMobile && (
            <button
              className={`flex w-full items-center h-[5.208vw] font-bold text-center text-white
              md:text-[2.604vw] md:leading-[3.906vw] ${
                expanded ? 'justify-start md:pl-[2.474vw] md:gap-[1.042vw]' : 'justify-center'
              }`}
              onClick={toggleExpand}
            >
              <img
                src="/images/icons/expand_menu.svg"
                alt="exit icon"
                className={`md:w-[3.125vw] md:h-[3.125vw] transition-transform ${
                  expanded && 'rotate-[180deg]'
                }`}
              />
              {expanded && t('menu.expand')}
            </button>
          )}
        </div>
      </section>
    </>
  );
};
