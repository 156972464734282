import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { Main } from './components/Main';
import { About } from './components/Main/components/About';
import { FirstSection } from './components/Main/components/FirstSection';
import { HowItWorks } from './components/Main/components/HowItWorks';
import { Questions } from './components/Main/components/Questions';
import { SecondSection } from './components/Main/components/SecondSection';
import { ThirdSection } from './components/Main/components/ThirdSection';

export const Landing = () => {
  return (
    <div className="w-full h-fit bg-[#060C18] overflow-hidden">
      <div className="w-[71.198vw] flex flex-col items-center mx-auto font-body md:w-full relative h-max">
        <Header />
        <Main>
          <FirstSection />
          <SecondSection />
          <ThirdSection />
          <HowItWorks />
          <About />
          <Questions />
        </Main>
      </div>
      <Footer />
    </div>
  );
};
