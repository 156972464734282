import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetAdminDataQuery } from '../../../redux/api/user';
import { useAppSelector } from '../../../redux/hooks';
import { links } from '../config';

import { AdminCurrentUser } from './AdminCurrentUser';
import { Link } from './Link';
import { QuitBlock } from './QuitBlock';

export const DashboardSidebarMobile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { organization, role, id, adminId } = useAppSelector(store => store.user);
  const { t } = useTranslation();
  const { data } = useGetAdminDataQuery('not-confirmed', {
    refetchOnMountOrArgChange: true,
    skip: role !== 'admin',
  });

  const toggleMenu = () => setIsOpen(prev => !prev);

  return (
    <section className="w-full bg-sidebar bg-[#373A3E]">
      <div
        className={`flex justify-between items-center px-[5.333vw] h-[21.333vw] relative z-30
        ${isOpen && 'border-b-[1px] border-white'}`}
      >
        <p className="font-bold text-[4.8vw] leading-[7.447vw] text-white">
          {role === 'admin' ? t('menu.admin') : organization}
        </p>
        <button onClick={toggleMenu}>
          <img
            src={isOpen ? '/images/icons/close_icon.svg' : '/images/icons/menu.svg'}
            alt="menu icon"
            className="w-[10.667vw] h-[10.667vw]"
          />
        </button>
      </div>
      <div
        className={`w-screen transition-all bg-sidebar bg-[#373A3E] ${
          isOpen
            ? 'translate-x-0 h-min xs:h-full min-h-screen absolute top-0 left-0 z-20'
            : '-translate-x-[150%] hidden'
        } pt-[26.667vw] flex flex-col pb-[53.333vw]`}
      >
        {id !== adminId && role === 'admin' && <AdminCurrentUser />}
        {id !== adminId && (
          <div className="pb-[8vw] flex flex-col mt-[13.867vw]">
            <div className="flex flex-col gap-[2.667vw]">
              {links.base.map(el => (
                <Link
                  key={el.icon}
                  to={el.to(id)}
                  expanded={true}
                  icon={el.icon}
                  text={t(el.text)}
                />
              ))}
            </div>
          </div>
        )}
        {id !== adminId && role === 'admin' && (
          <div className="h-[1px] w-[50%] ml-[6.4vw] bg-white mb-[8vw]" />
        )}

        <div
          className={`flex flex-col h-full ${
            id === adminId
              ? 'justify-between'
              : role !== 'admin'
              ? 'justify-end'
              : 'justify-between'
          }`}
        >
          {role === 'admin' && (
            <div className={`pb-[8vw] flex flex-col ${adminId === id && 'mt-[13.867vw]'}`}>
              {links.admin.map(el => (
                <Link
                  key={el.icon}
                  to={el.to(id)}
                  text={t(el.text)}
                  icon={el.icon}
                  expanded={true}
                  active={true}
                  badge={el.badge ? data?.length : undefined}
                />
              ))}
            </div>
          )}
          <QuitBlock expanded={true} />
        </div>
      </div>
    </section>
  );
};
