import { FC, useState } from 'react';

import i18n from '../../../../../i18n';
import { ReactComponent as ArrowDown } from '../../../../../static/arrow_down.svg';
import ClickAwayListener from '../../../../../view/ClickAwayListener';
import { languages } from '../config';

interface IProps {
  className: string;
}

export const LanguageSelect: FC<IProps> = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(prev => !prev);
  };
  const handleLangChange = (lng: string) => () => {
    i18n.changeLanguage(lng);
    setIsOpen(false);
  };

  /* eslint-disable-next-line */
  const handleClose = (e: any) => {
    if (e.target.classList.contains('away')) return;
    setIsOpen(false);
  };

  const correctLang =
    i18n.language.split('-').length > 1 ? i18n.language.split('-')[0] : i18n.language;

  return (
    <div
      className={`rounded-[5.208vw] border-[1px] border-white px-[1.042vw] py-[0.573vw] relative z-10 ${className}`}
    >
      <button className="flex items-center away" onClick={handleClick}>
        <p className="font-bold leading-[1.25vw] text-[0.833vw] text-white w-[1.615vw] away">
          {correctLang.toUpperCase()}
        </p>
        <ArrowDown
          className={`w-[1.25vw] h-[1.25vw] transition-transform away ${
            isOpen ? 'rotate-[180deg]' : 'rotate-0'
          }`}
        />
      </button>
      {isOpen && (
        <ClickAwayListener onClickAway={handleClose}>
          <ul
            className={`absolute -bottom-[0.208vw] right-0 translate-y-full
              w-[7.552vw] bg-white p-[1.042vw] rounded-[1.042vw] cursor-pointer
            `}
          >
            {languages.map(lng => (
              <li
                onClick={handleLangChange(lng)}
                className={`rounded-[2.083vw] ${lng === correctLang && 'bg-blackBg text-white'}
              pl-[1.042vw] py-[0.26vw] leading-[1.25vw] text-[0.833vw]
            `}
                key={lng}
              >
                {lng.toUpperCase()}
              </li>
            ))}
          </ul>
        </ClickAwayListener>
      )}
    </div>
  );
};
