import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useDisclosure } from '../../../hooks/useDisclosure';
import { useLoader } from '../../../hooks/useLoader';
import { useGetMenuCategoryItemsQuery } from '../../../redux/api/menu';
import { IMenuItemsResponse } from '../../../redux/api/types/menu';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { updateExtraPath } from '../../../redux/slices/userSlice';

export const useMenuCategoryBody = () => {
  const [searchValue, setSearchValue] = useState('');
  const [items, setItems] = useState<IMenuItemsResponse['data']['items']>([]);
  const { isOpen, open, close } = useDisclosure();
  const { categoryId } = useParams();
  const { menuLang } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();

  const { data, isFetching, isLoading, refetch } = useGetMenuCategoryItemsQuery(
    {
      id: +(categoryId as string),
      lang: menuLang,
    },
    { skip: !menuLang, refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (data) {
      dispatch(updateExtraPath(data.data.name));
    }
  }, [data]);

  useEffect(() => {
    return () => {
      dispatch(updateExtraPath(''));
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (data) {
        setItems(
          data.data.items.filter(e => e.name.toLowerCase().includes(searchValue.toLowerCase()))
        );
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [searchValue]);

  useEffect(() => {
    if (data) {
      setItems(data.data.items.slice(0, 8));
    }
  }, [data]);

  const isLoader = useLoader(isFetching, isLoading);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);

  return {
    data,
    isLoader,
    refetch,
    isOpen,
    open,
    close,
    items,
    searchValue,
    handleSearch,
    setItems,
  };
};
