import { FC } from 'react';

import { LoginForm } from '../../components/LoginForm';
import { RegisterForm } from '../../components/RegisterForm';
import { AuthTemplate } from '../../layouts/AuthTemplate';

interface IProps {
  type: 'register' | 'login';
}

export const AuthPage: FC<IProps> = ({ type }) => {
  return <AuthTemplate>{type === 'register' ? <RegisterForm /> : <LoginForm />}</AuthTemplate>;
};
