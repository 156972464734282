import { TFunction } from 'i18next';

export const truncate = (str: string) => {
  if (str.length <= 25) return str;
  const splited = str.split(' ');
  let res = '';

  for (const word of splited) {
    res += `${word} `;

    if (res.length + word.length >= 25) {
      break;
    }
  }

  return res.trim() + '...';
};

function getTimeName(value: number, words: string[]) {
  value = Math.abs(value) % 100;
  const num = value % 10;
  if (value > 10 && value < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num == 1) return words[0];
  return words[2];
}

const timeHours = ['foodMenu.client.hour1', 'foodMenu.client.hour2', 'foodMenu.client.hour3'];
const timeMinutes = [
  'foodMenu.client.minute1',
  'foodMenu.client.minute2',
  'foodMenu.client.minute3',
];

export const convertTime = (
  time: number,
  t: TFunction<'translation', undefined, 'translation'>
) => {
  const minutes = time % 60;
  const hours = Math.floor(time / 60);

  if (hours) {
    return `${hours} ${t(getTimeName(hours, timeHours))} ${minutes} ${t(
      getTimeName(minutes, timeMinutes)
    )}`;
  }

  return `${minutes} ${t(getTimeName(minutes, timeMinutes))}`;
};
