import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReviewType } from '../../../redux/api/types/reviews';
import { ReactComponent as ArrowDown } from '../../../static/arrow_down.svg';
import ClickAwayListener from '../../../view/ClickAwayListener';
import { dropDownItems } from '../config';

interface IProps {
  activeItems: ReviewType[];
  setActiveItems: Dispatch<SetStateAction<ReviewType[]>>;
}

const CategoryDropdown: FC<IProps> = ({ activeItems, setActiveItems }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const handleClick = () => {
    setIsOpen(prev => !prev);
  };
  const handleChange = (selected: ReviewType) => () => {
    setActiveItems(prev => {
      const ind = prev.findIndex(e => e === selected);
      if (ind < 0) {
        if (prev.length >= 6) return prev;
        return [...prev, selected];
      }
      const copy = [...prev];
      copy.splice(ind, 1);
      return copy;
    });
  };

  /* eslint-disable-next-line */
  const handleClose = (e: any) => {
    if (e.target.classList.contains('away')) return;
    setIsOpen(false);
  };

  return (
    <div className="max-w-min">
      <div
        className={`w-[19.01vw] px-[1.042vw] py-[0.573vw] shadow-pass rounded-[0.417vw] relative
    md:w-[40.234vw] md:px-[2.604vw] md:py-[1.432vw] md:rounded-[1.042vw] mt-[1vw] md:mb-[1vw] mb-[0.4vw]
    xs:w-full xs:px-[5.333vw] xs:py-[2.933vw] xs:rounded-[2.133vw] xs:mb-[2vw]`}
      >
        <button
          className="flex items-center away w-full h-full justify-between"
          onClick={handleClick}
        >
          <p
            className={`font-bold leading-[1.25vw] text-[0.833vw] text-mainBlack away text-ellipsis overflow-hidden whitespace-nowrap
        md:text-[2.083vw] md:leading-[3.125vw] xs:text-[4.267vw] xs:leading-[6.4vw]`}
          >
            {activeItems.map(item => t(`pay.types.${item}`)).join(', ')}
          </p>
          <ArrowDown
            className={`w-[1.25vw] h-[1.25vw] transition-transform away ${
              isOpen ? 'rotate-[180deg]' : 'rotate-0'
            } md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]`}
            stroke="black"
            fill="black"
            style={{ '&>path': { fill: 'black' } }}
          />
        </button>
      </div>
      {isOpen && (
        <ClickAwayListener onClickAway={handleClose}>
          <div
            className={`w-full bg-white shadow-pass rounded-[0.417vw] z-10
          md:rounded-[1.042vw] xs:rounded-[2.133vw] xs:mb-[2vw] md:mb-[1vw] mb-[0.4vw]
          pt-[1vw] px-[0.833vw] md:pt-[2.604vw] md:px-[2.083vw] xs:pt-[4.267vw] xs:px-[3.2vw]`}
          >
            <div className="flex justify-between mb-[0.5vw] md:mb-[1.302vw] xs:mb-[2.667vw]">
              <p className="text-[0.625vw] leading-[0.938vw] md:text-[1.563vw] md:leading-[2.344vw] xs:text-[3.2vw] xs:leading-[4.267vw]">
                Выбрано
              </p>
              <p className="text-[0.625vw] leading-[0.938vw] md:text-[1.563vw] md:leading-[2.344vw] xs:text-[3.2vw] xs:leading-[4.267vw]">
                {activeItems.length}/6
              </p>
            </div>
            <ul className="max-h-[18.229vw] md:max-h-[45.573vw] xs:max-h-[93.333vw] overflow-auto">
              {dropDownItems.map(item => (
                <li
                  key={item}
                  onClick={handleChange(item)}
                  className={`cursor-pointer md:text-[2.083vw] text-mainBlack text-ellipsis overflow-hidden whitespace-nowrap
                  md:leading-[3.125vw] xs:text-[4.267vw] xs:leading-[6.4vw] py-[0.573vw] px-[0.5vw]
                  md:px-[2.604vw] md:py-[1.432vw] xs:px-[5.333vw] xs:py-[2.933vw] rounded-[0.625vw]
                  hover:bg-overlay flex justify-between items-center md:rounded-[1.563vw] xs:rounded-[3.2vw]
                `}
                >
                  <div className="flex items-center gap-[0.417vw] md:gap-[1.042vw] xs:gap-[2.133vw]">
                    <img
                      src={`/images/icons/${item}.svg`}
                      alt={`${item} icon`}
                      className="w-[1.458vw] h-[1.458vw] md:w-[3.646vw] md:h-[3.646vw] xs:w-[7.467vw] xs:h-[7.467vw]"
                    />
                    {t(`pay.types.${item}`)}
                  </div>
                  <input
                    type="checkbox"
                    className="checkbox checkbox-warning checkbox-sm"
                    checked={activeItems.includes(item)}
                  />
                </li>
              ))}
            </ul>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default CategoryDropdown;
