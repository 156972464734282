import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { emailSchema, passwordSchema, stringSchema } from './base';

export const useRegisterValidationSchema = () => {
  const { t } = useTranslation();

  const schema = yup.object({
    email: emailSchema({
      required: t('errors.email.required'),
      length: t('errors.email.length'),
      invalid: t('errors.email.invalid'),
    }),
    password: passwordSchema({
      space: t('errors.pass.space'),
      required: t('errors.pass.required'),
      chars: t('errors.pass.chars'),
      length: t('errors.pass.min'),
    }),
    firstName: stringSchema('Name', 3, {
      length: t('errors.name.length'),
      required: t('errors.name.required'),
    }),
    lastName: stringSchema('Surname', 2, {
      length: t('errors.surname.length'),
      required: t('errors.surname.required'),
    }),
    organization: stringSchema('Organization', 3, {
      length: t('errors.organization.length'),
      required: t('errors.organization.required'),
    }),
    confirmPassword: passwordSchema({
      space: t('errors.pass.space'),
      required: t('errors.pass.required'),
      chars: t('errors.pass.chars'),
      length: t('errors.pass.min'),
    }),
    socialLink: stringSchema('Social link', 0, {
      required: t('errors.social.required'),
    }),
    phone: stringSchema('Phone number', 12, {
      required: t('errors.phone.required'),
      length: t('errors.phone.length'),
    }),
  });

  return schema;
};
