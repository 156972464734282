import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { TOAST_OPTIONS } from '../../config';
import { API_URL } from '../../constants/api';
import useMediaQuery from '../../pages/Landing/components/share/useQuery';
import { useAppSelector } from '../../redux/hooks';

export const PDFButton = ({ period, isAdmin }: { period?: string; isAdmin?: boolean }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const { id, adminId } = useAppSelector(state => state.user);

  const clickHandler = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem('tipsToken');

      const resp = await fetch(`${API_URL}/pdf/${isAdmin ? adminId : id}?period=${period}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (resp.status !== 200) {
        throw new Error('', { cause: resp.status });
      }

      const blob = await resp.blob();

      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute('download', 'summary.pdf');
      link.click();
    } catch (error) {
      if ((error as { cause: number }).cause === 429) {
        toast.error('Too many requests', TOAST_OPTIONS);
      } else {
        toast.error('Forbidden', TOAST_OPTIONS);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      className={`bg-white text-mainBlack font-bold text-[1.042vw] leading-[1.563vw] flex items-center gap-[0.521vw]
      rounded-[0.417vw] border-[1px] border-phoneOrange px-[2.656vw] py-[0.469vw] normal-case hover:bg-white hover:border-phoneOrange
      md:rounded-[1.042vw] md:px-[2.995vw] md:py-[1.172vw] md:text-[2.604vw] md:leading-[3.906vw] md:gap-[1.302vw]
      xs:rounded-[2.133vw] xs:px-[6vw] xs:py-[2.133vw] disabled:bg-slate-100
      `}
      onClick={clickHandler}
      disabled={isLoading}
    >
      {!isMobile && t('analytic.pdf')}
      <img
        src="/images/icons/upload.svg"
        alt="upload icon"
        className="w-[1.25vw] h-[1.25vw] md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]"
      />
    </button>
  );
};
