import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { API_URL } from '../../../../../constants/api';
import MenuButton from '../../../shared/Button';

interface IProps {
  image?: string;
  qrLink: string;
  feedbackLink: string;
  menuLink: string;
}

const ButtonBlock: FC<IProps> = ({ image, qrLink, feedbackLink, menuLink }) => {
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const handleClick = (to: string) => () => {
    if (!menuLink) navigate(to);
  };

  return (
    <div className="flex items-center relative z-10 flex-col">
      <h1
        className={`dark:text-white text-[#4D4D4D] text-[40px] leading-[46px] mb-[40px] font-bold ${
          language !== 'ge' ? 'font-sourceSans' : 'font-helvetica'
        }`}
      >
        {t('foodMenu.client.greating')}
      </h1>
      <div className="overflow-hidden rounded-full border-[1px] border-mainOrange w-[51.733vw] h-[51.733vw] dark:bg-blackBg bg-[#F3F3F3] flex items-center justify-center mb-[80px]">
        {image ? (
          <img src={`${API_URL}/image/${image}`} />
        ) : (
          <p className="text-[#EAEAEA] dark:text-[#3e3e3e] text-[36px] leading-normal max-w-[76px]">
            your logo
          </p>
        )}
      </div>
      <div className="flex flex-col gap-[12px] w-[78.667vw]">
        <MenuButton
          dark={false}
          icon="restaraunt"
          link={menuLink}
          onClick={handleClick('categories')}
        >
          {t('foodMenu.client.menuBtn')}
        </MenuButton>
        <MenuButton dark={false} icon="payment" link={qrLink}>
          {t('foodMenu.client.tipsBtn')}
        </MenuButton>
        <MenuButton dark={true} icon="feedback" link={feedbackLink}>
          {t('foodMenu.client.feedbackBtn')}
        </MenuButton>
      </div>
    </div>
  );
};

export default ButtonBlock;
