import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { API_URL } from '../../constants/api';

interface IProps {
  fileChange: (e: ChangeEvent<HTMLInputElement>) => void;
  size?: 'xl' | 'sm';
  image?: string;
  withDesc?: boolean;
  file?: File;
  className?: string;
}

const ImageInput: FC<IProps> = ({ fileChange, className, size, image, withDesc, file }) => {
  const { t } = useTranslation();
  const [preview, setPreview] = useState('');

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = data => {
        setPreview(data.target?.result as string);
      };
    } else {
      setPreview('');
    }
  }, [file]);

  return (
    <div
      className={`${
        size === 'xl'
          ? 'w-[19.011vw] px-[3.672vw] py-[2.083vw] md:w-full'
          : 'py-[0.5vw] w-[7.611vw] md:w-[20vw] xs:w-full'
      } self-start text-center
    shadow-pass border-[1px] rounded-[0.833vw] border-authBorder flex items-center justify-center
    md:px-[3.125vw] md:py-[5.208vw] md:rounded-[2.083vw]
    xs:px-[6.4vw] xs:py-[10.667vw] xs:rounded-[4.267vw] ${className ?? ''}`}
    >
      <input
        accept="image/png, image/jpeg"
        type="file"
        id="file"
        name="file"
        className="hidden"
        onChange={fileChange}
      />
      <label
        htmlFor="file"
        className={`flex items-center flex-col text-[0.729vw] leading-[1.042vw] text-textGray text-center justify-center cursor-pointer
    md:text-[1.823vw] md:leading-[2.604vw] xs:text-[3.733vw] xs:leading-[5.333vw] md:max-w-[34.896vw] xs:max-w-[71.467vw]`}
      >
        <img
          src={preview ? preview : image ? `${API_URL}/image/${image}` : '/images/upload.png'}
          alt="upload img"
          className={
            size === 'xl'
              ? 'w-[7.5vw] h-[7.5vw] mb-[1.25vw] md:w-[18.75vw] md:h-[18.75vw] md:mb-[3.125vw] xs:w-[38.4vw] xs:h-[38.4vw] xs:mb-[6.4vw]'
              : 'w-7/12 h-7/12 md:w-full md:h-full'
          }
        />
        {withDesc && t('employee.image')}
      </label>
    </div>
  );
};

export default ImageInput;
