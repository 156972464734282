import { USER_QR, USER_QR_UPDATE } from '../../constants/api';

import { IQRResponse } from './types/qr';
import baseApi from './base';

const qrApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getUserQr: builder.query<IQRResponse, number>({
      query: id => ({
        url: `${USER_QR}/${id}`,
      }),
    }),
    updateUserQR: builder.mutation<unknown, number>({
      query: id => ({
        url: `${USER_QR_UPDATE}/${id}`,
        method: 'PUT',
      }),
    }),
  }),
});

export const { useGetUserQrQuery, useUpdateUserQRMutation } = qrApi;
