export const hoverEffect = `relative after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-full after:h-full after:border-b-[4px] after:rounded-[5.208vw]
  after:duration-300 after:transition-opacity after:bg-transparent after:border-[#BB8900] hover:after:opacity-100 after:opacity-0
  md:after:rounded-[13.021vw]`;

export const yellowDot = `
  before:content-[''] before:bg-[#F1B615] before:rounded-[50%] before:w-[0.417vw] md:before:w-[0.781vw]
  before:h-[0.417vw] md:before:h-[0.781vw] before:block before:absolute relative before:left-0 before:top-[50%]
  before:-translate-y-[50%] pl-[1.458vw] md:pl-[1.823vw]
  xs:before:w-[1.6vw] xs:before:h-[1.6vw] xs:pl-[4.8vw]
`;

export const yellowDotBig = `
  before:content-[''] before:bg-[#F1B615] before:rounded-[50%] before:w-[0.417vw] max-w-[29.635vw]
  before:h-[0.417vw] before:block before:absolute relative before:left-0 pl-[1.25vw] before:translate-y-full
  md:max-w-full md:before:w-[1.042vw] md:before:h-[1.042vw] md:pl-[3.255vw] xs:w-full
  xs:before:w-[2.113vw] xs:before:h-[2.113vw] xs:pl-[6.667vw]
`;
