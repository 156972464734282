import { useEffect, useState } from 'react';

export const useLoader = (...args: boolean[]) => {
  const [isMounted, setIsMounted] = useState(false);
  const [some, setSome] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    setSome(args.some(e => e));
  }, args);

  return isMounted && some;
};
