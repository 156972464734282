import { FormikConfig } from '../../types/base';

export const config: FormikConfig = {
  name: {
    type: 'text',
    placeholder: 'auth.name',
    required: true,
    initial: '',
  },
  surname: {
    type: 'text',
    placeholder: 'auth.surname',
    required: false,
    initial: '',
  },
  thirdname: {
    type: 'text',
    placeholder: 'employee.thirdName',
    required: false,
    initial: '',
  },
  wish: {
    type: 'text',
    placeholder: 'employee.wish',
    required: false,
    initial: '',
  },
  proffession: {
    type: 'text',
    placeholder: 'employee.proffession',
    required: false,
    initial: '',
  },
  organization: {
    type: 'text',
    placeholder: 'auth.organization',
    required: true,
    initial: '',
    disabled: true,
  },
  iban: {
    type: 'text',
    placeholder: 'iban',
    required: true,
    initial: '',
  },
};
