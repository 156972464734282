import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { API_URL } from '../../../constants/api';
import { ROUTES } from '../../../constants/routes';
import { useGetAfterPayDataQuery } from '../../../redux/api/payment';
import { Button } from '../../../view/Button';

export const PaymentEnd = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { data } = useGetAfterPayDataQuery(id as string);

  return (
    <div
      className={`w-full h-full relative bg-cover flex items-end bg-center ${
        data?.image ? 'bg-phoneBG' : 'bg-afterPay'
      }`}
    >
      {data?.image && (
        <img
          src={`${API_URL}/image/${data?.image}`}
          className="absolute top-[15px] left-[15px] z-0 w-[92vw] h-[128vw] rounded-[3.2vw]"
          alt="image"
        />
      )}
      <div className="flex flex-col items-center bg-white w-full h-[78.4vw] rounded-t-[3.467vw] p-[5.333vw] relative z-10">
        {data?.title ? (
          <p className="mb-[5.333vw] text-[4.267vw] leading-[6.4vw] text-mainBlack font-bold">
            {data.title}
          </p>
        ) : (
          <img
            src="/images/after_pay_icon.png"
            alt="ok icon"
            className="w-[16vw] h-[16vw] mb-[5.333vw]"
          />
        )}
        {data?.description ? (
          <p className="text-[4.267vw] leading-[6.4vw] text-mainBlack max-w-[80vw] text-center mb-[10vw]">
            {data.description}
          </p>
        ) : (
          <p className="text-center text-mainBlack text-[5.333vw] leading-[8vw] mb-[6.4vw]">
            {t('pay.end')}
          </p>
        )}
        {data?.link ? (
          <Button className="w-full self-end" onClick={() => {}}>
            <a className="w-full h-full flex items-center justify-center" href={data.link}>
              {data?.buttonText ? data.buttonText : t('closeBtn')}
            </a>
          </Button>
        ) : (
          <Button className="w-full self-end" onClick={() => navigate(ROUTES.LANDING)}>
            {data?.buttonText ? data.buttonText : t('closeBtn')}
          </Button>
        )}
      </div>
    </div>
  );
};
