import { useTranslation } from 'react-i18next';

import useMediaQuery from '../../../share/useQuery';

import { Card } from './components/Card';
import { SwiperBlock } from './components/Swiper/Swiper';
import { cards } from './config';

import './style.css';

export const HowItWorks = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <section
      id="howworks"
      className="w-full flex flex-col items-center md:items-start gap-[6.146vw] md:gap-[6.51vw] pt-[7.812vw] mt-[-7.812vw] pb-[4.948vw] md:pb-[20.963vw] md:pt-[13vw] md:mt-[-13vw] xs:pt-[19vw] xs:mt-[-19vw] xs:pb-[91.467vw]"
    >
      <h2 className="font-bold text-[2.813vw] leading-[3.333vw] text-center text-white md:self-center md:text-[5.208vw] md:leading-[7.292vw] xs:text-[8vw] xs:leading-[12.8vw]">
        {t('landing.how.title')}
      </h2>
      <div className="w-full md:overflow-x-auto scrollbar-hide h-max">
        {isMobile ? (
          <SwiperBlock />
        ) : (
          <div className="w-full flex items-start justify-center h-max md:justify-start md:w-max xs:w-max">
            {cards.map((card, i) => (
              <Card card={card} key={card.image} index={i + 1} arrow={i + 1 !== cards.length} />
            ))}
          </div>
        )}
      </div>
    </section>
  );
};
