import { FC } from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../../constants/routes';
import { IEmploeyyCardItem } from '../../../redux/api/types/user';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { openModal } from '../../../redux/slices/userSlice';
import ClickAwayListener from '../../../view/ClickAwayListener';

interface IProps {
  handleClose: () => void;
  coords: { top: number; left: number };
  employee: IEmploeyyCardItem;
  onDelete: () => void;
}

export const EmployeePortal: FC<IProps> = ({ handleClose, coords, employee, onDelete }) => {
  const dispatch = useAppDispatch();
  const { id } = useAppSelector(state => state.user);

  const handleDeleteUser = () => {
    handleClose();
    dispatch(openModal());
    onDelete();
  };

  return createPortal(
    <ClickAwayListener onClickAway={handleClose}>
      <div
        className={`absolute p-[0.417vw] shadow-auth z-10 bg-white rounded-[0.625vw] border-[1px]
          border-midGray md:rounded-[1.563vw] xs:rounded-[3.2vw] md:p-[1.042vw] xs:p-[2.133vw]
          -translate-x-[100%]
        `}
        style={coords}
      >
        <Link
          to={`${ROUTES.DASHBOARD}/${id}${ROUTES.RATING}${ROUTES.ADD_EMPLOYEE}`}
          className="flex items-center gap-[0.521vw] h-[2.083vw] md:h-[5.208vw] xs:h-[10.667vw] w-max"
          state={{ employee }}
        >
          <img
            src="/images/icons/edit.svg"
            alt="icon"
            className="w-[1.25vw] h-[1.25vw] md:w-[2.604vw] md:h-[2.604vw] xs:w-[5.333vw] xs:h-[5.333vw]"
          />
          <p className="text-[0.729vw] leading-[1.042vw] md:text-[1.823vw] md:leading-[2.604vw] xs:text-[3.733vw] xs:leading-[5.333vw]">
            Редактировать
          </p>
        </Link>
        <button
          className="flex items-center gap-[0.521vw] h-[2.083vw] md:h-[5.208vw] xs:h-[10.667vw] w-max"
          onClick={handleDeleteUser}
        >
          <img
            src="/images/icons/trash.svg"
            alt="icon"
            className="w-[1.25vw] h-[1.25vw] md:w-[2.604vw] md:h-[2.604vw] xs:w-[5.333vw] xs:h-[5.333vw]"
          />
          <p className="text-[0.729vw] leading-[1.042vw] md:text-[1.823vw] md:leading-[2.604vw] xs:text-[3.733vw] xs:leading-[5.333vw]">
            Удалить
          </p>
        </button>
      </div>
    </ClickAwayListener>,
    document.body
  );
};
