import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '../../../constants/routes';
import useMediaQuery from '../../../pages/Landing/components/share/useQuery';
import { IAdminDataResponse } from '../../../redux/api/types/user';
import { useAppDispatch } from '../../../redux/hooks';
import { openModal, replaceUser } from '../../../redux/slices/userSlice';
import { ReactComponent as TrashIcon } from '../../../static/trash.svg';

interface IProps {
  item: IAdminDataResponse;
  setDeleteId?: Dispatch<SetStateAction<number>>;
  isNew: boolean;
  deleteHandler?: (id: number) => () => void;
  acceptHandler?: (id: number) => () => void;
}

export const TableRow: FC<IProps> = ({
  item,
  setDeleteId,
  isNew,
  acceptHandler,
  deleteHandler,
}) => {
  const { organization, iban, email, id } = item;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isTablet = useMediaQuery('(max-width: 1280px) and (max-aspect-ratio: 1024/695)');
  const isMobile = useMediaQuery('(max-width: 600px)');

  const handleDelete = () => {
    setDeleteId?.(id);
    dispatch(openModal());
  };

  const handleNavigate = () => {
    if (!isNew) {
      dispatch(replaceUser({ id, organization }));
      navigate(`${ROUTES.DASHBOARD}/${id}${ROUTES.ANALYTICS}`);
    }
  };

  return (
    <div
      className={`flex items-center justify-between text-[1.042vw]
        leading-[1.563vw] text-mainBlack bg-white
        border-b-[1px] border-midGray py-[0.521vw] md:py-[1.302vw] xs:pt-[2.667vw] xs:pb-[5.333vw]
        xs:text-[3.733vw] xs:leading-[5.333vw] md:text-[2.604vw] md:leading-[3.906vw]
      `}
    >
      <div
        className={`flex items-center gap-[1.302vw] ${!isNew && 'cursor-pointer'}`}
        onClick={handleNavigate}
      >
        <p className="w-[16.719vw] md:w-[31.901vw] xs:w-[42.133vw] text-ellipsis overflow-hidden whitespace-nowrap">
          {organization}
        </p>
        {!isMobile && !isTablet && (
          <>
            <p className="w-[16.875vw]">{iban}</p>
            <p className="w-[15.417vw]">{email}</p>
          </>
        )}
      </div>
      {isNew ? (
        <></>
      ) : (
        <div className="w-[12.031vw] flex justify-end md:w-[20.313vw] xs:w-[18.4vw]">
          <button
            className={`${
              isNew ? 'bg-mainGreen' : 'bg-mainYellow'
            } flex gap-[0.521vw] leading-[1.25vw] text-[0.833vw] md:gap-[1.302vw] md:px-[2.604vw] md:py-[0.781vw]
            normal-case px-[1.042vw] py-[0.313vw] rounded-[0.417vw] text-mainBlack xs:py-[1.6vw] xs:pl-[6.4vw] xs:pr-[5.333vw]
            active:focus:scale-[0.95] md:rounded-[1.042vw] xs:rounded-[2.133vw] md:text-[2.083vw] md:leading-[3.125vw]`}
            onClick={acceptHandler?.(id)}
          >
            {item.reviews}
          </button>
        </div>
      )}
      <div
        className={`w-[10.573vw] flex justify-end md:w-[20.313vw] ${
          isNew ? 'xs:w-[32.4vw]' : 'xs:w-[18.4vw]'
        }`}
      >
        {isNew ? (
          <div className="flex w-full justify-end gap-[0.521vw] md:gap-[1.302vw] xs:gap-[2vw]">
            <button
              className={`bg-mainGreen flex items-center md:rounded-[1.042vw] px-[0.833vw] py-[0.417vw]
              rounded-[0.417vw] active:focus:scale-[0.95] xs:rounded-[2.133vw] md:px-[2.083vw] md:py-[1.042vw] xs:px-[2.667vw] xs:py-[1.067vw]`}
              onClick={acceptHandler?.(id)}
            >
              <img
                src="/images/icons/check.svg"
                alt="accept icon"
                className="w-[1.25vw] h-[1.25vw] md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]"
              />
            </button>
            <button
              className={`bg-mainRed flex items-center md:rounded-[1.042vw] px-[0.833vw] py-[0.417vw]
              rounded-[0.417vw] active:focus:scale-[0.95] xs:rounded-[2.133vw] md:px-[2.083vw] md:py-[1.042vw] xs:px-[2.667vw] xs:py-[1.067vw]`}
              onClick={deleteHandler?.(id) || handleDelete}
            >
              <img
                src="/images/icons/remove.svg"
                alt="decline icon"
                className="w-[1.25vw] h-[1.25vw] md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]"
              />
            </button>
          </div>
        ) : (
          <button
            className={`bg-mainRed flex gap-[0.521vw] leading-[1.25vw] text-[0.833vw] md:gap-[1.302vw] md:px-[2.604vw] md:py-[0.781vw]
            normal-case px-[1.042vw] py-[0.313vw] rounded-[0.417vw] text-white xs:py-[1.6vw] xs:pl-[6.4vw] xs:pr-[5.333vw]
            active:focus:scale-[0.95] md:rounded-[1.042vw] xs:rounded-[2.133vw] md:text-[2.083vw] md:leading-[3.125vw]`}
            onClick={deleteHandler?.(id) || handleDelete}
          >
            {!isMobile && t('organizations.delete')}
            <TrashIcon className="w-[1.25vw] h-[1.25vw] md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]" />
          </button>
        )}
      </div>
    </div>
  );
};
