import { useTranslation } from 'react-i18next';

import { yellowDotBig } from '../../../share/classes';

export const ThirdSection = () => {
  const {
    t,
    i18n: { language: lang },
  } = useTranslation();
  const correctLang = lang.split('-').length > 1 ? lang.split('-')[0] : lang;
  return (
    <section
      className={`w-full flex flex-col items-center gap-[4.531vw] md:gap-[6.51vw] xs:gap-[8vw] mb-[3.678vw]
      md:mb-0 md:pb-[10.417vw] xs:pb-0 xs:mb-[21.333vw] pt-[6vw] mt-[-5vw] md:pt-[13vw] md:mt-[-13vw] xs:pt-[19vw] xs:mt-[-19vw]`}
      id="third"
    >
      <h2 className="font-bold text-[2.813vw] leading-[3.333vw] text-center text-white md:text-[5.208vw] md:leading-[7.292vw] xs:text-[8vw] xs:leading-[12.8vw]">
        {t('landing.for.title')}
      </h2>
      <div className="flex justify-end gap-[5.729vw] md:flex-col md:items-center md:gap-[4.427vw] md:w-[87.63vw] xs:w-full xs:gap-[5.333vw]">
        <img
          src={`/images/landing/tablet_phone_${correctLang}.png`}
          alt="tablet and phone"
          className="w-[28.802vw] h-[35vw] translate-y-[2.708vw] md:w-[61.979vw] md:h-[75.26vw] md:xs:translate-y-0 xs:w-full xs:h-[124.067vw]"
        />
        <div className="flex flex-col gap-[1.042vw] md:gap-[2.604vw] md:w-full xs:w-full xs:gap-[5.333vw]">
          <div
            className={`bg-cardBg3 backdrop-blur-[2px] rounded-[1.042vw] pt-[1.563vw] pl-[3.385vw] pr-[2.083vw] pb-[1.979vw]
            md:rounded-[2.604vw] md:pt-[3.906vw] md:pl-[8.333vw] md:pr-[5.078vw] md:pb-[4.558vw] md:w-full xs:full
            xs:rounded-[5.333vw] xs:pt-[3.906vw] xs:pl-[5.333vw] xs:pr-[5.333vw] xs:pb-[5.333vw]`}
          >
            <h3
              className={`font-bold text-[1.563vw] leading-[2.083vw] text-white mb-[1.198vw]
              md:text-[3.125vw] md:leading-[4.427vw] md:mb-[2.604vw]
              xs:text-[6.4vw] xs:leading-[9.067vw] xs:mb-[3.467vw] xs:pl-[11.733vw]`}
            >
              {t('landing.for.first.title')}
            </h3>
            <ul
              className={`text-white text-[1.042vw] leading-[1.563vw] max-w-[30.938vw] flex flex-col gap-[0.833vw] -translate-x-[1.302vw]
              md:-translate-x-[3.125vw] md:text-[2.083vw] md:leading-[3.125vw] md:max-w-[77.344vw] md:gap-[1.563vw]
              xs:text-[4.267vw] xs:leading-[6.4vw] xs:max-w-full xs:gap-[2.133vw] xs:translate-x-[0]`}
            >
              <li className={yellowDotBig}>{t('landing.for.first.text1')}</li>
              <li className={yellowDotBig}>{t('landing.for.first.text2')}</li>
              <li className={yellowDotBig}>{t('landing.for.first.text3')}</li>
              <li className={yellowDotBig}>{t('landing.for.first.text4')}</li>
            </ul>
          </div>
          <div
            className={`bg-cardBg3 backdrop-blur-[2px] rounded-[1.042vw] pt-[1.563vw] pl-[3.385vw] pr-[2.083vw] pb-[1.979vw]
            md:rounded-[2.604vw] md:pt-[3.906vw] md:pl-[8.333vw] md:pr-[5.078vw] md:pb-[4.558vw] md:w-full xs:full
            xs:rounded-[5.333vw] xs:pt-[3.906vw] xs:pl-[5.333vw] xs:pr-[5.333vw] xs:pb-[5.333vw]`}
          >
            <h3
              className={`font-bold text-[1.563vw] leading-[2.083vw] text-white mb-[1.198vw]
              md:text-[3.125vw] md:leading-[4.427vw] md:mb-[2.604vw]
              xs:text-[6.4vw] xs:leading-[9.067vw] xs:mb-[3.467vw] xs:pl-[11.733vw]`}
            >
              {t('landing.for.second.title')}
            </h3>
            <ul
              className={`text-white text-[1.042vw] leading-[1.563vw] max-w-[30.938vw] flex flex-col gap-[0.833vw] -translate-x-[1.302vw]
              md:-translate-x-[3.125vw] md:text-[2.083vw] md:leading-[3.125vw] md:max-w-[77.344vw] md:gap-[1.563vw]
              xs:text-[4.267vw] xs:leading-[6.4vw] xs:max-w-full xs:gap-[2.133vw] xs:translate-x-[0]`}
            >
              <li className={yellowDotBig}>{t('landing.for.second.text1')}</li>
              <li className={yellowDotBig}>{t('landing.for.second.text2')}</li>
              <li className={yellowDotBig}>{t('landing.for.second.text3')}</li>
            </ul>
          </div>
          <div
            className={`bg-cardBg3 backdrop-blur-[2px] rounded-[1.042vw] pt-[1.563vw] pl-[3.385vw] pr-[2.083vw] pb-[1.979vw]
            md:rounded-[2.604vw] md:pt-[3.906vw] md:pl-[8.333vw] md:pr-[5.078vw] md:pb-[4.558vw] md:w-full
            xs:rounded-[5.333vw] xs:pt-[3.906vw] xs:pl-[5.333vw] xs:pr-[5.333vw] xs:pb-[5.333vw]`}
          >
            <h3
              className={`font-bold text-[1.563vw] leading-[2.083vw] text-white mb-[1.198vw]
              md:text-[3.125vw] md:leading-[4.427vw] md:mb-[2.604vw]
              xs:text-[6.4vw] xs:leading-[9.067vw] xs:mb-[3.467vw] xs:pl-[11.733vw]`}
            >
              {t('landing.for.third.title')}
            </h3>
            <ul
              className={`text-white text-[1.042vw] leading-[1.563vw] max-w-[30.938vw] flex flex-col gap-[0.833vw] -translate-x-[1.302vw]
              md:-translate-x-[3.125vw] md:text-[2.083vw] md:leading-[3.125vw] md:max-w-[77.344vw] md:gap-[1.563vw]
              xs:text-[4.267vw] xs:leading-[6.4vw] xs:max-w-full xs:gap-[2.133vw] xs:translate-x-[0]`}
            >
              <li className={yellowDotBig}>{t('landing.for.third.text1')}</li>
              <li className={yellowDotBig}>{t('landing.for.third.text2')}</li>
              <li className={yellowDotBig}>{t('landing.for.third.text3')}</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
