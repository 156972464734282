import jwt from 'jwt-decode';

import { JWTUser } from '../types/base';

export const pathnameSlicer = (pathname: string) => {
  const index = pathname.lastIndexOf('/');
  const endpoint = pathname.slice(index + 1);
  if (parseInt(endpoint)) {
    const slicedPath = pathname.slice(0, index);
    const nextIndex = slicedPath.lastIndexOf('/');
    return pathname.slice(nextIndex + 1, index);
  }
  return endpoint;
};

export const trim = (str: string, length: number) => {
  return `${str.slice(0, length + 1)}...`;
};

export const getShortName = (name: string) => {
  const splited = name.split(' ');
  if (splited.length <= 1) return name.slice(0, 2).toUpperCase();
  else return `${splited[0][0]}${splited[1][0]}`.toUpperCase();
};

export const checkToken = (): [boolean, number] => {
  const token = localStorage.getItem('tipsToken');
  if (!token) return [false, -1];

  const decoded = jwt(token) satisfies JWTUser;

  if (Date.now() < new Date(decoded.exp * 1000).getTime()) {
    return [true, decoded.id];
  }
  return [false, -1];
};
