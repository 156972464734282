import { ReactComponent as DietIcon } from '../../../../static/dietary.svg';
import { ReactComponent as HalalIcon } from '../../../../static/halal.svg';
import { ReactComponent as HotIcon } from '../../../../static/hot.svg';
import { ReactComponent as KosherIcon } from '../../../../static/kosher.svg';
import { ReactComponent as LentenIcon } from '../../../../static/lenten.svg';
import { ReactComponent as VeganIcon } from '../../../../static/vegan.svg';
import { ReactComponent as VegetarianIcon } from '../../../../static/vegetarian.svg';
import { DishTypes } from '../../../../types/base';

export const config = {
  [DishTypes.DIET]: {
    color: '#4285F4',
    icon: DietIcon,
    title: 'foodMenu.item.types.diet',
  },
  [DishTypes.HALAL]: {
    color: '#A5842B',
    icon: HalalIcon,
    title: 'foodMenu.item.types.halal',
  },
  [DishTypes.HOT]: {
    color: '#E04141',
    icon: HotIcon,
    title: 'foodMenu.item.types.hot',
  },
  [DishTypes.KOSHER]: {
    color: '#2BA4D8',
    icon: KosherIcon,
    title: 'foodMenu.item.types.kosher',
  },
  [DishTypes.POST]: {
    color: '#9CABBA',
    icon: LentenIcon,
    title: 'foodMenu.item.types.post',
  },
  [DishTypes.VEGAN]: {
    color: '#32936F',
    icon: VeganIcon,
    title: 'foodMenu.item.types.vegan',
  },
  [DishTypes.VEGETARIAN]: {
    color: '#518B54',
    icon: VegetarianIcon,
    title: 'foodMenu.item.types.vegetarian',
  },
} as const;
