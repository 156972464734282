import { FC } from 'react';

import { useDarkTheme } from '../hooks/useDarkTheme';

interface IProps {
  F?: string;
}

const Switch: FC<IProps> = ({}) => {
  const [isChecked, setIsChecked] = useDarkTheme();

  const handleCheckboxChange = () => {
    setIsChecked(prev => !prev);
  };

  return (
    <label className="relative w-[68px] h-[42px] inline-block">
      <input
        className="opacity-0 w-0 h-0"
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <span
        className={`
        absolute cursor-pointer top-0 left-0 right-0 bottom-0 transition-all dark:bg-switchBgGradient dark:border-none border-[1px] border-[#D8D8D8] bg-white rounded-[40px] before:rounded-full
        before:absolute before:h-[34px] before:w-[34px] before:left-[4px] before:dark:bottom-[4px] before:bottom-[3px] before:bg-switchThumbGradient before:dark:bg-none before:dark:bg-bgNeutral20 before:transition-all
        ${isChecked ? 'before:dark:translate-x-[80%] before:translate-x-[73%]' : ''}
      `}
      />
    </label>
  );
};

export default Switch;
