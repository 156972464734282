import { FC, MouseEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from '../../../../../i18n';
import { dashboardLink } from '../../../config';
import { hoverEffect } from '../../share/classes';
import { Navbar } from '../../share/Navbar';
import useMediaQuery from '../../share/useQuery';
import { languages } from '../config';

import Overlay from './Overlay';

interface IProps {
  toggleBurger: () => void;
  isOpen: boolean;
}

export const SideMenu: FC<IProps> = ({ toggleBurger, isOpen }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 600px)');

  const changeLang = (lng: string) => () => {
    i18n.changeLanguage(lng);
    toggleBurger();
  };

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLElement).classList.contains('close')) {
      if (isOpen) toggleBurger();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [isOpen]);

  return (
    <div
      className={`duration-200 transition-transform scrollbar-hide ${
        isOpen ? 'translate-x-0' : '-translate-x-[150%]'
      } fixed top-0 left-0 transition-all overflow-y-auto w-full h-screen z-50 close`}
      onMouseDown={handleClick}
      onPointerDown={handleClick}
    >
      {isOpen && !isMobile && <Overlay />}
      <div
        className={`h-max min-h-screen w-[48.828vw] xs:w-full bg-[#060C18] flex flex-col items-center
        xs:pb-[10.667vw] md:pb-[5.208vw]`}
      >
        <div className="h-[13.021vw] flex items-center xs:gap-[20.533vw] gap-[10.026vw] px-[2.734vw] xs:px-[5.6vw] w-full xs:h-[20vw]">
          <button onClick={toggleBurger}>
            <img
              src="/images/landing/close.svg"
              alt="close icon"
              className="md:w-[5.208vw] md:h-[5.208vw] xs:w-[10.667vw] xs:h-[10.667vw]"
            />
          </button>
          <img
            src="/images/logo.png"
            alt="main logo"
            className="md:w-[12.76vw] md:h-[6.51vw] -translate-x-[2vw] xs:w-[26.133vw] xs:h-[13.333vw]"
          />
        </div>
        <div className="py-[13.021vw] xs:py-[15.447vw] flex items-center justify-center xs:mb-[3.2vw]">
          <Navbar mr="" className="" handler={toggleBurger} />
        </div>
        <div className="w-[43.62vw] xs:w-[89.333vw] h-[1px] bg-white" />
        <div className="mt-[13.021vw]">
          <div className="flex flex-col gap-[1.042vw] text-white items-center justify-center mb-[7.161vw]">
            <p
              className={`text-[1.563vw] leading-[2.083vw] relative z-[2] flex items-center
              gap-[0.833vw] md:gap-[2.083vw] md:text-[3.125vw] md:leading-[4.427vw]
              xs:gap-[4.267vw] xs:text-[4.533vw] xs:leading-[6.4vw]`}
            >
              <img
                src="/images/landing/mail.svg"
                alt="mail"
                className="w-[1.25vw] h-[1.25vw] md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]"
              />
              info@beezytips.ge
            </p>
            <p
              className={`text-[1.563vw] leading-[2.083vw] relative z-[2] flex items-center
              gap-[0.833vw] md:gap-[2.083vw] md:text-[3.125vw] md:leading-[4.427vw]
              xs:gap-[4.267vw] xs:text-[4.533vw] xs:leading-[6.4vw]`}
            >
              <img
                src="/images/landing/Telegram.svg"
                alt="Telegram"
                className="w-[1.25vw] h-[1.25vw] md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]"
              />
              @beezytipz
            </p>
          </div>
          <a
            href={dashboardLink}
            target="_blank"
            className={`max-w-[43.62vw] bg-phoneOrange font-bold ${hoverEffect} md:pb-[1.497vw] mb-[7.161vw] text-center
            md:text-[2.344vw] md:leading-[3.125vw] md:rounded-[13.021vw] md:pt-[1.367vw] block text-mainBlack
            xs:leading-[6.4vw] xs:text-[4.267vw] xs:max-w-[89.333vw] xs:mb-[11.6vw] md:flex md:items-center md:justify-center
            xs:px-[0] xs:pt-[0] xs:pb-[0] xs:w-[89.333vw] xs:h-[12.8vw] md:px-[0] md:h-[5.99vw]`}
            rel="noreferrer"
          >
            {t('landing.signIn')}
          </a>
          <div className="text-white flex w-[43.62vw] xs:w-[89.333vw] items-center justify-between">
            {languages.map(lng => (
              <button
                key={lng}
                className={`rounded-[13.021vw] font-bold
                text-[2.083vw] leading-[3.125vw] px-[4.883vw] py-[1.432vw] ${
                  lng === i18n.language && 'border-white border-[1px]'
                } xs:leading-[6.4vw] xs:text-[4.267vw] xs:rounded-[26.667vw] xs:py-[2.933vw] xs:px-[10vw]`}
                onClick={changeLang(lng)}
              >
                {lng.toUpperCase()}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
