import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import { TOAST_OPTIONS } from '../../config';
import { ROUTES } from '../../constants/routes';
import { useLoader } from '../../hooks/useLoader';
import { useRedirect } from '../../hooks/useRedirect';
import { useChangePasswordMutation } from '../../redux/api/auth';
import { IUserRestorePasswordResponse } from '../../redux/api/types';
import { IErrorResponse } from '../../types/errors';
import { creteInitialValues } from '../../utils/formik';
import { usePassValidationSchema } from '../../utils/validations/password';
import { Button } from '../../view/Button';
import { FormTextInput } from '../../view/FormTextInput';
import { Loader } from '../Loader';

import { config } from './config';

interface IProps {
  isRestoring: boolean;
  data?: IUserRestorePasswordResponse;
}

export const ChangePasswordForm: FC<IProps> = ({ isRestoring, data }) => {
  const initialValues = useMemo(() => {
    return isRestoring ? creteInitialValues(config.restore) : creteInitialValues(config.profile);
  }, [isRestoring]);
  const [changePassword, { isLoading, isSuccess, error, isError }] = useChangePasswordMutation();
  const { t } = useTranslation();
  const isLoader = useLoader(isLoading);

  useRedirect(false, isLoading, isSuccess, ROUTES.LOGIN);

  const handleSubmit = (value: typeof initialValues) => {
    if (value.newPassword === value.repeatPassword) {
      if (data) {
        changePassword({
          userId: data.id,
          newPassword: value.newPassword,
          isRestoring,
        });
      } else {
        changePassword({
          currentPassword: value.currentPassword,
          newPassword: value.newPassword,
          isRestoring,
        });
      }
    }
  };
  const validationSchema = usePassValidationSchema(isRestoring);

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
  });

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('back.user.update'), TOAST_OPTIONS);
      formik.resetForm();
    }
    if (isError) {
      if (error && (error as IErrorResponse).status === 400) {
        formik.setErrors({ currentPassword: t('back.user.pass') || '' });
      } else {
        toast.error(t('back.user.error'), TOAST_OPTIONS);
      }
    }
  }, [isError, isSuccess, error]);

  return (
    <div
      className={`bg-white ${
        isRestoring
          ? 'w-[35.104vw] border-authBorder border-[1px] md:w-[83.073vw] xs:w-[89.333vw]'
          : 'w-[79.167vw] md:w-[83.073vw] xs:w-[89.333vw]'
      } p-[1.302vw] md:p-[3.255vw] xs:p-[5.333vw] flex flex-col rounded-[0.834vw] shadow-pass md:rounded-[2.083vw] xs:rounded-[4.267vw]
      `}
    >
      {isLoader && <Loader />}
      <div className="mb-[1.25vw] md:mb-[3.125vw] xs:mb-[6.4vw] flex flex-col gap-[0.417vw] md:gap-[1.042vw] xs:gap-[2.133vw]">
        <h2 className="font-bold text-[1.042vw] leading-[1.563vw] text-mainBlack md:text-[2.604vw] md:leading-[3.906vw] xs:text-[4.8vw] xs:leading-[7.467vw]">
          {t('profile.passChange')}
        </h2>
        <p className="fornt-normal text-[0.729vw] leading-[1.042vw] md:text-[1.823vw] md:leading-[2.604vw] xs:text-[3.733vw] xs:leading-[5.333vw]">
          {isRestoring ? t('profile.new') : t('profile.passChangeText')}
        </p>
      </div>
      {Object.entries(config[isRestoring ? 'restore' : 'profile']).map(([key, value]) => {
        if (!isRestoring && key === 'currentPassword') {
          return (
            <div key={key} className="mb-[1.25vw]">
              <FormTextInput
                value={formik.values[key]}
                onChange={formik.handleChange}
                placeholder={t(value.placeholder) || ''}
                id={key}
                name={key}
                error={formik.errors[key]}
                gap="mb-[0.417vw]"
                type="password"
              />
              <span className="text-[0.625vw] leading-[0.938vw] text-textGray md:text-[1.563vw] md:leading-[2.344vw] xs:text-[3.2vw] xs:leading-[4.8vw]">
                {t('profile.passChangeTip')}
              </span>
            </div>
          );
        }
        return (
          <FormTextInput
            value={formik.values[key]}
            onChange={formik.handleChange}
            placeholder={t(value.placeholder) || ''}
            id={key}
            name={key}
            key={key}
            error={formik.errors[key]}
            type="password"
          />
        );
      })}
      <Button
        className="flex self-end bg-textGray hover:bg-textGray px-[1.823vw] md:px-[4.557vw] xs:px-[9.333vw]"
        onClick={formik.submitForm}
      >
        {t('changeBtn')}
      </Button>
    </div>
  );
};
