import { ChangeEvent, FC, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { MODAL_ANCHOR_ID } from '../../../constants/base';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { IMenuCategoryItem } from '../../../redux/api/types/menu';
import { ReactComponent as CloseIcon } from '../../../static/close.svg';
import { Button } from '../../../view/Button';
import { FormTextInput } from '../../../view/FormTextInput';
import FileBlock from '../../QRBody/components/Promo/components/FileBlock';
import { config } from '../config';
import { useLocalFormik } from '../hooks/useLocalFormik';

interface IProps {
  onClose: () => void;
  refetch: () => void;
  currentData?: IMenuCategoryItem;
  maxOrder?: number;
}

const CreateCategoryModal: FC<IProps> = ({ onClose, refetch, currentData, maxOrder }) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, onClose);

  const [file, setFile] = useState<File>();
  const fileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0] as File);
  };

  const formik = useLocalFormik(onClose, refetch, file, currentData, maxOrder);

  return createPortal(
    <div className="absolute top-0 left-0 bottom-0 right-0 z-20 flex items-center justify-center bg-slate-500/50">
      <div
        className={`bg-white p-3 rounded-xl flex flex-col w-[36vw] px-[30px] py-[40px] relative
        md:w-[65vw] xs:w-[90vw]`}
        ref={ref}
      >
        <p className="text-[24px] leading-[34px] text-mainBlack font-bold mb-[24px] xs:text-[20px] xs:leading-[30px]">
          {t('foodMenu.category.modal')}
        </p>
        {Object.entries(config.inputs).map(([key, el]) => (
          <FormTextInput
            type={el.type}
            required={el.required}
            key={key}
            placeholder={t(el.placeholder) as string}
            error={formik.errors[key as keyof typeof formik.initialValues]}
            {...formik.getFieldProps(key)}
          />
        ))}
        <FileBlock
          onChange={fileChange}
          file={file}
          imageFromDB={currentData?.image ? currentData.image : undefined}
          className="mb-[24px]"
          description={t('foodMenu.category.image') as string}
        />
        <Button disabled={!!Object.values(formik.errors).length} onClick={formik.submitForm}>
          {t('saveBtn')}
        </Button>
        <button
          onClick={onClose}
          className="absolute right-[0.642vw] top-[0.642vw] md:right-[2.604vw] md:top-[2.604vw] xs:right-[4.267vw] xs:top-[4.267vw]"
        >
          <CloseIcon
            className="w-[1.25vw] h-[1.25vw] md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]"
            stroke="black"
          />
        </button>
      </div>
    </div>,
    document.getElementById(MODAL_ANCHOR_ID)!
  );
};

export default CreateCategoryModal;
