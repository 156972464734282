import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  handleSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  searchValue: string;
}

const SearchBar: FC<IProps> = ({ searchValue, handleSearch }) => {
  const { t } = useTranslation();

  return (
    <div className="relative">
      <input
        className={`w-full text-[1.042vw]
    leading-[1.563vw] text-mainBlack py-[0.417vw] pl-[2.396vw] pr-[0.729vw]
    border-inputBorder rounded-[0.417vw] border-[1px]
    placeholder:text-textGray focus:outline-none
    md:text-[2.083vw] md:leading-[3.125vw] md:py-[1.042vw] md:pl-[5.99vw] md:pr-[1.823vw] md:rounded-[1.042vw]
    xs:text-[3.733vw] xs:leading-[5.333vw] xs:py-[2.133vw] xs:pl-[12.267vw] xs:pr-[3.733vw] xs:rounded-[2.133vw]`}
        placeholder={t('organizations.search') || ''}
        value={searchValue}
        onChange={handleSearch}
      />
      <img
        src="/images/icons/search.svg"
        alt="search icon"
        className={`w-[1.25vw] h-[1.25vw] absolute top-[0.629vw] left-[0.729vw] xs:top-[1.042vw] xs:left-[3.733vw]
    md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw] md:top-[1.042vw] md:left-[1.823vw]`}
      />
    </div>
  );
};

export default SearchBar;
