import { ChangeEvent, FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useClickOutside } from '../../hooks/useClickOutside';
import { useAddMenuLanguageMutation } from '../../redux/api/menu';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { updateMenuLang } from '../../redux/slices/userSlice';
import { ReactComponent as ArrowDown } from '../../static/arrow_down.svg';
import { Button } from '../Button';
import { FormTextInput } from '../FormTextInput';

interface IProps {
  items: string[];
  refetch: () => void;
}

export const MenuLanguageSelect: FC<IProps> = ({ items, refetch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newLang, setNewLang] = useState('');
  const { menuLang, menuId } = useAppSelector(state => state.user);
  const ref = useRef<HTMLUListElement>(null);
  useClickOutside(ref, () => setIsOpen(false));
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [update] = useAddMenuLanguageMutation();

  const handleClick = () => {
    setIsOpen(prev => !prev);
  };
  const handleLangChange = (lng: string) => () => {
    setIsOpen(false);
    dispatch(updateMenuLang(lng));
  };

  const handleNewLangChange = (e: ChangeEvent<HTMLInputElement>) => setNewLang(e.target.value);

  const handleAddNewLanguage = () =>
    update({ lang: newLang, menuId }).then(() => {
      refetch();
      setIsOpen(false);
      setNewLang('');
      dispatch(updateMenuLang(newLang.toLowerCase()));
    });

  return (
    <div className="flex gap-[10px] items-center text-textGray text-[18px] leading-[28px] xs:text-[14px] xs:leading-[20px] xs:order-1 xs:mb-4">
      <p className="text-[18px] leading-[20px]">{t('foodMenu.language')}</p>
      <div
        className={`rounded-[5.208vw] border-[1px] border-black px-[1.042vw]
      py-[0.573vw] relative z-10 w-min ml-auto
      md:rounded-[13.021vw] md:py-[1.432vw] md:px-[2.604vw] md:w-[12.37vw] md:h-[5.99vw]
      xs:rounded-[26.667vw] xs:px-[5.333vw] xs:py-[2.933vw] xs:w-[25.333vw] xs:h-[12.267vw]`}
      >
        <button className="flex items-center" onClick={handleClick}>
          <p
            className={`font-bold leading-[1.25vw] text-[0.833vw] text-black w-[1.615vw]
          md:text-[2.083vw] md:leading-[3.125vw] md:w-[3.125vw] xs:text-[4.267vw] xs:leading-[6.4vw] xs:w-[6.4vw]`}
          >
            {items.includes(menuLang) ? menuLang.toUpperCase() : ''}
          </p>
          <ArrowDown
            fill="black"
            stroke="black"
            className={`w-[1.25vw] h-[1.25vw] transition-transform ${
              isOpen ? 'rotate-[180deg]' : 'rotate-0'
            } md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]`}
          />
        </button>
        {isOpen && (
          <ul
            ref={ref}
            className={`absolute -bottom-[0.208vw] right-0 translate-y-full flex flex-col gap-[0.417vw]
              w-[7.552vw] bg-white p-[1.042vw] rounded-[1.042vw] cursor-pointer
              border-[1px] border-inputBorder md:w-[18.88vw] md:p-[2.604vw] md:rounded-[2.604vw] md:gap-[1.042vw]
              xs:w-[38.667vw] xs:p-[5.333vw] xs:rounded-[5.333vw] xs:gap-[2.133vw]
            `}
          >
            {items.map(lng => (
              <li
                onClick={handleLangChange(lng)}
                className={`rounded-[2.083vw] ${lng === menuLang && 'bg-blackBg text-white'}
                  pl-[1.042vw] py-[0.26vw] leading-[1.25vw] text-[0.833vw]
                  md:text-[2.083vw] md:leading-[3.125vw] md:rounded-[2.604vw] md:pl-[2.604vw] md:py-[0.651vw]
                  xs:text-[4.267vw] xs:leading-[6.4vw] xs:rounded-[5.333vw] xs:pl-[5.333vw] xs:py-[1.333vw]
                `}
                key={lng}
              >
                {lng.toUpperCase()}
              </li>
            ))}
            <li className="border-t-2 border-black flex flex-col pt-2">
              <FormTextInput value={newLang} onChange={handleNewLangChange} type="text" />
              <Button onClick={handleAddNewLanguage} className="-mt-4">
                {t('foodMenu.addBtn')}
              </Button>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};
