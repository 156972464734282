export const selectDate = [
  {
    title: 'analytic.filterTime1',
    value: 'today',
  },
  {
    title: 'analytic.filterTime2',
    value: 'week',
  },
  {
    title: 'analytic.filterTime3',
    value: 'month',
  },
  {
    title: 'All',
    value: 'all',
  },
];
