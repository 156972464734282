export const BeeAnim = () => {
  return (
    <div className="absolute left-[50.037%] -top-[6.134%] z-[1] w-[37.917vw] h-[37.865vw] md:hidden">
      <img
        src="/images/landing/vector.png"
        alt="vector"
        className="w-[29.782vw] h-[20.938vw] absolute left-[22.17%] top-[23.118%] rotate-[8.26deg]"
      />
      <img
        src="/images/landing/bee.svg"
        alt="bee"
        className="absolute left-[10%] top-[18.741%] w-[6.198vw] h-[6.198vw] rotate-[10deg] animate-bee"
      />
    </div>
  );
};
