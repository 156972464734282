import {
  AFTER_PAY_DATA,
  PAYMENT_CREATE,
  PAYMENT_CREATE_TBC,
  SEND_AFTER_PAY_DATA,
} from '../../constants/api';

import {
  ICreatePaymentPayload,
  IPaymentResponse,
  IRedirectDataResponse,
  ITBCPaymentResponse,
} from './types/payment';
import baseApi from './base';

const paymentApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    createPayment: builder.mutation<IPaymentResponse, ICreatePaymentPayload>({
      query: data => ({
        url: PAYMENT_CREATE,
        method: 'POST',
        body: data,
      }),
    }),
    createTBCPayment: builder.mutation<ITBCPaymentResponse, ICreatePaymentPayload>({
      query: data => ({
        url: PAYMENT_CREATE_TBC,
        method: 'POST',
        body: data,
      }),
    }),
    getAfterPayData: builder.query<IRedirectDataResponse, string>({
      query: id => ({
        url: `${AFTER_PAY_DATA}/${id}`,
      }),
    }),
    sendPromoData: builder.mutation<unknown, FormData>({
      query: data => ({
        url: SEND_AFTER_PAY_DATA,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useCreatePaymentMutation,
  useCreateTBCPaymentMutation,
  useGetAfterPayDataQuery,
  useSendPromoDataMutation,
} = paymentApi;
