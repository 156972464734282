import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import { TOAST_OPTIONS } from '../../../config';
import { useLoader } from '../../../hooks/useLoader';
import { useUpdateCategoryMutation } from '../../../redux/api/menu';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { updateExtraPath } from '../../../redux/slices/userSlice';
import { useAddCategoryValidationSchema } from '../../../utils/validations/menu';

export const useCategoryBlock = (name: string) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>();
  const [preview, setPreview] = useState('');
  const { categoryId } = useParams();
  const { menuLang } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [update, { isLoading, data }] = useUpdateCategoryMutation();

  const formik = useFormik({
    initialValues: { name },
    onSubmit: () => {},
    validationSchema: useAddCategoryValidationSchema(),
  });

  const updateCategory = () => {
    const formData = new FormData();

    formData.append('id', categoryId as string);
    formData.append('lang', menuLang);
    formData.append('name', formik.values.name);

    if (file) {
      formData.append('file', file);
    }

    formik.submitForm().then(() => {
      update(formData).then(() => {
        toast.success(t('foodMenu.toast.categoryUpdated'), TOAST_OPTIONS);
      });
    });
  };

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = fileData => {
        setPreview(fileData.target?.result as string);
      };
      updateCategory();
    } else {
      setPreview('');
    }
  }, [file]);

  useEffect(() => {
    if (data && data.data.name) {
      dispatch(updateExtraPath(data.data.name));
    }
  }, [data]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (name !== formik.values.name) {
        updateCategory();
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [formik.values.name]);

  useEffect(() => {
    formik.setFieldValue('name', name);
  }, [name]);

  const fireInput = () => {
    inputRef.current?.click();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputFile = e.target.files?.[0];

    if (inputFile) {
      setFile(inputFile);
    }
  };

  const isLoader = useLoader(isLoading);

  return {
    preview,
    fireInput,
    handleFileChange,
    inputRef,
    isLoader,
    formik,
  };
};
