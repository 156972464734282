import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '../../constants/routes';
import { AuthTemplate } from '../../layouts/AuthTemplate';
import { ReactComponent as CloseIcon } from '../../static/close.svg';
import { Button } from '../../view/Button';

export const UserReview = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => navigate(ROUTES.HOME);

  return (
    <AuthTemplate>
      <div
        className={`
          w-[30.104vw] border-authBorder shadow-auth border-[1px] flex flex-col relative p-[2.083vw] rounded-[0.625vw] gap-[1.563vw]
          md:w-[72.756vw] md:p-[5.208vw] md:rounded-[1.563vw] md:gap-[3.906vw]
          xs:w-[89.067vw] md:p-[10.667vw] md:rounded-[3.2vw] xs:gap-[8vw]
        `}
      >
        <p
          className={`
            font-bold text-mainBlack text-[1.24vw] leading-[1.771vw] text-center
            md:text-[3.125vw] md:leading-[4.427vw] xs:text-[6.4vw] xs:leading-[9.067vw]
          `}
        >
          {t('confirmation.onReview')}
        </p>
        <Button onClick={handleClick}>{t('confirmation.btn')}</Button>
        <button
          onClick={handleClick}
          className="absolute top-[1.042vw] right-[1.042vw] md:top-[2.604vw] md:right-[2.604vw] xs:top-[0.533vw] xs:right-[0.533vw]"
        >
          <CloseIcon
            className={`w-[1.25vw] h-[1.25vw] 
            md:w-[3.125vw] md:h-[3.125vw] 
            xs:w-[6.4vw] xs:h-[6.4vw] `}
            stroke="black"
          />
        </button>
      </div>
    </AuthTemplate>
  );
};
