import { NewRequestBody } from '../../components/NewRequestBody';
import { DashboardTemplate } from '../../layouts/DashboradTemplate';

export const NewRequest = () => {
  return (
    <DashboardTemplate>
      <NewRequestBody />
    </DashboardTemplate>
  );
};
