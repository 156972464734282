import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { API_URL } from '../../../../constants/api';
import { IPaymentDataResponse } from '../../../../redux/api/types/user';

interface IProps {
  handleUserClick: (id: number) => () => void;
  data?: IPaymentDataResponse;
  activeUser: number;
}

const UsersBlock: FC<IProps> = ({ handleUserClick, data, activeUser }) => {
  const { t } = useTranslation();
  const currentUser = data && data.employees.find(e => e.id === activeUser);

  return (
    <div className="w-full">
      <p className="text-white pl-[4.267vw] mb-[3.733vw] text-[4.267vw] leading-[6.4vw]">
        {t('pay.to')}
      </p>
      <div className="flex items-center gap-[4vw] overflow-auto scrollbar-hide px-[4vw]">
        {data &&
          data.employees &&
          !!data.employees.length &&
          data.employees.map(user => (
            <div key={user.id} onClick={handleUserClick(user.id)}>
              <div
                className={`w-[28.8vw] h-[28.8vw] rounded-full bg-textGray mb-[1.067vw] flex items-center justify-center ${
                  user.id === activeUser && 'border-4 border-mainOrange drop-shadow-orange'
                }`}
              >
                {user.image ? (
                  <img
                    src={`${API_URL}/image/${user.image}`}
                    className="w-full h-full rounded-full"
                    alt="user avatar"
                  />
                ) : (
                  <p className="text-white text-[10vw]">{user.name?.[0] || data.organization[0]}</p>
                )}
              </div>
              <p className="w-[28.8vw] text-white text-center text-[4.267vw] leading-[6.4vw] text-ellipsis overflow-hidden whitespace-nowrap">
                {user.id === data.organizationId ? data.organization : user.name}
              </p>
            </div>
          ))}
      </div>
      {currentUser && currentUser.wish && (
        <p className="text-white pl-[4.267vw] pt-2 text-[3.267vw] leading-[6.4vw]">
          {t('pay.wish')} {currentUser.wish}
        </p>
      )}
    </div>
  );
};

export default UsersBlock;
