import { QRBody } from '../../components/QRBody';
import { DashboardTemplate } from '../../layouts/DashboradTemplate';

export const QRCode = () => {
  return (
    <DashboardTemplate>
      <QRBody />
    </DashboardTemplate>
  );
};
