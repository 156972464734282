import { Dispatch, FC, SetStateAction, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useClickOutside } from '../../../hooks/useClickOutside';
import { useDisclosure } from '../../../hooks/useDisclosure';
import { useUpdateMenuActiveLanguagesMutation } from '../../../redux/api/menu';
import { useAppSelector } from '../../../redux/hooks';
import { ReactComponent as ArrowDown } from '../../../static/arrow_down.svg';

interface IProps {
  refetch: () => void;
  languages: string[];
  setActiveLanguages: Dispatch<SetStateAction<string[]>>;
  activeLanguages: string[];
}

const VisibleLanguageSelect: FC<IProps> = ({
  setActiveLanguages,
  languages,
  activeLanguages,
  refetch,
}) => {
  const { isOpen, close, open } = useDisclosure();
  const { menuId } = useAppSelector(state => state.user);
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, close);
  const [update] = useUpdateMenuActiveLanguagesMutation();

  const onUpdate = (langs: string[]) => {
    const formData = new FormData();
    formData.append('langs', langs.join(','));
    formData.append('menuId', `${menuId}`);

    update(formData).then(refetch);
  };

  const handleChange = (selected: string) => () => {
    setActiveLanguages(prev => {
      const ind = prev.findIndex(e => e === selected);
      if (ind < 0) {
        if (prev.length >= 6) return prev;
        onUpdate([...prev, selected].filter(el => el));
        return [...prev, selected].filter(el => el);
      }
      const copy = [...prev];
      copy.splice(ind, 1);
      onUpdate(copy);
      return copy;
    });
  };

  return (
    <div className="flex items-center gap-[10px] relative">
      <p className="text-[18px] leading-[20px]">{t('foodMenu.language')}</p>
      <div
        className={`px-[1.042vw] py-[0.573vw] shadow-pass rounded-[0.417vw] relative
    md:w-[40.234vw] md:px-[2.604vw] md:py-[1.432vw] md:rounded-[1.042vw] md:mb-[1vw]
    xs:w-full xs:px-[5.333vw] xs:py-[2.933vw] xs:rounded-[2.133vw] xs:mb-[2vw]`}
      >
        <button className="flex items-center h-full justify-between" onClick={open}>
          <p
            className={`font-bold leading-[1.25vw] text-[0.833vw] text-mainBlack text-ellipsis overflow-hidden whitespace-nowrap
        md:text-[2.083vw] md:leading-[3.125vw] xs:text-[4.267vw] xs:leading-[6.4vw] w-[50px]`}
          >
            {activeLanguages.map(item => item.toUpperCase()).join(', ')}
          </p>
          <ArrowDown
            className={`w-[1.25vw] h-[1.25vw] transition-transform ${
              isOpen ? 'rotate-[180deg]' : 'rotate-0'
            } md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]`}
            stroke="black"
            fill="black"
            style={{ '&>path': { fill: 'black' } }}
          />
        </button>
      </div>
      {isOpen && (
        <div
          className={` bg-white shadow-pass rounded-[0.417vw] z-50 absolute right-0 top-14
          md:rounded-[1.042vw] xs:rounded-[2.133vw] xs:mb-[2vw] md:mb-[1vw] mb-[0.4vw]
          px-[0.833vw] md:px-[2.083vw] xs:px-[3.2vw]`}
          ref={ref}
        >
          <ul className="max-h-[18.229vw] md:max-h-[45.573vw] xs:max-h-[93.333vw] overflow-auto">
            {languages.map(item => (
              <li
                key={item}
                onClick={handleChange(item)}
                className={`cursor-pointer md:text-[2.083vw] text-mainBlack text-ellipsis overflow-hidden whitespace-nowrap
                  md:leading-[3.125vw] xs:text-[4.267vw] xs:leading-[6.4vw] py-[0.573vw] px-[0.5vw]
                  md:px-[2.604vw] md:py-[1.432vw] xs:px-[5.333vw] xs:py-[2.933vw] rounded-[0.625vw] gap-[5px]
                  hover:bg-overlay flex justify-between items-center md:rounded-[1.563vw] xs:rounded-[3.2vw]
                `}
              >
                <div className="flex items-center gap-[0.417vw] md:gap-[1.042vw] xs:gap-[2.133vw]">
                  {item.toUpperCase()}
                </div>
                <input
                  type="checkbox"
                  className="checkbox checkbox-warning checkbox-sm"
                  checked={activeLanguages.includes(item)}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default VisibleLanguageSelect;
