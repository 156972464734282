import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import { TOAST_OPTIONS } from '../../config';
import { ROUTES } from '../../constants/routes';
import { useLoginUserMutation } from '../../redux/api/auth';
import { useAppSelector } from '../../redux/hooks';
import { IErrorResponse } from '../../types/errors';
import { creteInitialValues } from '../../utils/formik';
import { useLoginValidationSchema } from '../../utils/validations/login';
import { Button } from '../../view/Button';
import { Checkbox } from '../../view/Checkbox';
import { FormTextInput } from '../../view/FormTextInput';

import { config } from './config';

export const LoginForm = () => {
  const [loginUser, { isLoading, isSuccess, isError, error }] = useLoginUserMutation();
  const { id } = useAppSelector(state => state.user);
  const navigate = useNavigate();
  const validationSchema = useLoginValidationSchema();
  const [remember, setRemember] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const correctLang =
      i18n.language.split('-').length > 1 ? i18n.language.split('-')[0] : i18n.language;
    i18n.changeLanguage(correctLang);
  }, []);

  useEffect(() => {
    if (!isLoading && isSuccess && id) {
      navigate(`${ROUTES.DASHBOARD}/${id}${ROUTES.PROFILE}${ROUTES.PROFILE}`);
    }
  }, [isLoading, isSuccess, id]);

  const initialValues = useMemo(() => creteInitialValues(config), []);
  const handleFormSubmit = (values: typeof initialValues) => {
    loginUser({ email: values.email, password: values.password, remember });
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
    validationSchema: validationSchema,
  });

  useEffect(() => {
    if (error) {
      const { status } = error as IErrorResponse;
      if (status === 'FETCH_ERROR') {
        return;
      }
      if ((error as IErrorResponse).status === 404) {
        formik.setErrors({ email: t('errors.email.exist') || '' });
      } else if ((error as IErrorResponse).status === 403) {
        toast.error(t('back.user.permission'), { ...TOAST_OPTIONS, position: 'top-center' });
      } else if ((error as IErrorResponse).status === 423) {
        toast.error(t('back.user.email'), { ...TOAST_OPTIONS, position: 'top-center' });
      } else {
        formik.setErrors({ password: t('errors.pass.incorrect') || '' });
      }
    }
  }, [isError]);

  const toggleCheckbox = () => setRemember(prev => !prev);

  return (
    <div
      className={`w-[35.104vw] p-[2.084vw] border-[1px] bg-white
      rounded-[0.834vw] border-authBorder flex flex-col shadow-auth
      md:w-[87.76vw] md:p-[5.208vw] md:rounded-[2.083vw]
      xs:w-[89.333vw] xs:p-[5.333vw] xs:rounded-[4.267vw]`}
    >
      <div className="flex items-center justify-between mb-[1.25vw] md:mb-[3.125vw] xs:mb-[6.4vw]">
        <p
          className={`font-bold text-[1.25vw] leading-[1.771vw]
          md:text-[3.125vw] md:leading-[4.427vw] xs:text-[4.8vw] xs:leading-[7.467vw]`}
        >
          {t('auth.entrance')}
        </p>
        <button
          className={`border-[1px] border-inputBorder rounded-[0.417vw]
          py-[0.417vw] px-[0.833vw] text-[0.729vw] leading-[1.042vw]
          md:rounded-[1.042vw] md:py-[1.042vw] md:px-[2.083vw] md:text-[1.823vw] md:leading-[2.604vw]
          xs:rounded-[2.113vw] xs:py-[2.113vw] xs:px-[4.267vw] xs:text-[3.733vw] xs:leading-[5.333vw]
          `}
        >
          <Link to={ROUTES.REGISTER} className="text-authActive">
            {t('auth.register_link')}
          </Link>
        </button>
      </div>
      {Object.entries(config).map(([key, value], i) => (
        <FormTextInput
          value={formik.values[key]}
          onChange={formik.handleChange}
          key={key}
          placeholder={t(value.placeholder) || ''}
          required={value.required}
          id={key}
          name={key}
          error={formik.errors[key]}
          isTouched={formik.touched[key]}
          last={i === Object.entries(config).length - 1}
          type={value.type}
        />
      ))}
      <div className="flex flex-col gap-[0.833vw] md:gap-[2.083vw] xs:gap-[4.267vw]">
        <div className="flex items-center justify-between">
          <Checkbox
            labelText={t('auth.rememberMe') || ''}
            checked={remember}
            toggle={toggleCheckbox}
            name="remember"
          />
          <button
            className={`text-mainOrange text-[0.729vw] font-bold leading-[1.25vw]
            md:text-[1.823vw] md:leading-[3.125vw] xs:text-[3.733vw] xs:leading-[6.4vw]`}
          >
            <Link to={ROUTES.SEND_PASS_RESTORE}>{t('auth.forgotPass')}</Link>
          </button>
        </div>
        <Button onClick={formik.submitForm} className="">
          {t('auth.login')}
        </Button>
      </div>
    </div>
  );
};
