import { ChangeEvent, Dispatch, FC, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  feedback: string;
  setFeedback: Dispatch<SetStateAction<string>>;
}

export const Feedback: FC<IProps> = ({ feedback, setFeedback }) => {
  const [textView, setTextView] = useState(false);
  const handleBtnClick = () => setTextView(true);
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => setFeedback(e.target.value);
  const { t } = useTranslation();

  return (
    <div className="w-full px-[4vw]">
      {textView ? (
        <div className="w-[92vw] bg-white flex flex-col px-[3.733vw] py-[3.2vw] rounded-[3.2vw]">
          <textarea
            className={`
              focus:outline-none w-full border-b-[1px] border-textGray resize-none text-[3.733vw] leading-[5.333vw]
              placeholder:font-bold placeholder:text-[4.267vw] placeholder:leading-[6.4vw] placeholder:text-midGray mb-[2.667vw]
            `}
            placeholder={t('pay.feedbackInput') || ''}
            value={feedback}
            onChange={handleChange}
          />
          <p className="text-[3.2vw] leading-[4.8vw]">{t('pay.phone')}</p>
        </div>
      ) : (
        <button
          className={`
            btn flex items-center gap-[3.2vw] w-[92vw] h-[11.733vw]
            bg-mainYellow hover:bg-mainYellow rounded-[3.2vw] justify-center
            font-medium text-[4.267vw] leading-[6.4vw] text-mainBlack
          `}
          onClick={handleBtnClick}
        >
          {t('pay.feedback')}
          <img
            src="/images/icons/feedback.svg"
            alt="feedback icon"
            className="w-[7.467vw] h-[7.467vw]"
          />
        </button>
      )}
    </div>
  );
};
