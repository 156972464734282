import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { emailSchema, passwordSchema } from './base';

export const useLoginValidationSchema = () => {
  const { t } = useTranslation();

  const schema = yup.object({
    email: emailSchema({
      required: t('errors.email.required'),
      length: t('errors.email.length'),
      invalid: t('errors.email.invalid'),
    }),
    password: passwordSchema({
      space: t('errors.pass.space'),
      required: t('errors.pass.required'),
      chars: t('errors.pass.chars'),
      length: t('errors.pass.min'),
    }),
  });

  return schema;
};
