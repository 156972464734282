export const selectDate = [
  {
    title: 'analytic.filterTime1',
    value: 'today',
  },
  {
    title: 'analytic.filterTime2',
    value: 'week',
  },
  {
    title: 'analytic.filterTime3',
    value: 'month',
  },
];
export const selectReceiver = [
  {
    title: 'analytic.filterPerson',
    value: 'all',
  },
];

export const cards = [
  {
    title: 'rating.ratingAvg',
    tooltipText: 'rating.ratingAvgTip',
    key: 'averageRating',
  },
  {
    title: 'rating.ratesAmount',
    tooltipText: 'rating.ratingAmountTip',
    key: 'transactions',
  },
];

export const markItems = ['clean', 'service', 'food', 'interior', 'music', 'politeness'];
