import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { ROUTES } from '../../constants/routes';
import { useAppSelector } from '../../redux/hooks';
import { ReactComponent as CloseIcon } from '../../static/close.svg';
import { Button } from '../../view/Button';

export const NotConfirmedUser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useAppSelector(state => state.user);
  const handleClick = () => navigate(`${ROUTES.DASHBOARD}/${id}${ROUTES.PROFILE}${ROUTES.PROFILE}`);

  return (
    <div className="w-full h-full flex items-center justify-center">
      <div
        className={`
            w-[30.104vw] border-authBorder shadow-auth border-[1px] flex flex-col relative p-[2.083vw] rounded-[0.625vw] gap-[1.563vw]
            md:w-[72.756vw] md:p-[5.208vw] md:rounded-[1.563vw] md:gap-[3.906vw]
            xs:w-[89.067vw] md:p-[10.667vw] md:rounded-[3.2vw] xs:gap-[8vw]
          `}
      >
        <div>
          <p
            className={`
              font-bold text-mainBlack text-[1.24vw] leading-[1.771vw] text-center mb-[0.833vw]
              md:text-[3.125vw] md:leading-[4.427vw] xs:text-[6.4vw] xs:leading-[9.067vw] md:mb-[2.083vw] xs:mb-[4.267vw]
            `}
          >
            {t('confirmation.onReview2')}
          </p>
          <p className="text-[0.833vw] leading-[1.25vw] md:text-[2.083vw] md:leading-[3.125vw] xs:text-[3.733vw] xs:leading-[5.333vw] text-textGray text-center">
            {t('confirmation.onReviewText')}
          </p>
        </div>
        <Button onClick={() => {}}>
          <Link
            className={`w-full font-bold text-[1.042vw] leading-[1.563vw]
            md:text-[2.604vw] md:leading-[3.906vw] xs:text-[5.333vw] xs:leading-[8vw]`}
            to={`${ROUTES.DASHBOARD}/${id}${ROUTES.PROFILE}${ROUTES.PROFILE}`}
          >
            {t('confirmation.btn')}
          </Link>
        </Button>
        <button
          onClick={handleClick}
          className="absolute top-[1.042vw] right-[1.042vw] md:top-[2.604vw] md:right-[2.604vw] xs:top-[0.533vw] xs:right-[0.533vw]"
        >
          <CloseIcon
            className={`w-[1.25vw] h-[1.25vw] 
            md:w-[3.125vw] md:h-[3.125vw] 
            xs:w-[6.4vw] xs:h-[6.4vw] `}
            stroke="black"
          />
        </button>
      </div>
    </div>
  );
};
