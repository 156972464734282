import { FC, PropsWithChildren } from 'react';

interface IProps {
  className?: string;
}

const MenuHeader: FC<PropsWithChildren<IProps>> = ({ className, children }) => {
  return (
    <header
      className={`p-[4vw] flex justify-between items-start relative z-10 h-[100px] ${className}`}
    >
      {children}
    </header>
  );
};

export default MenuHeader;
