import { FC } from 'react';

interface IProps {
  title: string;
  className?: string;
}

const DropdownLabel: FC<IProps> = ({ title, className }) => {
  return (
    <p
      className={`px-[11px] py-[2px] whitespace-nowrap rounded-[8px] text-[16px] leading-[24px] bg-mainYellow ${className}`}
    >
      {title}
    </p>
  );
};

export default DropdownLabel;
