import { FC, ReactNode } from 'react';

interface IProps {
  children: ReactNode;
  className?: string;
}

export const Main: FC<IProps> = ({ children, className }) => {
  return <main className={`bg-white ${className || ''}`}>{children}</main>;
};
