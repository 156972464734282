import { FC, PropsWithChildren } from 'react';

interface IProps {
  onClick: () => void;
  isActive: boolean;
}

const Button: FC<PropsWithChildren<IProps>> = ({ children, onClick, isActive }) => {
  return (
    <button
      className={`
        w-[35px] h-[35px] rounded-[5px]
        ${isActive ? 'bg-mainOrange text-white' : 'bg-transparent text-mainBlack'}
      `}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
