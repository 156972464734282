import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../constants/routes';
import { useLoader } from '../../hooks/useLoader';
import { useGetUserReviewQuery } from '../../redux/api/reviews';
import { useGetUserEmployyesQuery } from '../../redux/api/user';
import { useAppSelector } from '../../redux/hooks';
import { ReviewKeyType } from '../../types/base';
import { Card } from '../../view/Card';
import { Select } from '../../view/Select';
import { Loader } from '../Loader';

import { EmployeeCard } from './components/EmployeeCard';
import { FeedbackItem } from './components/FeedbackItem';
import { cards, selectDate, selectReceiver } from './config';
import { getServiceTotalAmount } from './helpers';

export const RatingBody = () => {
  const { id } = useAppSelector(state => state.user);
  const [period, setPeriod] = useState(selectDate[0].value);
  const [receiver, setReceiver] = useState(selectReceiver[0].value);
  const { data, isLoading } = useGetUserReviewQuery(
    { id, period, receiver },
    {
      refetchOnMountOrArgChange: true,
      skip: !id,
    }
  );
  const {
    data: employees,
    refetch,
    isLoading: isLoadingEmployee,
    isFetching,
  } = useGetUserEmployyesQuery(id, {
    refetchOnMountOrArgChange: true,
    skip: !id,
  });
  const { t } = useTranslation();
  const isLoader = useLoader(isLoading, isLoadingEmployee, isFetching);

  const receiverSelectData = useMemo(() => {
    if (employees) {
      return [
        ...selectReceiver,
        ...employees.map(e => ({
          title: `${e.lastName} ${e.firstName}`,
          value: e.id.toString(),
        })),
      ];
    } else {
      return selectReceiver;
    }
  }, [employees]);

  const sortedData = useMemo(() => {
    if (data) {
      return [...data?.negative, ...data?.positive].sort(
        (a, b) => new Date(b.reviewDate).getTime() - new Date(a.reviewDate).getTime()
      );
    }
    return [];
  }, [data]);

  const handlePeriodChange = (value: string) => setPeriod(value);
  const handleReceiverChange = (value: string) => setReceiver(value);

  return (
    <div className="bg-white px-[2.604vw] pt-[2.083vw] pb-[1.203vw] xs:px-[5.333vw] md:px-[3.255vw] relative">
      <div className="flex gap-[0.842vw] h-full md:flex-col md:gap-[2.604vw] md:mb-[3.906vw] xs:gap-[4.267vw]">
        <div>
          <div className="flex items-center gap-[1.094vw] md:gap-[2.604vw] mb-[1.354vw] md:mb-[2.604vw] xs:gap-[3.2vw] xs:mb-[5.333vw] xs:flex-col">
            <Select options={selectDate} onChange={handlePeriodChange} />
            <Select options={receiverSelectData} onChange={handleReceiverChange} />
          </div>
          <div
            className={`flex items-center gap-[1.042vw] mb-[1.563vw] md:gap-0
            md:mb-0 xs:mb-[6.4vw] md:flex-wrap xs:flex-wrap xs:justify-center
            md:justify-between xs:gap-[4.267vw] xs:mb-0`}
          >
            {cards.map(el => (
              <Card
                tooltipText={t(el.tooltipText) || ''}
                title={t(el.title) || ''}
                key={el.title}
                className="flex flex-col justify-between"
              >
                <p className="font-bold text-[1.563vw] leading-[2.083vw] text-end md:text-[3.125vw] md:leading-[4.427vw] xs:text-[6.4vw] xs:leading-[9.067vw]">
                  {data?.[el.key as ReviewKeyType]}
                </p>
              </Card>
            ))}
          </div>
        </div>
        <div className="h-full flex gap-[1.042vw] md:gap-[2.604vw] xs:flex-col">
          <Card
            title={t('rating.positive') || ''}
            className="flex flex-col gap-[1.563vw] !h-[11.354vw] md:gap-[3.906vw] md:!h-[28.385vw] xs:!h-[50.4vw] xs:gap-[4.267vw]"
          >
            {!!data?.positive.length ? (
              <div className="flex items-center gap-[1.198vw] gap-y-[1.563vw] md:gap-[1.497vw] md:gap-y-[2.604vw] flex-wrap xs:gap-[5.6vw] xs:gap-y-[5.867vw]">
                {data.marks &&
                  data.marks.map(item => (
                    <div className="flex items center gap-[0.313vw]" key={item}>
                      <img
                        src={`/images/icons/${item}.svg`}
                        alt={`${item} icon`}
                        className="w-[2.083vw] h-[2.083vw] md:w-[5.208vw] md:h-[5.208vw] xs:w-[10.667vw] xs:h-[10.667vw]"
                      />
                      <p
                        className={`w-[2.083vw] h-[2.083vw] rounded-[50%] font-medium
                        text-mainBlack text-[0.833vw] leading-[1.25vw] flex items-center
                        justify-center bg-mainYellow
                        md:w-[5.208vw] md:h-[5.208vw] xs:w-[10.667vw] xs:h-[10.667vw]
                        md:text-[2.083vw] md:leading-[3.125vw] xs:text-[4.267vw] xs:leading-[6.4vw]`}
                      >
                        x<span>{getServiceTotalAmount(data.positive, item)}</span>
                      </p>
                    </div>
                  ))}
              </div>
            ) : (
              <p className="font-medium text-[0.833vw] leading-[1.25vw] text-lowGray text-center mb-[0.625vw] md:text-[1.823vw] md:leading-[2.604vw] xs:text-[3.733vw] xs:leading-[5.333vw]">
                {t('rating.noRates')}
              </p>
            )}
          </Card>
          <Card
            title={t('rating.negative') || ''}
            className="flex flex-col gap-[1.563vw] !h-[11.354vw] md:gap-[3.906vw] md:!h-[28.385vw] xs:!h-[50.4vw] xs:gap-[4.267vw]"
          >
            {!!data?.negative.length ? (
              <div className="flex items-center gap-[1.198vw] gap-y-[1.563vw] flex-wrap md:gap-[1.497vw] md:gap-y-[2.604vw] flex-wrap xs:gap-[5.6vw] xs:gap-y-[5.867vw]">
                {data.marks &&
                  data.marks.map(item => (
                    <div className="flex items center gap-[0.313vw]" key={item}>
                      <img
                        src={`/images/icons/${item}.svg`}
                        alt={`${item} icon`}
                        className="w-[2.083vw] h-[2.083vw] md:w-[5.208vw] md:h-[5.208vw] xs:w-[10.667vw] xs:h-[10.667vw]"
                      />
                      <p
                        className={`w-[2.083vw] h-[2.083vw] rounded-[50%] font-medium
                        text-mainBlack text-[0.833vw] leading-[1.25vw] flex items-center
                        justify-center bg-mainYellow
                        md:w-[5.208vw] md:h-[5.208vw] xs:w-[10.667vw] xs:h-[10.667vw]
                        md:text-[2.083vw] md:leading-[3.125vw] xs:text-[4.267vw] xs:leading-[6.4vw]`}
                      >
                        x<span>{getServiceTotalAmount(data.negative, item)}</span>
                      </p>
                    </div>
                  ))}
              </div>
            ) : (
              <p className="font-medium text-[0.833vw] leading-[1.25vw] text-lowGray text-center mb-[0.625vw] md:text-[1.823vw] md:leading-[2.604vw] xs:text-[3.733vw] xs:leading-[5.333vw]">
                {t('rating.noRates')}
              </p>
            )}
          </Card>
        </div>
      </div>
      <div
        className={`w-full p-[1.302vw] flex flex-col gap-[1.042vw] shadow-pass rounded-[0.625vw]
        h-[21.615vw] mb-[1.563vw] md:rounded-[1.563vw] xs:rounded-[3.2vw]
        md:h-[56vw] md:mb-[3.906vw] md:p-[2.604vw] md:gap-[5.208vw]
        xs:h-[181.333vw] xs:mb-[5.333vw] xs:p-[5.333vw] xs:gap-[4.533vw]`}
      >
        <p className="text-mainBlack text-[1.25vw] leading-[1.771vw] md:text-[2.604vw] md:leading-[3.906vw] xs:text-[5.333vw] xs:leading-[8vw]">
          {t('rating.feedback')}
        </p>
        <div className="flex flex-col gap-[1.041vw] overflow-auto max-h-full pr-[0.833vw] md:gap-[1.563vw] xs:gap-[3.2vw]">
          {data && !!sortedData.length ? (
            sortedData.map(el => <FeedbackItem feedback={el} key={el.id} />)
          ) : (
            <p className="text-lowGray text-[1.25vw] leading-[1.771vw] w-full text-center md:text-[2.083vw] md:leading-[3.125vw] xs:text-[4.267vw] xs:leading-[6.4vw]">
              {t('rating.noFeedbacks')}
            </p>
          )}
        </div>
      </div>
      <div className="overflow-auto">
        <div className="flex items-start gap-[0.938vw] md:gap-[3.385vw] xs:flex-col xs:gap-[4.267vw]">
          <p className="font-bold text-[1.25vw] leading-[1.771vw] text-mainBlack  md:text-[2.604vw] md:leading-[3.906vw] xs:text-[5.333vw] xs:leading-[8vw]">
            {t('rating.employees')}
          </p>
          <div className="flex items-start gap-[0.833vw] md:gap-[2.083vw] xs:gap-[4.267vw] pb-[0.5vw] md:pb-[1.2vw] xs:pb-[2vw]">
            <Link
              to={`${ROUTES.DASHBOARD}/${id}${ROUTES.RATING}${ROUTES.ADD_EMPLOYEE}`}
              className={`flex flex-col items-center gap-[0.521vw] w-[5.677vw]
            md:gap-[1.302vw] md:w-[14.193vw] xs:w-[29.067vw] xs:gap-[2.667vw]`}
            >
              <div
                className={`w-[3.646vw] h-[3.646vw] flex items-center justify-center bg-midGray rounded-[50%]
              md:w-[9.115vw] md:h-[9.115vw] xs:w-[18.667vw] xs:h-[18.667vw]`}
              >
                <img
                  src="/images/icons/plus.svg"
                  alt="plus icon"
                  className="w-[1.25vw] h-[1.25vw] md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]"
                />
              </div>
              <p className="font-medium max-w-[80%] text-[0.625vw] leading-[0.938vw] text-center md:text-[1.563vw] md:leading-[2.344vw] xs:text-[3.2vw] xs:leading-[4.8vw]">
                {t('rating.addEmployee')}
              </p>
            </Link>
            {employees?.map((el, i) => (
              <EmployeeCard key={i} data={el} refetch={refetch} />
            ))}
          </div>
        </div>
      </div>
      {isLoader && <Loader />}
    </div>
  );
};
