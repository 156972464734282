import { ROUTES } from '../../constants/routes';

export const config = {
  analytics: 'menu.analytic',
  rating: 'menu.rating',
  qr: 'menu.qr',
  profile: 'menu.profile',
  security: 'menu.profile',
  employee: 'employee.title',
  organizations: 'menu.organizations',
  'new-requests': 'menu.new',
  menu: 'menu.menu',
  transactions: 'menu.transactions',
} as const;

export const links = {
  base: [
    {
      text: 'menu.analytic',
      icon: 'analytics',
      to: (id: number) => `${ROUTES.DASHBOARD}/${id}${ROUTES.ANALYTICS}`,
    },
    {
      text: 'menu.rating',
      icon: 'rating',
      to: (id: number) => `${ROUTES.DASHBOARD}/${id}${ROUTES.RATING}`,
    },
    {
      text: 'menu.qr',
      icon: 'qr-code',
      to: (id: number) => `${ROUTES.DASHBOARD}/${id}${ROUTES.QR_CODE}`,
    },
    {
      text: 'menu.menu',
      icon: 'file',
      to: (id: number) => `${ROUTES.DASHBOARD}/${id}${ROUTES.MENU}`,
    },
  ],
  admin: [
    {
      text: 'menu.organizations',
      icon: 'house',
      to: (id: number) => `${ROUTES.DASHBOARD}/${id}${ROUTES.ORGANIZATION}`,
    },
    {
      text: 'menu.new',
      icon: 'file',
      badge: true,
      to: (id: number) => `${ROUTES.DASHBOARD}/${id}${ROUTES.NEW_REQUEST}`,
    },
    {
      text: 'menu.transactions',
      icon: 'analytics',
      to: () => `${ROUTES.TRANSACTIONS}`,
    },
  ],
};
