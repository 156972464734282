import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../../../constants/routes';
import { dashboardLink } from '../../config';
import { hoverEffect } from '../share/classes';
import { Navbar } from '../share/Navbar';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="w-full bg-[#151822]">
      <div className="w-full flex items-center justify-center h-[6.094vw] md:h-[77.604vw] md:py-[7.813vw] xs:pt-[14.667vw] xs:pb-[16vw] xs:h-[149.6vw]">
        <div className="flex w-[71.198vw] items-center md:flex-col md:items-center md:w-full md:justify-between md:h-full justify-between">
          <Link to={ROUTES.LANDING}>
            <img
              src="/images/logo.png"
              alt="main logo"
              className="w-[5.104vw] h-[2.604vw] md:w-[12.76vw] md:h-[6.51vw] md:mt-0 md:mr-0 xs:w-[26.133vw] xs:h-[13.333vw]"
            />
          </Link>
          <Navbar mr="md:mr-0" className="" />
          <a
            href={dashboardLink}
            target="_blank"
            className={`px-[1.823vw] pt-[0.547vw] pb-[0.599vw] bg-phoneOrange block text-mainBlack
              rounded-[5.208vw] font-bold text-[0.938vw] leading-[1.25vw] md:rounded-[13.021vw]
              ${hoverEffect} md:text-[2.083vw] md:leading-[3.125vw] md:px-[8.529vw] md:pt-[1.367vw] md:pb-[1.497vw]
              xs:text-[4.267vw] xs:leading-[6.4vw] xs:rounded-[26.667vw] xs:px-[0] xs:pt-[0] xs:pb-[0] xs:w-[89.333vw]
              xs:h-[12.8vw] xs:flex xs:items-center xs:justify-center
            `}
            rel="noreferrer"
          >
            {t('landing.signIn')}
          </a>
        </div>
      </div>
      <div className="h-[1px] w-full bg-white opacity-10" />
      <div className="h-[5.313vw] md:h-[14.844vw] flex items-center justify-center w-full md:items-start xs:h-[30.4vw]">
        <div className="flex justify-between w-[71.198vw] md:flex-col md:items-center md:pt-[2.474vw] md:gap-[2.083vw] xs:pt-[5.067vw] xs:gap-[4.267vw]">
          <p className="text-[0.833vw] text-white leading-[1.25vw] md:text-[2.083vw] md:leading-[3.125vw] xs:text-[4.267vw] xs:leading-[6.4vw]">
            {t('landing.footer')}
          </p>
          <div className="flex gap-[1.302vw] md:gap-[3.255vw] xs:gap-[6.667vw]">
            <img
              src="/images/landing/visa.svg"
              alt="visa"
              className="w-[2.344] h-[1.563vw] md:w-[5.859vw] md:h-[3.906vw] xs:w-[12vw] xs:h-[8vw]"
            />
            <img
              src="/images/landing/mastercard.svg"
              alt="mastercard"
              className="w-[2.344] h-[1.563vw] md:w-[5.859vw] md:h-[3.906vw] xs:w-[12vw] xs:h-[8vw]"
            />
            <img
              src="/images/landing/google-pay.svg"
              alt="google-pay"
              className="w-[2.344] h-[1.563vw] md:w-[5.859vw] md:h-[3.906vw] xs:w-[12vw] xs:h-[8vw]"
            />
            <img
              src="/images/landing/apple-pay.svg"
              alt="apple-pay"
              className="w-[2.344] h-[1.563vw] md:w-[5.859vw] md:h-[3.906vw] xs:w-[12vw] xs:h-[8vw]"
            />
          </div>
        </div>
      </div>
    </footer>
  );
};
