import * as yup from 'yup';

type ErrorMessages = Record<string, string | null>;

export const emailSchema = (msg: ErrorMessages, required = true) => {
  let schema = yup.string();
  if (required) schema = schema.required(msg.required || '');
  return schema.email(msg.invalid || '').min(6, msg.length || '');
};

export const passwordSchema = (msg: ErrorMessages) =>
  yup
    .string()
    .trim()
    .test('password', msg.space || '', value => {
      return !/\s/.test(value as string);
    })
    .required(msg.required || '')
    .test('password', msg.chars || '', value => {
      return !/[&@#$%\^\*]/.test(value as string);
    })
    .min(6, msg.length || '');

export const stringSchema = (
  key: string,
  length: number,
  msg: ErrorMessages,
  required = true,
  options: Record<string, string | number> = {}
) => {
  let schema = yup.string().min(length, msg.length || '');
  if (required) schema = schema.required(msg.required || '');
  if (options.max) schema = schema.max(options.max as number, msg.maxLength || '');
  return schema;
};

export const ibanSchema = (msg: ErrorMessages, required = true) => {
  let schema = yup.string();
  if (required) schema = schema.required(msg.required || '');
  return schema
    .test('only digits', msg.chars || '', value => {
      if (!value) return true;
      return !/[!?.,\-\+&@#$%\^\*]/.test(value as string);
    })
    .min(10, msg.length || '')
    .max(22, msg.length || '');
};

export const numberSchema = (min: number, msg: ErrorMessages, required = true) => {
  let schema = yup.number();
  if (required) schema = schema.required(msg.required || '');
  return schema.min(min, msg.min || '');
};
