import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowDown } from '../../static/arrow_down.svg';
import ClickAwayListener from '../ClickAwayListener';

interface IProps {
  className: string;
  items: string[];
}

export const LanguageSelect: FC<IProps> = ({ className, items }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation();

  const handleClick = () => {
    setIsOpen(prev => !prev);
  };
  const handleLangChange = (lng: string) => () => {
    setIsOpen(false);
    i18n.changeLanguage(lng);
  };

  /* eslint-disable-next-line */
  const handleClose = (e: any) => {
    if (e.target.classList.contains('away')) return;
    setIsOpen(false);
  };
  const correctLang = i18n.language
    ? i18n.language.split('-').length > 1
      ? i18n.language.split('-')[0]
      : i18n.language
    : 'en';
  return (
    <div
      className={`rounded-[5.208vw] border-[1px] border-white px-[1.042vw]
      py-[0.573vw] relative z-10 ${className}
      md:rounded-[13.021vw] md:py-[1.432vw] md:px-[2.604vw] md:w-[12.37vw] md:h-[5.99vw]
      xs:rounded-[26.667vw] xs:px-[5.333vw] xs:py-[2.933vw] xs:w-[25.333vw] xs:h-[12.267vw]`}
    >
      <button className="flex items-center away" onClick={handleClick}>
        <p
          className={`font-bold leading-[1.25vw] text-[0.833vw] text-white w-[1.615vw] away
          md:text-[2.083vw] md:leading-[3.125vw] md:w-[3.125vw] xs:text-[4.267vw] xs:leading-[6.4vw] xs:w-[6.4vw]`}
        >
          {correctLang.toUpperCase()}
        </p>
        <ArrowDown
          className={`w-[1.25vw] h-[1.25vw] transition-transform away ${
            isOpen ? 'rotate-[180deg]' : 'rotate-0'
          } md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]`}
        />
      </button>
      {isOpen && (
        <ClickAwayListener onClickAway={handleClose}>
          <ul
            className={`absolute -bottom-[0.208vw] right-0 translate-y-full flex flex-col gap-[0.417vw]
              w-[7.552vw] bg-white p-[1.042vw] rounded-[1.042vw] cursor-pointer
              border-[1px] border-inputBorder md:w-[18.88vw] md:p-[2.604vw] md:rounded-[2.604vw] md:gap-[1.042vw]
              xs:w-[38.667vw] xs:p-[5.333vw] xs:rounded-[5.333vw] xs:gap-[2.133vw]
            `}
          >
            {items.map(lng => (
              <li
                onClick={handleLangChange(lng)}
                className={`rounded-[2.083vw] ${lng === correctLang && 'bg-blackBg text-white'}
                  pl-[1.042vw] py-[0.26vw] leading-[1.25vw] text-[0.833vw]
                  md:text-[2.083vw] md:leading-[3.125vw] md:rounded-[2.604vw] md:pl-[2.604vw] md:py-[0.651vw]
                  xs:text-[4.267vw] xs:leading-[6.4vw] xs:rounded-[5.333vw] xs:pl-[5.333vw] xs:py-[1.333vw]
                `}
                key={lng}
              >
                {lng.toUpperCase()}
              </li>
            ))}
          </ul>
        </ClickAwayListener>
      )}
    </div>
  );
};
