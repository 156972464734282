import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { emailSchema, ibanSchema, stringSchema } from './base';

export const useProfileValidationSchema = () => {
  const { t } = useTranslation();

  return yup.object({
    organization: stringSchema(
      'Organization',
      3,
      {
        length: t('errors.organization.length'),
        required: t('errors.organization.required'),
      },
      false
    ),
    email: emailSchema(
      {
        required: t('errors.email.required'),
        length: t('errors.email.length'),
        invalid: t('errors.email.invalid'),
      },
      false
    ),
    iban: ibanSchema(
      {
        required: t('errors.iban.required'),
        chars: t('errors.iban.chars'),
        length: t('errors.iban.length'),
      },
      false
    ),
    socialLink: stringSchema('Social link', 0, {}, false),
    phone: stringSchema(
      'Phone number',
      12,
      {
        length: t('errors.phone.length'),
      },
      false
    ),
  });
};
