import { ReactComponent as HexagonB } from '../../../../../static/bottom-hexagons.svg';
import { ReactComponent as Line } from '../../../../../static/flight-line.svg';
import { ReactComponent as HexagonT } from '../../../../../static/top-hexagons.svg';

const ImagesBG = () => {
  return (
    <div className="w-full">
      <HexagonT className="absolute -right-[26.4vw] -top-[6.667vw]" />
      <Line className="absolute left-[7.2vw] top-[13.6vw]" />
      <HexagonB className="absolute top-[91.2vw]" />
    </div>
  );
};

export default ImagesBG;
