import { ForgotPasswordForm } from '../../components/ForgotPasswordForm';
import { AuthTemplate } from '../../layouts/AuthTemplate';

export const SendRestorePassword = () => {
  return (
    <AuthTemplate>
      <ForgotPasswordForm />
    </AuthTemplate>
  );
};
