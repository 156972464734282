import { useNavigate } from 'react-router-dom';

import { ROUTES } from '../../../constants/routes';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { replaceUser } from '../../../redux/slices/userSlice';

export const AdminCurrentUser = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { adminId, organization } = useAppSelector(state => state.user);

  const handleLeaveCurrentUser = () => {
    dispatch(replaceUser({ id: adminId as number, organization: 'admin' }));
    navigate(`${ROUTES.DASHBOARD}/${adminId}${ROUTES.ORGANIZATION}`);
  };

  return (
    <div className="flex w-full justify-center xs:justify-start xs:px-[6.4vw]">
      <div className="flex justify-between w-[11.458vw] md:w-[26.656vw] xs:w-[58.667vw] max-h-max">
        <p
          className={`flex gap-[0.417vw] items-center font-medium text-[0.938vw] leading-[1.458vw] max-w-[70%]
            md:text-[2.344vw] md:leading-[3.646vw] md:gap-[1.042vw] text-white xs:text-[4.8vw] xs:leading-[7.447vw] xs:gap-[2.133vw]`}
        >
          <img
            src="/images/icons/home_round.svg"
            alt="home icon"
            className="w-[1.719vw] h-[1.719vw] md:w-[4.297vw] md:h-[4.297vw] xs:w-[8.8vw] xs:h-[8.8vw]"
          />
          {organization}
        </p>
        <button onClick={handleLeaveCurrentUser}>
          <img
            src="/images/icons/exit.svg"
            alt="exit icon"
            className="w-[1.25vw] h-[1.25vw] md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]"
          />
        </button>
      </div>
    </div>
  );
};
