import { ReviewType } from '../../redux/api/types/reviews';
import { FormikConfig } from '../../types/base';

export const config: FormikConfig = {
  organization: {
    type: 'text',
    placeholder: 'auth.organization',
    required: false,
    initial: '',
  },
  email: {
    type: 'text',
    placeholder: 'auth.email',
    required: false,
    initial: '',
  },
  wish: {
    type: 'text',
    placeholder: 'employee.wish',
    required: false,
    initial: '',
  },
  iban: {
    type: 'text',
    placeholder: 'iban',
    required: false,
    initial: '',
  },
  socialLink: {
    type: 'text',
    placeholder: 'auth.socialLink',
    required: false,
    initial: '',
  },
  phone: {
    type: 'text',
    placeholder: 'auth.phone',
    required: false,
    initial: '',
  },
};

export const adminConfig: FormikConfig = {
  menuLink: {
    type: 'text',
    placeholder: 'profile.menuLink',
    required: false,
    initial: '',
  },
  qrLink: {
    type: 'text',
    placeholder: 'profile.qrLink',
    required: false,
    initial: '',
  },
  feedbackLink: {
    type: 'text',
    placeholder: 'profile.feedbackLink',
    required: false,
    initial: '',
  },
};

export const dropDownItems: ReviewType[] = [
  'clean',
  'comfort',
  'food',
  'hookah',
  'hospitality',
  'interior',
  'music',
  'organization',
  'politeness',
  'service',
  'team',
  'wine',
];
