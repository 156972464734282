import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { API_URL } from '../../../constants/api';
import { useDeleteCategoryImageMutation } from '../../../redux/api/menu';
import { ReactComponent as EditIcon } from '../../../static/edit.svg';
import { Button } from '../../../view/Button';
import { FormTextInput } from '../../../view/FormTextInput';
import { Loader } from '../../Loader';
import { useCategoryBlock } from '../hooks/useCategoryBlock';

interface IProps {
  image: string | null;
  name: string;
}

const CategoryBlock: FC<IProps> = ({ image, name }) => {
  const { fireInput, inputRef, handleFileChange, preview, isLoader, formik } =
    useCategoryBlock(name);
  const [deleteImage, { isLoading, isSuccess }] = useDeleteCategoryImageMutation();
  const { categoryId } = useParams();

  const { t } = useTranslation();

  const handleDelete = () => {
    deleteImage({ id: +(categoryId as string) });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('back.user.update'));
    }
  }, [isSuccess]);

  return (
    <>
      {(isLoader || isLoading) && <Loader />}
      <div className="flex gap-[40px] items-center mb-[30px] md:w-full">
        <div className="relative">
          <button
            className="absolute bottom-0 right-0 z-10 bg-mainOrange rounded-full p-1"
            onClick={fireInput}
          >
            <EditIcon fill="white" />
          </button>
          <input
            accept="image/png, image/jpeg"
            type="file"
            id="file-block"
            name="file-block"
            ref={inputRef}
            className="hidden"
            onChange={handleFileChange}
          />
          <label
            htmlFor="file-block"
            className={`flex items-center flex-col text-[0.729vw] leading-[1.042vw] text-textGray text-center justify-center cursor-pointer
md:text-[1.823vw] md:leading-[2.604vw] xs:text-[3.733vw] xs:leading-[5.333vw] md:max-w-[34.896vw] xs:max-w-[71.467vw]`}
          >
            <img
              src={preview ? preview : image ? `${API_URL}/image/${image}` : '/images/upload.png'}
              alt="upload img"
              className="w-[4.792vw] h-[4.792vw] rounded-full md:w-[11.98vw] md:h-[11.98vw] xs:w-[18.667vw] xs:h-[18.667vw]"
            />
          </label>
        </div>
        <div className="translate-y-[15px] md:w-[66vw]">
          <FormTextInput
            error={formik.errors.name}
            {...formik.getFieldProps('name')}
            name="name"
            type="text"
          />
          <Button className="!h-[30px]" onClick={handleDelete}>
            {t('foodMenu.deleteBtn')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CategoryBlock;
