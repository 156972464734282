import { ChangeEvent, FC, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useClickOutside } from '../../hooks/useClickOutside';
import { useAppSelector } from '../../redux/hooks';
import { ReactComponent as ArrowDown } from '../../static/arrow_down.svg';
import { OrderUpdateFn } from '../../types/base';
import { Button } from '../Button';
import { FormTextInput } from '../FormTextInput';

interface IProps {
  items: number[];
  refetch: () => void;
  currentOrder: number;
  id: number;
  updateFn: OrderUpdateFn;
}

const OrderSelect: FC<IProps> = ({ items, refetch, currentOrder, id, updateFn }) => {
  const { menuLang } = useAppSelector(state => state.user);
  const [isOpen, setIsOpen] = useState(false);
  const [newOrder, setNewOrder] = useState('0');
  const ref = useRef<HTMLUListElement>(null);
  const { categoryId } = useParams();
  useClickOutside(ref, () => setIsOpen(false));

  const handleClick = () => {
    setIsOpen(prev => !prev);
  };

  const handleNewOrderChange = (e: ChangeEvent<HTMLInputElement>) => setNewOrder(e.target.value);

  const handleAddNewOrder = (order: number) => () => {
    const formData = new FormData();
    formData.append('order', `${order}`);
    formData.append('id', `${id}`);
    formData.append('lang', menuLang);

    if (categoryId) {
      formData.append('menuCategoryId', categoryId);
    }

    updateFn(formData).then(() => {
      refetch();
      setIsOpen(false);
      setNewOrder('0');
    });
  };

  const handleOrderChange = (order: number) => () => {
    setIsOpen(false);
    handleAddNewOrder(order)();
  };

  return (
    <div
      className={`rounded-xl border-[1px] border-black px-[1.042vw]
      py-[0.573vw] relative w-min
      md:rounded-[13.021vw] md:py-[1.432vw] md:px-[2.604vw] md:w-[12.37vw] md:h-[5.99vw]
      xs:rounded-[26.667vw] xs:px-[5.333vw] xs:py-[2.933vw] xs:w-[25.333vw] xs:h-[12.267vw]`}
    >
      <button className="flex items-center" onClick={handleClick}>
        <p
          className={`font-bold leading-[1.25vw] text-[0.833vw] text-black w-[1.615vw]
          md:text-[2.083vw] md:leading-[3.125vw] md:w-[3.125vw] xs:text-[4.267vw] xs:leading-[6.4vw] xs:w-[6.4vw]`}
        >
          {items.includes(currentOrder) ? currentOrder : ''}
        </p>
        <ArrowDown
          fill="black"
          stroke="black"
          className={`w-[1.25vw] h-[1.25vw] transition-transform ${
            isOpen ? 'rotate-[180deg]' : 'rotate-0'
          } md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]`}
        />
      </button>
      {isOpen && (
        <ul
          ref={ref}
          className={`absolute z-50 -bottom-[0.208vw] right-0 translate-y-full flex flex-col gap-[0.417vw]
              w-[7.552vw] bg-white p-[1.042vw] rounded-[1.042vw] cursor-pointer
              border-[1px] border-inputBorder md:w-[18.88vw] md:p-[2.604vw] md:rounded-[2.604vw] md:gap-[1.042vw]
              xs:w-[38.667vw] xs:p-[5.333vw] xs:rounded-[5.333vw] xs:gap-[2.133vw]
            `}
        >
          {items.map(order => (
            <li
              onClick={handleOrderChange(order)}
              className={`rounded-[2.083vw] ${order === currentOrder && 'bg-blackBg text-white'}
                  pl-[1.042vw] py-[0.26vw] leading-[1.25vw] text-[0.833vw]
                  md:text-[2.083vw] md:leading-[3.125vw] md:rounded-[2.604vw] md:pl-[2.604vw] md:py-[0.651vw]
                  xs:text-[4.267vw] xs:leading-[6.4vw] xs:rounded-[5.333vw] xs:pl-[5.333vw] xs:py-[1.333vw]
                `}
              key={order}
            >
              {order}
            </li>
          ))}
          <li className="border-t-2 border-black flex flex-col pt-2">
            <FormTextInput value={newOrder} onChange={handleNewOrderChange} type="number" />
            <Button onClick={handleAddNewOrder(+newOrder)} className="-mt-4">
              Add
            </Button>
          </li>
        </ul>
      )}
    </div>
  );
};

export default OrderSelect;
