import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldInputProps } from 'formik';

interface IProps {
  error?: string;
  fieldProps: FieldInputProps<string>;
}

const Textarea: FC<IProps> = ({ error, fieldProps }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full bg-white flex flex-col">
      <textarea
        className={`
          ${
            error
              ? 'mb-[0.313vw] md:mb-[0.781vw] xs:mb-[1.6vw]'
              : 'mb-[1.25vw] md:mb-[3.125vw] xs:mb-[6.4vw]'
          }
        ${
          error
            ? 'mb-[0.313vw] border-mainRed focus:outline-mainRed md:mb-[0.781vw] xs:mb-[1.6vw]'
            : 'border-inputBorder'
        } w-full h-[5.5vw] rounder-[0.417vw] px-[0.729vw] py-[0.625vw]
          rounded-[0.417vw] border-[1px] text-authActive focus:border-[1px] focus:border-mainOrange
          text-[0.834vw] leading-[1.25vw]
          md:h-[15.25vw] md:rounded-[1.042vw] md:px-[1.823vw] md:py-[1.563vw] md:text-[2.083vw] md:leading-[3.125vw]
          xs:h-[30.8vw] xs:rounded-[2.133vw] xs:px-[3.733vw] xs:py-[3.2vw] xs:text-[4.267vw] xs:leading-[6.4vw]
          focus:outline-0`}
        placeholder={t('foodMenu.item.compound') || ''}
        {...fieldProps}
      />
      {error && (
        <p
          className={`text-[0.625vw] leading-[0.938vw] text-textGray flex items-center gap-[0.417vw]
  md:text-[1.563vw] md:leading-[2.344vw] xs:text-[3.2vw] xs:leading-[4.8vw] md:gap-[0.417vw] xs:gap-[2.133vw]`}
        >
          <img
            src="/images/icons/error.svg"
            alt="error icon"
            className="w-[0.833vw] h-[0.833vw] md:w-[2.083vw] md:h-[2.083vw] xs:w-[4.267vw] xs:h-[4.267vw]"
          />
          {error}
        </p>
      )}
    </div>
  );
};

export default Textarea;
