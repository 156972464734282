import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { emailSchema } from './base';

export const useResetValidationSchame = () => {
  const { t } = useTranslation();

  return yup.object({
    email: emailSchema({
      required: t('errors.email.required'),
      length: t('errors.email.length'),
      invalid: t('errors.email.invalid'),
    }),
  });
};
