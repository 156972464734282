import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { DOCS_POLICY, DOCS_TERMS } from '../../constants/api';

export const Footer = () => {
  const { t } = useTranslation();

  const clickHandler = (type: string) => async () => {
    let blob: Blob;
    let fileName: string;
    if (type === 'terms') {
      const resp = await fetch(DOCS_TERMS);
      blob = await resp.blob();
      fileName = 'User_Agreement_Beezytips.pdf';
    } else {
      const resp = await fetch(DOCS_POLICY);
      blob = await resp.blob();
      fileName = 'Personal_Data_Processing_Policy_Beezytips.pdf';
    }
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', fileName);
    link.click();
  };

  return (
    <footer
      className={`w-full h-[4.167vw] md:h-[14.193vw] xs:h-[48.8vw] bg-mainBlack
      flex px-[14.427vw] md:px-[6.12vw] items-center justify-between
      text-white xs:flex-col xs:justify-center xs:gap-[3.733vw]
      py-[2.188vw] md:py-[5.469vw] xs:py-[11.2vw]`}
    >
      <p className="text-[0.833vw] leading-[1.25vw] md:text-[2.083vw] md:leading-[3.125vw] xs:text-[4.267vw] xs:leading-[6.4vw]">
        {t('footer.author')}
      </p>

      <div className="flex items-center gap-[2.083vw] md:gap-[10.285vw] xs:flex-col xs:gap-[3.2vw]">
        <Link
          onClick={clickHandler('policy')}
          to="/"
          className="text-[0.833vw] leading-[1.25vw] md:text-[2.083vw] md:leading-[3.125vw] xs:text-[4.267vw] xs:leading-[6.4vw]"
        >
          {t('footer.policy')}
        </Link>
        <Link
          onClick={clickHandler('terms')}
          to="/"
          className="text-[0.833vw] leading-[1.25vw] md:text-[2.083vw] md:leading-[3.125vw] xs:text-[4.267vw] xs:leading-[6.4vw]"
        >
          {t('footer.condition')}
        </Link>
      </div>
    </footer>
  );
};
