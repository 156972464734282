import { ChangeEvent, FC, ReactElement } from 'react';

interface IProps {
  classNames?: string;
  labelText: string | ReactElement;
  labelClassnames?: string;
  checked: boolean;
  toggle: (e?: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  disabled?: boolean;
  withShadow?: boolean;
}

export const Checkbox: FC<IProps> = ({
  labelText,
  labelClassnames,
  classNames,
  toggle,
  checked,
  name,
  disabled,
  withShadow,
}) => {
  return (
    <label
      htmlFor={name}
      className={`flex gap-[0.573vw] ml-[0.573vw] ${classNames || 'items-center'}
      md:gap-[1.432vw] md:ml-[1.042vw] xs:gap-[2.933vw] xs:ml-[2.133vw]`}
    >
      <input
        type="checkbox"
        name={name}
        id={name}
        className={`checkbox checkbox-warning w-[0.938vw] h-[0.938vw] rounded-[0.208vw] checked:shadow-none
        md:w-[2.344vw] md:h-[2.344vw] md:rounded-[0.521vw] ${withShadow && 'shadow-checkbox'}
        xs:w-[4.8vw] xs:h-[4.8vw] xs:rounded-[1.067vw] disabled:bg-stone-500 disabled:shadow-none`}
        onChange={toggle}
        checked={checked}
        value={''}
        disabled={disabled}
      />
      <span
        className={`text-mainBlack text-[0.729vw] leading-[1.042vw] md:text-[1.823vw] md:leading-[2.604vw] ${
          labelClassnames || 'xs:text-[3.733vw] xs:leading-[5.333vw]'
        }`}
      >
        {labelText}
      </span>
    </label>
  );
};
