import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Balls } from './components';

export const About = () => {
  const {
    t,
    i18n: { language: lang },
  } = useTranslation();
  const correctLang = lang.split('-').length > 1 ? lang.split('-')[0] : lang;
  const ref2 = useRef<HTMLImageElement | null>(null);
  const [anim2, setAnim2] = useState('animate-coin1 translate-y-[4.375vw]');
  const [delay2, setDelay2] = useState(1300);

  useEffect(() => {
    const animCb2 = (e: AnimationEvent) => {
      const type = e.animationName;

      const timer = setTimeout(() => {
        if (type === 'coin1') {
          setAnim2('animate-coin2 -translate-y-full');
          setDelay2(1);
        } else {
          setAnim2('animate-coin1 translate-y-[4.375vw]');
          setDelay2(1300);
        }
        clearTimeout(timer);
      }, delay2);
    };
    ref2.current?.addEventListener('animationend', animCb2);
    return () => {
      ref2.current?.removeEventListener('animationend', animCb2);
    };
  }, [anim2]);

  return (
    <section
      id="about"
      className="w-full pt-[11.408vw] mt-[-5.208vw] mb-[4.167vw] md:mb-[10.417vw] md:flex md:justify-center xs:mb-[21.333vw] md:pt-[13vw] md:mt-[-13vw] xs:pt-[19vw] xs:mt-[-19vw]"
    >
      <div
        className={`flex gap-[3.542vw] bg-advBg rounded-[1.042vw] pt-[7.188vw] pb-[7.708vw] pl-[30.052vw] relative
        md:gap-[2.604vw] md:rounded-[1.284vw] md:pt-[6.25vw] md:pb-[7.292vw] md:pl-[29.948vw] md:w-[87.63vw]
        xs:rounded-[2.63vw] xs:pt-[40.267vw] xs:pb-[14.133vw] xs:pl-[5.6vw] xs:w-full`}
      >
        <img
          src={`/images/landing/about_${correctLang}.png`}
          alt="about phone"
          // ref={ref}
          className={`w-[31.354vw] h-[44.01vw] absolute -left-[6.803%] -top-[21.757%] md:w-[38.846vw] md:h-[54.245vw] ${''}
          md:-left-[9.955%] md:-top-[12.2%] xs:w-[79.2vw] xs:h-[111.2vw] xs:-top-[62.133vw] xs:left-[15.733%]`}
        />
        <div
          className={`absolute -left-[6.406vw] -top-[0.365vw] w-[25.99vw] h-[19.89vw]
          md:w-[32.031vw] md:h-[24.479vw] md:top-[1.953vw] md:-left-[9.588vw]
          xs:w-[65.6vw] xs:h-[50.133vw] xs:-top-[42.159vw] xs:left-[13vw]`}
        >
          <img
            src="/images/landing/coin.png"
            alt="coin"
            ref={ref2}
            className={`w-[8.75vw] h-[8.75vw] absolute right-0 top-0
            md:w-[10.785vw] md:h-[10.785vw] ${anim2}
            xs:w-[22.088vw] xs:h-[22.088vw]`}
          />
        </div>
        <div className="text-white w-[35.156vw] md:w-[50.391vw] xs:w-[88vw]">
          <h3
            className={`font-bold text-[2.813vw] leading-[3.333vw] mb-[3.125vw]
            md:text-[5.208vw] md:leading-[7.292vw] md:mb-[2.604vw]
            xs:text-[8vw] xs:leading-[12.8vw] xs:mb-[5.333vw]`}
          >
            {t('landing.about.title')}
          </h3>
          <p
            className={`text-[1.042vw] leading-[1.563vw] mb-[1.563vw] 
            md:text-[2.083vw] md:leading-[3.125vw] md:mb-[2.083vw]
            xs:text-[4.267vw] xs:leading-[6.4vw] xs:mb-[4.267vw]`}
          >
            {t('landing.about.text1')}
          </p>
          <p
            className={`text-[1.042vw] leading-[1.563vw] mb-[1.563vw] 
            md:text-[2.083vw] md:leading-[3.125vw] md:mb-[2.083vw]
            xs:text-[4.267vw] xs:leading-[6.4vw] xs:mb-[4.267vw]`}
          >
            {t('landing.about.text2')}
          </p>
          <p className="text-[1.042vw] leading-[1.563vw] md:text-[2.083vw] md:leading-[3.125vw] xs:text-[4.267vw] xs:leading-[6.4vw]">
            {t('landing.about.text3')}
          </p>
        </div>
        <Balls />
      </div>
    </section>
  );
};
