export const selectDate = [
  {
    title: 'analytic.filterTime1',
    value: 'today',
  },
  {
    title: 'analytic.filterTime2',
    value: 'week',
  },
  {
    title: 'analytic.filterTime3',
    value: 'month',
  },
];
export const selectReceiver = [
  {
    title: 'analytic.filterPerson',
    value: 'all',
  },
];

export const cards = [
  {
    title: 'analytic.tips',
    tooltipText: 'analytic.tipsTip',
    key: 'total',
  },
  {
    title: 'analytic.amount',
    key: 'transactions',
    tooltipText: 'analytic.amountTip',
  },
  {
    title: 'analytic.ratingAvg',
    tooltipText: 'analytic.ratingTip',
    key: 'averageRating',
  },
];
