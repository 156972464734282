import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  setCurrentTab: Dispatch<SetStateAction<number>>;
  currentTab: number;
}

const Tabs: FC<IProps> = ({ currentTab, setCurrentTab }) => {
  const { t } = useTranslation();

  const handleClick = (i: number) => () => setCurrentTab(i);

  return (
    <div className="flex items-center gap-[2.083vw] md:gap-[5.208vw] xs:gap-[10.667vw]">
      <button
        onClick={handleClick(0)}
        className={`flex items-center gap-[0.417vw] px-[0.208vw] pt-[0.677vw] leading-[1.25vw] font-bold text-[0.729vw] ${
          currentTab === 0
            ? 'border-b-[0.104vw] border-mainOrange pb-[0.573vw] md:pb-[1.432vw] xs:pb-[2.933vw] text-mainBlack'
            : 'pb-[0.677vw] text-textGray md:pb-[1.693vw] xs:pb-[3.467vw]'
        } md:gap-[1.042vw] md:px-0 md:pt-[1.693vw] md:text-[1.823vw] md:leading-[3.125vw]
  xs:gap-[2.133vw] xs:px-0 xs:pt-[3.467vw] xs:text-[3.733vw] xs:leading-[6.4vw]`}
      >
        {t('qr.promo.tab1')}
      </button>
      <button
        onClick={handleClick(1)}
        className={`flex items-center gap-[0.417vw] px-[0.208vw] pt-[0.677vw] leading-[1.25vw] font-bold text-[0.729vw] ${
          currentTab === 1
            ? 'border-b-[0.104vw] border-mainOrange pb-[0.573vw] md:pb-[1.432vw] xs:pb-[2.933vw] text-mainBlack'
            : 'pb-[0.677vw] text-textGray md:pb-[1.693vw] xs:pb-[3.467vw]'
        }  md:gap-[1.042vw] md:px-0 md:pt-[1.693vw] md:text-[1.823vw] md:leading-[3.125vw]
  xs:gap-[2.133vw] xs:px-0 xs:pt-[3.467vw] xs:text-[3.733vw] xs:leading-[6.4vw]`}
      >
        {t('qr.promo.tab2')}
      </button>
    </div>
  );
};

export default Tabs;
