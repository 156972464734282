import { Dispatch, SetStateAction } from 'react';

interface DataItem {
  value: string;
  setError: Dispatch<SetStateAction<string>>;
}

export const validateCard = (data: {
  cardNumber: DataItem;
  year: DataItem;
  month: DataItem;
  cvv: DataItem;
}) => {
  const { cardNumber, year, month, cvv } = data;

  let isError = false;

  if (cardNumber.value.length <= 19) {
    cardNumber.setError('errors.card.card');
    isError = true;
  } else {
    cardNumber.setError('');
  }
  if (year.value.length < 2 || month.value.length < 2) {
    year.setError('errors.card.date');
    isError = true;
  } else {
    year.setError('');
  }
  if (cvv.value.length < 3) {
    cvv.setError('errors.card.cvv');
    isError = true;
  } else {
    cvv.setError('');
  }

  return isError;
};
